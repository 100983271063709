import axios from "axios";
import AddBackFillingModel from "./AddBackFillingModel";

export default class AddBackFillingCommand {
    responseErrorMessage : string
    statusCode: number;
    constructor() {
        this.responseErrorMessage = '';
        this.statusCode = 0;
    }

    async CreateBackFill(backfill:AddBackFillingModel ){
        const API_URL = process.env.REACT_APP_API_URL
        const idToken : string | null = localStorage.getItem("IdToken");
        const config = {
          headers: { Authorization: `${idToken}` }
        };

        try{
            const response = await axios.post(`${API_URL}/v1/backfill`,
            {
               id : backfill.Id,
               partnername: backfill.PartnerName,
               providername:backfill.ProviderName,
               slotduration:backfill.slotDuration,
               timeFrameStart:backfill.timeFrameStart,
               timeFrameEnd:backfill.timeFrameEnd,
               brand:backfill.Brand,
               entitytype:backfill.EntityType

            },config );
            this.statusCode = response.status;
            return response;
        }

        catch (error :any) {
            if (error.response && error.response.data) {
              this.responseErrorMessage = error.response.data.message;
              this.statusCode = error.response.status
            } else {
              // Handle the generic error message
              throw new Error("Failed to create backfill");
            }
          }
    }
}