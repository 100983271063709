import React , {FC} from 'react'
import { useHref } from 'react-router-dom';

type Props = {
    className: string
  }

  const VAddStores : React.FC<Props> = ({className})=> {
    return(
        <div className="d-flex flex-column flex-lg-row">

            <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">

                <div className="card">

                    <div className="card-body p-12">
                        <form>


                            <div className="mb-0">
                                <div className="row gx-10 mb-5">
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">Name</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter Partner Name</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">


                                        <div className="mb-5 pt-9">
                                        <select name="Partner" aria-label="Select Country" data-control="select2" data-placeholder="Select partner" className="form-select form-select-solid" >
                                        <option value="">-Select Partner-</option>
                                        <option value="KFC">KFC</option>
                                        <option value="Taco">Taco Bell</option>
                                        <option value="Bur">Burger King</option>
                                        <option value="sha">Shawarma Company</option>
                                        <option value="Nat">Naturals</option>
                                        <option value="Par">Paradise</option>
                                        <option value="Dom">Dominos</option>
                                        <option value="Star">Star Bucks</option>
                                    </select>
                                    <div className="text-gray-600">Please Select Partner</div>
                                   
                                    
                                        <div className="fv-plugins-message-container invalid-feedback">
                                            
                                        </div>


                                        </div>

                                    </div>
                                    <div className="col-lg-12 mb-5 d-flex align-items-center">
                                        <span className="fw-bolder mb-1 fs-6 col-lg-1">Address</span>
                                        <span className="border-bottom border-gray-300 col-lg-11"></span>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">Line1</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter Line1</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className=" form-label fs-6 text-gray-700 mb-3">Line2</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter Line2</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">City</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter City</div>


                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>


                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">State</label>

                                        <div className="mb-5">


                                            <select name="Partner" aria-label="Select State" data-control="select2" data-placeholder="Select partner" className="form-select form-select-solid" >
                                                <option value="">-Select State-</option>




                                                <option value="Ap" >Andra Pradesh</option>
                                                <option value="Ka">Karnataka</option>



                                                <option value="Cl">California</option>
                                                <option value="Tx">Texas</option>



                                                <option value="Ns">Nova Scotia</option>
                                                <option value="Al">Alberta </option>
                                            </select>

                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>


                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">Country</label>

                                        <div className="mb-5">

                                            <select name="Partner" aria-label="Select Country" data-control="select2" data-placeholder="Select partner" className="form-select form-select-solid" >
                                                <option value="">-Select Country-</option>
                                                <option value="Ind">India</option>
                                                <option value="Can">Canada </option>
                                                <option value="Usa">United States Of America </option>

                                            </select>



                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3"> Pincode</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter Pincode</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-12 d-flex align-items-center mb-10">
                                        <span className="d-inline fw-bolder min-w-100px">Primary Admin</span>
                                        <span className="border-bottom border-gray-300 w-100"></span>
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3"> First Name</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter User's "First Name</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3">Last Name</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter User's Last Name</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-lg-6">
                                        <label className="required form-label fs-6 text-gray-700 mb-3"> Email</label>

                                        <div className="mb-5">
                                            <input placeholder="" className="form-control form-control-solid" />
                                            <div className="text-gray-600">Please enter user's email(example: abcd@email.com)</div>
                                            <div className="fv-plugins-message-container invalid-feedback">

                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-700 mb-3">Country</label>
                                                <div className="mb-5">
                                                    <select name="Partner" aria-label="Select Country" data-control="select2" data-placeholder="Select partner" className="form-select form-select-solid"><option value="">-Select Country-</option><option value="AFG">Afghanistan (+93)</option><option value="ALA">Aland Islands (+358)</option><option value="ALB">Albania (+355)</option></select>


                                                    <div className="fv-plugins-message-container invalid-feedback">

                                                    </div>


                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-700 mb-3"> Mobile</label>

                                                <div className="mb-5">

                                                    <input placeholder="" className="form-control form-control-solid" />

                                                    <div className="text-gray-600">Please enter Mobile no</div>
                                                    <div className="fv-plugins-message-container invalid-feedback">

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-0">

                                <div className="row gx-10 mb-5">
                                    <div className="d-flex flex-stack">

                                        <div className="d-flex align-items-center me-2">
                                        </div>


                                        <button className="btn btn-primary" type="submit" data-kt-element="save">Save</button>

                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
  }
export {VAddStores}