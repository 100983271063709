import axios from "axios";
import StorePagnationListModel from "./StorePagnationListModel";

export default class StorePaginationListViewModel {
    storelist : StorePagnationListModel[];
    totalPages: number;
    totalCount : number;

    constructor()
 {
   this.storelist=[];
   this.totalCount=0;
   this.totalPages=0;
 }
    async LoadStoreList(pageNumber: number, pageSize: number, partnerName:any,status: string,filterbyid:any){

        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/partners/${partnerName}/stores`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    status:status,
                    filterbyid:filterbyid
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });
  
            const data = response.data.response.items;
            this.storelist = data;
            this.totalPages = response.data.response.totalPages
            this.totalCount= response.data.response.totalCount
        } catch (error) {
            console.error('Failed to load Stores list data:', error);
        }

    }
}
