import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import AddBackFillingCommand from './AddBackFillingCommand';
import AddBackFillingModel from './AddBackFillingModel';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import { SpinnerDotted } from 'spinners-react';
import { required } from 'yargs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Datetime from 'react-datetime';
import { ThemeSome } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import PartnersModel from '../VPartners/PartnersModel';
import PartnersViewModel from '../VPartners/PartnersViewModel';
import ProviderModel from '../VProviderModel/ProviderModel';
import ProviderViewModel from '../VProviderModel/ProviderViewModel';
import BrandsModel from '../VBrands/BarndsModel';
import BrandsViewmodel from '../VBrands/BrandsViewmodel';
import BlockUi from '../../../app/BlockUI/BlockUi';
import partnerListCommand from '../VPartnerList/PartnerListCommand';
import PartnerListModel from '../VPartnerList/PartnerListModel';
import { Value } from 'sass';


const AddBackFilling = Yup.object().shape({
    PartnerName: Yup.string()
        .min(1, 'partner Name should be at least 2 characters')
       
        .required('Partner Name must not be empty.'),
        ProviderName: Yup.string()
        .min(2, ' ProviderName should be at least 2 characters')
 
        .required('Provider Name must not be empty.'),
       
        slotDuration: Yup.string()
        .matches(/^[0-9]+$/, 'slotDuration should only contain numbers')
        .min(1, 'slotDuration  should be 1 digits')
        .required('slotDuration  must not be empty.'),
        timeFrameStart: Yup.date()
    .required('TimeFrameStart Must Not be Empty')
    .max(new Date(), 'TimeFrameStart must be in the past'),

timeFrameEnd: Yup.date()
    .required('TimeFrameEnd Must Not be Empty')
    .min(Yup.ref('timeFrameStart'), 'TimeFrameEnd must be greater than TimeFrameStart'),
   

         EntityType:Yup.string()
         .required('Entity Type Must Not be Empty')
         
});

const maxDate = new Date();

const AddBackFillingPage : React.FC = () => {
    const viewModel = new AddBackFillingCommand();
    const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
    const [selectedDateTimeend, setSelectedDateTimeEnd] = useState<Date | null>(null);
    const [partners, setPartners] =useState<PartnersModel[]>([]);
    const [providers ,setProviders] =useState<ProviderModel[]>([]);
    const [brands, setBrands] = useState<BrandsModel[]>([]);
    const partnersViewmodel = new PartnersViewModel();
    const providerViewmodel = new ProviderViewModel();
    const brandsViewmodel = new BrandsViewmodel();
    const PartnerlistViewmodel = new partnerListCommand();
    const [partnerlist, setPartnerList] = useState<PartnerListModel[]>([]);
    const [entity, setEntity] = useState('orders');
    const initialValues: AddBackFillingModel = {
        PartnerName: '',
        ProviderName: '',
        status: '',
        Brand:'',
        slotDuration: '',
        timeFrameStart:'',
        timeFrameEnd:'',
        EntityType:'orders'
    };
    const defaultEntity = 'orders';
    const [loading, setLoading] = useState(false)

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (values: AddBackFillingModel, { setSubmitting }: FormikHelpers<AddBackFillingModel>) => {
        setSubmitting(false);
        setLoading(true);
        const response = await viewModel.CreateBackFill(values);
        setLoading(false);
        console.log(response);
        if (viewModel.statusCode == 200) {
            swal("Success", "BackFill Created Successfully", "success");
            const inputFields = document.getElementsByClassName('clear');
            console.log(inputFields);
            for (let i = 0; i < inputFields.length; i++) {
                const inputField = inputFields[i] as HTMLInputElement;
                inputField.value = '';
            }
            setSelectedDateTime(null); // clear timeFrameStart
            setSelectedDateTimeEnd(null); // clear timeFrameEnd
        }
        if (viewModel.statusCode == 400) {
            swal("Back fill", viewModel.responseErrorMessage, "error");
        }
    };

console.log(handleSubmit);



const getBrands = async (Id : string) =>{
    await brandsViewmodel.LoadBrands(Id);
    setBrands(brandsViewmodel.brands);
}


const getPartnerList = async (entityName : string) =>{
    await PartnerlistViewmodel.LoadPartnersList(entityName);
    setPartnerList(PartnerlistViewmodel.partnerlist);
    console.log('Partner list:', partnerlist);
}

useEffect(() => {
    const getproviders = async () => {
        await providerViewmodel.LoadProviders();
        setProviders(providerViewmodel.providers);
    }
    //getPartnerList(entity)
    setLoading(true)
    getproviders();
    getPartnerList(entity);
    setLoading(false)
}, [ThemeSome,entity])


const handle = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedEntity = event.target.value;
    setEntity(selectedEntity);
    setPartnerList([]); // set an empty array for the partner list
    getPartnerList(selectedEntity);
    initialValues.EntityType = selectedEntity;
};
    return(
        <BlockUi blocking={isSubmitting || loading} message="">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={AddBackFilling}>
        {({  isSubmitting, handleChange, handleBlur}) => (
            <div className="d-flex flex-column flex-lg-row">
                {
                    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                        <div className="card">
                            <div className="card-body p-12">
                                <Form >
                                    <div className="mb-0">
                                        <div className="row gx-10 mb-5">
                                            <div className="col-lg-12 d-flex align-items-center mb-10">
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Provider</label>
                                                <div className="mb-5">
                                                        <select placeholder="" typeof='ProviderName' id='ProviderName' name='ProviderName' onChange={handleChange} onBlur={handleBlur} data-control="select2" data-placeholder="Select partner" className="form-select clear form-select-solid" >
                                                            <option value="">-Select Provider-</option>
                                                            {providers.map(x => (<option>{x.name}</option>))}
                                                        </select>
                                                    <div className="text-gray-600">Please Select Provider </div>
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><ErrorMessage name="ProviderName" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6" >
                                            <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Entity Type</label>
                                                        <div className="mb-5">
                                                            <select placeholder="" typeof='EntityType' id='EntityType' name='EntityType' onChange={(event) => {
                                                                handleChange(event);
                                                                handle(event);
                                                            }} onBlur={handleBlur} data-control="select2" data-placeholder="Select partner" className="form-select form-select-solid" >
                                                                <option value="orders">Orders</option>
                                                                <option value="offers">Offers</option>
                                                            </select>
                                                            <div className="text-gray-600">Please Select Entity Type</div>
                                                            <div className='fv-plugins-message-container'>
                                                                <div className='fv-help-block'><ErrorMessage name="EntityType" /></div>
                                                            </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Partner</label>
                                                <div className="mb-5">
                                                        <select name="PartnerName" typeof='PartnerName' id='PartnerName' aria-label="Select PartnerName" onChange={async (event) =>{await getBrands(event.target.value);handleChange(event);}} onBlur={handleBlur} data-control="select2" data-placeholder="Select partner" className="form-select clear form-select-solid" >
                                                            <option value="">-Select Partner-</option>
                                                            {/* {entity === 'orders' && <option value="parbrink">parbrink</option>}
                                                            {entity === 'offers' && <option value="sparkfly">sparkfly</option>} */}
                                                            {partnerlist.map(x => (<option value={x.id }>{x.name}</option>))}
                                                        </select>
                                                    <div className="text-gray-600">Please Select Partner </div>
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'> <ErrorMessage name="PartnerName" /></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <label className=" form-label fs-6 text-gray-600 fw-bold mb-3"> Brand</label>
                                                <div className="mb-5">
                                                        <select name="Brand" typeof='Brand' id='Brand' aria-label="Select Brand" onChange={handleChange} onBlur={handleBlur} data-control="select2" data-placeholder="Select brand" className="form-select clear form-select-solid" >
                                                            <option value="">-Select Brand-</option>
                                                            {brands.map(x => (<option>{x.name}</option>))}
                                                        </select>
                                                    <div className="text-gray-600">Please Select Brand </div>
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'> <ErrorMessage name="Brand" /></div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {loading && <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color="#c8c6c2" />}
                                            {/* <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">status</label>
                                                <div className="mb-5">
                                                    <input placeholder="" type='status' id='status' name='status' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                    <div className="text-gray-600">Please enter status</div>
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><ErrorMessage name="status" /></div>
                                                    </div>
                                                </div>
                                            </div> */}
                                             <div className="col-lg-6">
                                                <label className=" required form-label fs-6 text-gray-600 fw-bold mb-3">Time Frame Start</label>
                                                  <div className="mb-5">
                                                        <Field name="timeFrameStart" type='timeFrameStart' id='timeFrameStart'>
                                                            {(field: FieldProps) => (
                                                                <DatePicker
                                                                    {...field.field}
                                                                    className="form-control clear form-control-solid fw-bold"
                                                                    
                                                                    calendarClassName="custom-calendar"
                                                                    selected={selectedDateTime}
                                                                    onChange={(date) => {
                                                                        setSelectedDateTime(date);
                                                                        field.form.setFieldValue(field.field.name, date); // set form field value
                                                                    }}
                                                                    onBlur={field.form.handleBlur} // pass Formik's handleBlur function
                                                                    showTimeSelect
                                                                    timeIntervals={15}
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    maxDate={maxDate}
                                                                    autoComplete="off" 
                                                                />
                                                            )}
                                                        </Field>
                                                     <div className="text-gray-600">Please Select Time Frame Start</div> 
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><ErrorMessage name="timeFrameStart" /></div>
                                                    </div>
                                                </div>
                                            </div> 
                                             <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Time Frame End</label>
                                                <div className="mb-5">
                                                <Field name="timeFrameEnd" type='timeFrameEnd' id='timeFrameEnd'>
                                                            {(field: FieldProps) => (
                                                                <DatePicker
                                                                    {...field.field}
                                                                    className="form-control clear form-control-solid fw-bold"
                                                                    calendarClassName="custom-calendar"
                                                                    selected={selectedDateTimeend}
                                                                    onChange={(date) => {
                                                                        setSelectedDateTimeEnd(date);
                                                                        field.form.setFieldValue(field.field.name, date); // set form field value
                                                                    }}
                                                                    onBlur={field.form.handleBlur} // pass Formik's handleBlur function
                                                                    showTimeSelect
                                                                    timeIntervals={15}
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    maxDate={maxDate}
                                                                    autoComplete="off" 
                                                                />
                                                            )}
                                                        </Field>
                                                     <div className="text-gray-600">Please Select Time Frame Ends</div> 
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><ErrorMessage name="timeFrameEnd" /></div>
                                                    </div>
                                                </div>
                                            </div> 
                                             <div className="col-lg-6">
                                                <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Slot Duration</label>
                                                <div className="mb-5">
                                                    <input placeholder="" type='text' id='slotDuration' name='slotDuration' autoComplete="new-password" onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                    <div className="text-gray-600">Please enter duration in seconds</div>
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'><ErrorMessage name="slotDuration" /></div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                    <div className="mb-0">
                                        <div className="row gx-10 mb-5">
                                            <div className="d-flex flex-stack">
                                                <div className="d-flex align-items-center me-2">
                                                </div>
                                                <button type="submit" role="button" disabled={isSubmitting} className="btn btn-sm btn-primary me-3">
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )}
    </Formik>
    </BlockUi>
    )
}

export{AddBackFillingPage}