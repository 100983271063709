import axios from "axios";
import BackFillBatchesModel from "./BackFillBatchesModel";

export default class BackFillBatchesCommand{
    backfillbatches : BackFillBatchesModel[];
    totalPages: number;
    constructor (){
        this.backfillbatches=[];
        this.totalPages = 0;
    }

    async loadBatches(pageNumber: number, pageSize: number, backfillid:any, entityName : any) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/storeorderbackfill/backfill`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    backfillid:backfillid,
                    entityType : entityName
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });

            const data = response.data.response.items;
            this.backfillbatches = data;
            this.totalPages = response.data.response.totalPages
        } catch (error) {
            console.error('Failed to load backfill data:', error);
        }
    }
}