import axios from "axios";

export default class TransactionDetailsCommand {
    transactionDetails : any

    constructor()
    {
     this.transactionDetails=null;
    }


    async loadTransactionDetails(jobid:any, ksid:any) {
        try {
          const API_URL = process.env.REACT_APP_API_URL;
          const idToken: string | null = localStorage.getItem("IdToken");
          const response = await axios.get(
            `${API_URL}/v1/jobs/${jobid}/transactions/${ksid}/details`,
            {
              headers: {
                Authorization: `${idToken}`,
              },
            }
          );
    
          const data = response.data.response; // Assumes server returns a single item
          this.transactionDetails = data;
        } catch (error) {
          console.error("Failed to load transactions details data:", error);
        }
      }
}