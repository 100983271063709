import axios from "axios";

export default class StoreDetailsViewModel{
    storeDetails : any
    constructor(){
         this.storeDetails=null;
    }

    async LoadStoredetails (id : any){
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const idToken: string | null = localStorage.getItem("IdToken");
            const response = await axios.get(
              `${API_URL}/v1/stores/${id}`,
              {
                headers: {
                  Authorization: `${idToken}`,
                },
              }
            );
      
            const data = response.data.response; // Assumes server returns a single item
            this.storeDetails = data;
          } catch (error) {
            console.error("Failed to load store detais data:", error);
          }
        }
    }
    
