import axios from "axios";

export default class JobBatchesDeatilsCommand {


    jobbatchesdetails : any

    constructor()
    {
     this.jobbatchesdetails=null;
    }


    async loadJobBatchDetail(jobid:any, jobbatchid:any) {
        try {
          const API_URL = process.env.REACT_APP_API_URL;
          const idToken: string | null = localStorage.getItem("IdToken");
          const response = await axios.get(
            `${API_URL}/v1/jobs/${jobid}/jobbatches/${jobbatchid}/details`,
            {
              headers: {
                Authorization: `${idToken}`,
              },
            }
          );
    
          const data = response.data.response; // Assumes server returns a single item
          this.jobbatchesdetails = data;
        } catch (error) {
          console.error("Failed to load jobbatchesdetails data:", error);
        }
      }

}