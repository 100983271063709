import axios from "axios";
import GetCountriesModel from "./GetCountriesModel";

export default class GetCountriesViewModel {
countries : GetCountriesModel[];

constructor(){
    this.countries=[];
}

async loadCountries() {
    try {
        const API_URL = process.env.REACT_APP_API_URL
        const idToken : string | null = localStorage.getItem("IdToken");
        const response = await axios.get(`${API_URL}/v1/mobile-countries`, {
            headers:{
                'Authorization': `${idToken}`
            }
        });
        const data = response.data.response.countries;
        this.countries = data;
    } catch (error) {
        console.error('Failed to load user data:', error);
    }
}

} 