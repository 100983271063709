import axios from "axios";
import OrdersListModel from "./OrderListModel";

export default class OrdersListViewModel {
    Orders: OrdersListModel[];
    totalPages: number;
    totalCount: number;

    constructor() {
        this.Orders = [];
        this.totalPages = 0;
        this.totalCount = 0;
    }

    async loadOrders(pageNumber: number, pageSize: number, partnerName: string, fromDate: Date | null, toDate: Date | null) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken: string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/orders`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    partnerName: partnerName,
                    fromDate: fromDate,
                    toDate: toDate
                },
                headers: {
                    'Authorization': `${idToken}`
                }
            });

            const data = response.data.response.items;
            this.Orders = data;
            this.totalPages = response.data.response.totalPages
            this.totalCount = response.data.response.totalCount
        } catch (error) {
            console.error('Failed to load order data:', error);
        }
    }
}