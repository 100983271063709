import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import { SpinnerDotted } from 'spinners-react';
import { AddUserModel } from './AddUserModel';
import swal from 'sweetalert';
import AddUserViewModel from './AddUserViewModel';
import GetCountriesModel from '../../MGetCountries/VGetCountries/GetCountriesModel';
import GetCountriesViewModel from '../../MGetCountries/VGetCountries/GetCountriesViewModel';
import Select from 'react-select';
import './ReactSelect.css';
import { ThemeSome } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import BlockUi from '../../../app/BlockUI/BlockUi';

const AddUserSchema = Yup.object().shape({
    FirstName: Yup.string()
        .min(1, 'First Name should be at least 2 characters')
        .matches(/^[A-Za-z\s]+$/, 'First Name must only contain alphabetic characters')
        .required('First Name must not be empty.'),
    LastName: Yup.string()
        .min(1, ' LastName should be at least 2 characters')
        .matches(/^[A-Za-z\s]+$/, 'Last Name must only contain alphabetic characters')
        .required('Last Name must not be empty.'),
    PreferredUsername: Yup.string()
        .min(1, 'Display Name should be at least 2 characters')
        .required('Display Name must not be empty.'),
    UserName: Yup.string()
        .min(2, ' Username should be at least 2 characters')
        .required('Username must not be empty.'),
    Email: Yup.string()
        .required('Email must not be empty.')
        .email('Invalid email address'),
    MobileCountryCode: Yup.string()
        .required('Country Code must not be empty.'),
    Mobile: Yup.string()
        .matches(/^[0-9]+$/, 'Mobile should only contain numbers')
        .length(10, 'Mobile number should be 10 digits')
        .required('Mobile Number must not be empty.'),

});

const AddUserPage: React.FC = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [countries, setCountries] = useState<GetCountriesModel[] >([]);
    const viewModel = new AddUserViewModel();
    const countriesViewModel = new GetCountriesViewModel();

    const initialValues: AddUserModel = {
        Id: '',
        FirstName: '',
        LastName: '',
        PreferredUsername: '',
        UserName: '',
        Email: '',
        Mobile: '',
        MobileCountryCode: '',
        Password: '',
        AutoGeneratePassword: isChecked
    };
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (values: AddUserModel, { setSubmitting }: FormikHelpers<AddUserModel>) => {
        setSubmitting(false);
        values.AutoGeneratePassword = isChecked;
        if (values.AutoGeneratePassword)
            values.Password = '';
        setLoading(true);
        const response = await viewModel.CreateUser(values);
        setLoading(false);
        console.log(response);
        if (viewModel.statusCode == 200) {
            swal("Create User", "User Created Successfully", "success");
            setIsChecked(false);
            const inputFields = document.getElementsByClassName('clear');
            console.log(inputFields);
            for (let i = 0; i < inputFields.length; i++) {
                const inputField = inputFields[i] as HTMLInputElement;
                inputField.value = '';
            }
           // setCountries(null);
        }
        if (viewModel.statusCode == 400) {
            swal("Create User", viewModel.responseErrorMessage, "error");
        }
    };

    const [isDisabled, setIsDisabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputClassName, setInputClassName] = useState('form-control');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        setInputClassName(isChecked ? 'fw-bold' : 'd-none password_field');
        const inputFields = document.getElementsByClassName('password_field');
        console.log(inputFields);
        for (let i = 0; i < inputFields.length; i++) {
            const inputField = inputFields[i] as HTMLInputElement;
            inputField.value = '';
        }
        
        setIsDisabled(!isDisabled)
    }

    useEffect(() => {
        const getCountries = async () => {
            await countriesViewModel.loadCountries();
            setCountries(countriesViewModel.countries);
        }
        setLoading(true)
        setTimeout(async() => {
        await getCountries();
        setLoading(false)
        });
    }, [ThemeSome])


    function handleSelect(option: any) {
        console.log(option);
    }
    const [isSubmitting, setIsSubmitting] = useState(false);





    return (
        <BlockUi blocking={isSubmitting || loading} message="">

        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={AddUserSchema}>
            {({ isSubmitting, handleChange, handleBlur }) => (
                <div className="d-flex flex-column flex-lg-row">
                    {
                        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                            <div className="card">
                                <div className="card-body p-12">
                                    <Form >
                                        <div className="mb-0">
                                            <div className="row gx-10 mb-5">
                                                <div className="col-lg-12 d-flex align-items-center mb-10">
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> First Name</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='FirstName' id='FirstName' name='FirstName' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter User First Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'> <ErrorMessage name="FirstName" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Last Name</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='LastName' id='LastName' name='LastName' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter User Last Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="LastName" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Display Name</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='PreferredUsername' id='PreferredUsername' name='PreferredUsername' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter User Display Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="PreferredUsername" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Username</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='UserName' id='UserName' name='UserName' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter Username</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="UserName" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {loading && <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color="#c8c6c2" />}
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Email</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='Email' id='Email' name='Email' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter user email(example: abcd@email.com)</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="Email" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                   
                                                <div className="col-lg-3">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Country Code</label>
                                                    <div className="mb-5">
                                                        
                                                        <select name="MobileCountryCode" id='MobileCountryCode' typeof='MobileCountryCode' onChange={handleChange} onBlur={handleBlur} aria-label="Select Country" data-control="select2" data-placeholder="Select country" className="form-select clear form-select-solid fw-bold">
                                                            <option value="">-Select Country-</option>
                                                            {countries.map(x => (
                                                                <option value={x.mobileCode}>{x.name}</option>
                                                            ))}
                                                        </select> 
                                                        <div className="text-gray-600">Please Select Country Code</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="MobileCountryCode" /></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Mobile</label>
                                                    <div className="mb-5">
                                                        <input placeholder="" type='Mobile' id='Mobile' name='Mobile' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold"  autoComplete="new-password" />
                                                        <div className="text-gray-600">Please enter User Mobile no</div>

                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="Mobile" /></div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className=" form-label fs-6 text-gray-600 fw-bold mb-3">Password</label>
                                                    <div className="mb-5">
                                                        <input
                                                            className={`${inputClassName} form-control clear form-control-solid`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="" type='Password' id='Password' name='Password'
                                                        />
                                                        <div className="form-check mt-2">
                                                            <input
                                                                className="form-check-input clear"
                                                                type="checkbox"
                                                                checked={isChecked}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            <label className="form-check-label">Auto Generated Password</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-0">
                                            <div className="row gx-10 mb-5">
                                                <div className="d-flex flex-stack">
                                                    <div className="d-flex align-items-center me-2">
                                                    </div>
                                                    <button type="submit" role="button" disabled={isSubmitting} className="btn btn-sm btn-primary me-3">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Formik>
        </BlockUi>
    )
}
export { AddUserPage }