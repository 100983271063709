import { type } from "os";
import { useState } from "react";
import ProviderListModel from "./ProviderListModel";


export class ProviderListViewModel{
    provider : ProviderListModel[];

    constructor(){
        this.provider = [];
    }

    async loadData(){

        const providerlist : ProviderListModel[] = [
            new ProviderListModel('1','Zomoto ','17/02/2023','Active'),
            new ProviderListModel('2','DoorDash ','09/02/2023','InActive'),
            new ProviderListModel('3','Cloud Kitchen','01/02/2023','Active'),
            new ProviderListModel('4','Wisely ','28/01/2023','Active'),
            new ProviderListModel('5','Uber Eats ','15/02/2023','InActive'),
        ];
    
        this.provider = providerlist;
    }

    
    
}

   


