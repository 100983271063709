import React , {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import { useHref } from 'react-router-dom';
import {Istores} from '../MStores/IStores'

interface IState {
  stores : Istores[]
}
 
let VStoresList : React.FC = ()=> {

  const href = useHref({ pathname: '/addpartner' });
  
  let [state , setstate] = useState<IState>({
    stores : [
      
        {
          
          storeid  : '1001',
          storename  : 'Store 4',
          location  : 'Hyderabad',
          partner  : 'KFC',
          lastmodified : '17/02/2023',
          status  : 'Active'
      },
      {
          storeid  : '1001',
          storename  : 'Store 4',
          location  : 'Hyderabad',
          partner  : 'KFC',
          lastmodified : '17/02/2023',
          status  : 'Active'
      },
      {
         storeid  : '1001',
          storename  : 'Store 4',
          location  : 'Hyderabad',
          partner  : 'KFC',
          lastmodified : '17/02/2023',
          status  : 'Active'
      },
      {
        storeid  : '1001',
          storename  : 'Store 4',
          location  : 'Hyderabad',
          partner  : 'KFC',
          lastmodified : '17/02/2023',
          status  : 'Active'
      },
      {
        storeid  : '1001',
        storename  : 'Store 4',
        location  : 'Hyderabad',
        partner  : 'KFC',
        lastmodified : '17/02/2023',
        status  : 'Active'
      },
      
     ]
  });
 return(
 
  <div className="d-flex flex-column flex-lg-row">
      
  <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
     
      <div className="card">
         
          <div className="card-header border-0 pt-6">
             
              <div className="card-title">
                  
                  <div className="d-none d-flex align-items-center position-relative my-1">
                     
                      <span className="svg-icon svg-icon-1 position-absolute ms-6">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                               fill="none">
                              <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="black" />
                              <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black" />
                          </svg>
                      </span>
                    
                      <input type="text" data-kt-customer-table-filter="search"
                             className="form-control form-control-solid w-250px ps-15" placeholder="Search Client" />
                  </div>
                 
              </div>
             
              <div className="card-toolbar">
                  
                  <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                     
                      <div className="d-none w-150px me-3">
                          
                          <select className="form-select form-select-solid" data-control="select2" data-hide-search="true"
                                  data-placeholder="Status" data-kt-ecommerce-order-filter="status">
                              <option></option>
                              <option value="all">All</option>
                              <option value="active">Active</option>
                              <option value="locked">Locked</option>
                          </select>
                        
                      </div>
                      
                      <button type="button" className="d-none btn btn-light-primary me-3" data-bs-toggle="modal"
                              data-bs-target="#kt_client_export_modal">
                          
                          <span className="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                   fill="none">
                                  <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1"
                                        transform="rotate(90 12.75 4.25)" fill="black" />
                                  <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                                        fill="black" />
                                  <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                                        fill="#C4C4C4" />
                              </svg>
                          </span>
                         
                      </button>
                      
                       <a role="button" className="btn btn-primary" href={href}
                         data-bs-target="#kt_modal_add_client">
                          Add Partner
                      </a> 
                     
                  </div>
                 
                  <div className="d-flex justify-content-end align-items-center d-none"
                       data-kt-customer-table-toolbar="selected">
                      <div className="fw-bolder me-5">
                          <span className="me-2" data-kt-customer-table-select="selected_count"></span>Selected
                      </div>
                      <button type="button" className="btn btn-danger"
                              data-kt-customer-table-select="delete_selected">
                          Delete Selected
                      </button>
                  </div>
               
              </div>
             
          </div>
          
          <div className="card-body pt-0">
             
              <table className="table align-middle table-row-dashed fs-6 gy-5" id="client_table">

                 
                  <thead>
                      
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                          <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                  <input className="form-check-input" type="checkbox" data-kt-check="true"
                                         data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                              </div>
                          </th>
                          <th className="min-w-125px">Name</th>
                          <th className="min-w-125px">Location</th>
                          <th className="min-w-125px">Partner</th>
                          <th className="min-w-125px">Last Modified</th>
                          <th className="min-w-125px"> Status</th>
                          <th className="text-end min-w-70px">Actions</th>
                      </tr>
                      
                  </thead>
                  
                  <tbody className="fw-bold text-gray-600">
                      {
                          state.stores.length > 0 &&
                          state.stores.map(store => {
                              return(
                      
                          <tr key={store.storeid}>
                              
                              <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                                      <input className="form-check-input" type="checkbox" value="1" />
                                  </div>
                              </td>
                           
                              <td>
                                  {store.storename}
                              </td>
                              
                              <td>
                                  {store.location}
                              </td>
                              
                              <td>
                                  {store.partner}
                              </td>
                              <td>
                                  {store.lastmodified}
                              </td>
                              <td>
                                  {store.status}
                              </td>
                              
                              
                              <td className="text-end">
                              
                              <div className="menu-item">
                                  
                                  <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                 data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">
                                  
                                
                                  
                                  <span className="svg-icon svg-icon-3">
                                      <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                                      </svg>
                                  </span>
                                 
                              </a>
                              <a  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" data-bs-toggle="collapse"
                             data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">
                                  
                                  
                                  <span className="svg-icon svg-icon-3">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                          <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                      </svg>
                                  </span>
                                  
                                      </a>
                              
                              </div>
                             
                              <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4" data-kt-menu="true">
                               {/*                                         
                                  <div className="menu-item">
                                      <a href="/users/@Stores.Id/details" className="menu-link">View</a>
                                  </div> */}
                                 
                              </div>
                              
                          </td>
                            
                          </tr>
                              )
                          } )
                      }
                  </tbody>
                
              </table>
             
              
             
             
          </div>
         
      </div>
   
  </div>
 
</div>

 )
 

}

  export {VStoresList};