import axios from "axios";
import UpdateUserModel from "./UpadteUserModel";

export default class UpdateUserCommand {
    responseErrorMessage : string
      statusCode: number;
      constructor() {
          this.responseErrorMessage = '';
          this.statusCode = 0;
      }

     async UpdateUser(update: UpdateUserModel,id : any,cognitoid : any) {
      const API_URL = process.env.REACT_APP_API_URL
      const idToken : string | null = localStorage.getItem("IdToken");
      const config = {
        headers: { Authorization: `${idToken}` }
      };
      try {
        const response = await axios.put(`${API_URL}/v1/users/${id}/cognito/${cognitoid}`, 
        {firstName : update.FirstName,
         lastName : update.LastName,
         displayname : update.PreferredUsername,
         username:update.UserName,
         email:update.Email,
         mobile: update.Mobile?.slice(3),
         mobileCountryCode : update.MobileCountryCode,
        },config);
        this.statusCode = response.status;
        return response;
      } catch (error :any) {
        if (error.response && error.response.data) {
          this.responseErrorMessage = error.response.data.message;
          this.statusCode = error.response.status
        } else {
          // Handle the generic error message
          throw new Error("Failed to create user");
        }
      }
    }
    
    }
    