import { Value } from "sass";

export default class UpdateUserModel{
       FirstName ? : string = '';
       LastName ? : string = '';
       PreferredUsername ? : string = '';
       UserName?:string = '';
       Email?:string = '';
       Mobile?:string = '';
       MobileCountryCode?:  string = ''
}

export let updateUserDetails : UpdateUserModel = {
      FirstName : '',
      LastName  :'',
      PreferredUsername : '',
      UserName : '',
      Email: '',
      Mobile: '',
      MobileCountryCode : ''
}