import axios from "axios";
import PartnersPaginationModel from "./PartnersPaginationModel";

export default class PartnerPaginationCommand {
    partners : PartnersPaginationModel[];
    totalPages: number;
    totalCount: number;

    constructor()
    {
        this.partners=[];
        this.totalCount=0;
        this.totalPages=0;
    }

    async LoadPartenrs (pageNumber: number, pageSize: number,status: string)
    {
        try{
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1.1/partners`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    status: status
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });
  
            const data = response.data.response.items;
            this.partners = data;
            this.totalPages = response.data.response.totalPages
            this.totalCount= response.data.response.totalCount
        }
        catch(error : any){
            console.error('Failed to load partners data:', error); 
        }
    }

}