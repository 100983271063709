import axios from "axios";
import JobBatchesListModel from "./JobBacthesListModel";

export default class JobBatchesListviewModel{
      jobbatches : JobBatchesListModel[];
      totalPages: number;
      totalCount : number;
      constructor()
      {
        this.jobbatches=[];
        this.totalPages=0;
        this.totalCount=0;
      }

      async loadJobBatches(pageNumber: number, pageSize: number, jobid:any, status : string) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/jobs/${jobid}/batches`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    status : status
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });

            const data = response.data.response.items;
            this.jobbatches = data;
            this.totalPages = response.data.response.totalPages
            this.totalCount=response.data.response.totalCount
        } catch (error) {
            console.error('Failed to load job batches data:', error);
        }
    }
}