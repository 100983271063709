import axios from "axios";
import JobFilesListModel from "./JobFilesListModel";

export default class JobFilesListViewModel{

    jobfiles : JobFilesListModel[];
    totalPages: number;
    totalCount : number;
    constructor()
    {
      this.jobfiles=[];
      this.totalPages=0;
      this.totalCount=0;
    }

    async loadJobFiles(pageNumber: number, pageSize: number, jobid:any,) {
      try {
          const API_URL = process.env.REACT_APP_API_URL
          const idToken : string | null = localStorage.getItem("IdToken");
          const response = await axios.get(`${API_URL}/v1/jobs/${jobid}/files`, {
              params: {
                  page: pageNumber,
                  pageSize: pageSize,
              },
              headers:{
                  'Authorization': `${idToken}`
              }
          });

          const data = response.data.response.items;
          this.jobfiles = data;
          this.totalPages = response.data.response.totalPages
          this.totalCount= response.data.response.totalCount
      } catch (error) {
          console.error('Failed to load job files data:', error);
      }
  }
}