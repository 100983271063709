import axios from "axios";

export default class BaclFillDetailsViewModel{
    backFillDetails: any; // Change type to match expected response format
    constructor() {
      this.backFillDetails = null;
    }
  
    async loadData(id: any, entityName : any) {
      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const idToken: string | null = localStorage.getItem("IdToken");
        const response = await axios.get(
          `${API_URL}/v1/backfill/${id}/details`,
          {
            params: {
              entityType : entityName
          },
            headers: {
              Authorization: `${idToken}`,
            },
          }
        );
  
        const data = response.data.response; // Assumes server returns a single item
        this.backFillDetails = data;
      } catch (error) {
        console.error("Failed to load backfillDetail data:", error);
      }
    }
  
}