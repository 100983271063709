import axios from "axios";
import BrandsModel from "./BarndsModel";

export default class BrandsViewmodel{
    brands : BrandsModel[];

    constructor(){
        this.brands=[];
    }

    async LoadBrands(partnername:any) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/partners/${partnername}/brands`, {
                headers:{
                    'Authorization': `${idToken}`
                }
            });
            const data = response.data.response.brands;
            this.brands = data;
        } catch (error) {
            console.error('Failed to load Partner brands data:', error);
        }
    }
}