import axios from "axios";
import ProviderModel from "./ProviderModel";


export default class ProviderViewModel{
    providers : ProviderModel[];

    constructor(){
        this.providers=[];
    }

    async LoadProviders() {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/providers`, {
                headers:{
                    'Authorization': `${idToken}`
                }
            });
            const data = response.data.response.providers;
            this.providers = data;
        } catch (error) {
            console.error('Failed to load Partner data:', error);
        }
    }
}