import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup'
import PasswordChangeModel from './PasswordChangeModel';
import PasswordChangeCommand from './PasswordChangeCommand';
import swal from 'sweetalert';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { title } from 'process';
import { SpinnerDotted } from 'spinners-react';
import BlockUi from '../../../app/BlockUI/BlockUi';


const changepasswordschema = Yup.object().shape({
    CurrentPassword: Yup.string()
    .required('CurrentPassword must not be empty.'),
  NewPassword: Yup.string()
    .required('NewPassword must not be empty.')
    .notOneOf([Yup.ref('CurrentPassword')], 'NewPassword must not match CurrentPassword.'),
    ConfirmNewPassword: Yup.string()
        .oneOf([Yup.ref('NewPassword'), null], 'Passwords must match')
        .required('Confirm NewPassword must not be empty.')

});

const PasswordChangePage: React.FC = () => {
    const command = new PasswordChangeCommand();
    const { id, cognitoid } = useParams<string>();
    const [loading, setLoading] = useState(false)

    const initialValues: PasswordChangeModel = {
        CurrentPassword: '',
        NewPassword: '',
        ConfirmNewPassword: ''
    };

    const handleSubmit = async (values: PasswordChangeModel, { setSubmitting }: FormikHelpers<PasswordChangeModel>) => {
        setSubmitting(false);
        swal({
            title: "Are you sure?",
            text: "Do you want to reset your password?",
            icon: "warning",
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then(async (willReset) => {
            if (willReset) {
                setLoading(true);
                const response = await command.PasswordChange(values);
                if (command.statusCode == 200) {
                    swal("Update Password", "Password Updated Successfully", "success");
                    const inputFields = document.getElementsByClassName('clear');
                    console.log(inputFields);
                    for (let i = 0; i < inputFields.length; i++) {
                        const inputField = inputFields[i] as HTMLInputElement;
                        inputField.value = '';
                    }
                    setLoading(false);
                }
                if (command.statusCode == 400) {
                    swal("Update Password", command.responseErrorMessage, "error");
                    setLoading(false);
                }
            } else {
                swal("Ok");
            }
        });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <BlockUi blocking={isSubmitting || loading} message="">
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={changepasswordschema}>
            {({ isSubmitting, handleChange, handleBlur }) => (
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">  
                     <div className="card p-12">
                        <div className='row '>
                            <div className='col-lg-6'>
                        <Form>
                            {/* <div className='pb-5 pb-lg-10'>
                                <h3 className='fw-bolder text-dark display-6'>Change Password </h3>
                            </div> */}
                            {loading && <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color="#c8c6c2" />}
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-gray-600  pt-5'>Current Password</label>
                                <input type='Password' name='CurrentPassword' id='CurrentPassword' onChange={handleChange} onBlur={handleBlur} className='form-control clear form-control-lg form-control-solid' />
                                <div className="text-gray-600">Please enter Current Password</div>
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <ErrorMessage name="CurrentPassword" />
                                    </div>
                                </div>
                            </div>
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-gray-600  pt-5'>New Password</label>
                                <input type='Password' name='NewPassword' id='NewPassword' onChange={handleChange} onBlur={handleBlur} className='form-control clear form-control-lg form-control-solid' />
                                <div className="text-gray-600">Please enter New Password</div>
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <ErrorMessage name="NewPassword" />
                                    </div>
                                </div>
                            </div>
                            <div className='fv-row mb-10'>
                                <label className='form-label fs-6 fw-bolder text-gray-600 pt-5'>Confirm New Password</label>
                                <input type='Password' name='ConfirmNewPassword' id='ConfirmNewPassword' onChange={handleChange} onBlur={handleBlur} className='form-control clear form-control-lg form-control-solid' />
                                <div className="text-gray-600">Please Re-Enter New Password</div>
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <ErrorMessage name="ConfirmNewPassword" />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex flex-stack pb-lg-0'>
                                <div></div>
                                <div>
                                    <button type='submit' role='button' disabled={isSubmitting} className='btn btn-sm btn-primary me-3'>Submit</button>
                                </div>
                            </div>
                        </Form>
                        </div>
                        <div className='col-lg-6 d-flex justify-content-center align-items-center '>
                        <img src="\media\svg\easy\2.svg" className="theme-light-show w-200px mt-2" alt=""/>
                        <img src="\media\svg\easy\2-dark.svg" className="theme-dark-show w-200px mt-2" alt=""/>
                        </div>
                        </div>

                       
                   </div>
                    </div>
                </div>
            )}
        </Formik>
        </BlockUi>
        
    )
}
export { PasswordChangePage }
