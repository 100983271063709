/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {SampleDash} from '../../../../Dashboard/OverviewDashboard'

export function AsideMenuMain() {
  return (
    <>
      
      <AsideMenuItemWithSub
        to='/dashboard'
        title='Dashboard'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/dashboard/overview' title='Overview' hasBullet={true} />
        {/* <AsideMenuItem to='/dashboard/partner' title='Partners' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/dashboard/provider' title='Providers' hasBullet={true} /> */}
        {/* <AsideMenuItem to='/dashboard/fileimport' title='File Imports' hasBullet={true} /> */}
         <AsideMenuItem to='/dashboard/infra' title='Infrastucture' hasBullet={true} /> 
        <AsideMenuItem to='/dashboard/support' title='Support' hasBullet={true} />

      </AsideMenuItemWithSub>

 

      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Jobs'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/jobs/list' title='List' hasBullet={true} />

      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Orders'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/Order/list' title='List' hasBullet={true} />

      </AsideMenuItemWithSub>
     
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Transactions'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
       <AsideMenuItem to='/transaction/list' title='List' hasBullet={true} />
       
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/crafted/widgets' title='Partners' icon='/media/icons/duotune/arrows/arr001.svg' fontIcon='bi-layers'>
      <div className="row">
    <div className="col-10">
      <AsideMenuItem to='/partner/list' title='List' hasBullet={true} />
    </div>
    <div className="col-2 text-right mt-3">
      <span className="menu-badge"><span className="badge badge-success"   style={{
                                  color: 'var(--bs-primary-inverse)',
                                  backgroundColor: '#f73e00',
                                }}>Beta</span></span>
    </div>
  </div>
  <AsideMenuItem to='patners/stores/list' title='Stores' hasBullet={true} />
  <AsideMenuItem to='' title='Add Item' hasBullet={true} />
</AsideMenuItemWithSub>

<AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Backfills'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/backfill/list' title='List' hasBullet={true} />
        <AsideMenuItem to='/backfill/new' title='New' hasBullet={true} />

      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Users'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
       <AsideMenuItem to='/users/list' title='List' hasBullet={true} />
       <AsideMenuItem to='/users/new' title='New' hasBullet={true} />

        
      </AsideMenuItemWithSub>
 
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Providers'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/providers/list' title='List' hasBullet={true} />
        
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub
        to='/error'
        title='Partner'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/partners/list' title='List' hasBullet={true} />
        <AsideMenuItem to='/add/partner' title='Add Partner' hasBullet={true} />

      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='File Imports'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/file/imports' title='List' hasBullet={true} />

      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Stores'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/stores/list' title='List' hasBullet={true} />
        <AsideMenuItem to='/add/stores' title='Add Stores' hasBullet={true} />

      </AsideMenuItemWithSub> */}

     
    </>
  )
}
