/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useEffect, useRef, useState } from 'react'
import { SearchComponent } from '../../../assets/ts/components'
import { KTSVG } from '../../../helpers'
type Props = {
  className?: string
  mobileToggleBtnClass?: string
}
const Search: FC<Props> = ({ className = '', mobileToggleBtnClass = '' }) => {
  const [menuState, setMenuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)
  const resultsElement = useRef<HTMLDivElement | null>(null)
  const suggestionsElement = useRef<HTMLDivElement | null>(null)
  const emptyElement = useRef<HTMLDivElement | null>(null)

  const processs = (search: SearchComponent) => {
    setTimeout(function () {
      
    }, 1500)
  }

  const clear = (search: SearchComponent) => {
  }

  useEffect(() => {
    
  }, [])

  return (


    <div
      
    >
      {/* <div className="col-lg-6">
        <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Partner</label>
        <div className="mb-5">
          <select name="PartnerName" typeof='PartnerName' id='PartnerName' aria-label="Select PartnerName"  data-control="select2" data-placeholder="Select partner" className="form-select clear form-select-solid" >
            <option value="">-Select Partner-</option>
            <option value="KFC">KFC</option>
            <option value="TacoBell">Taco Bell</option>
            <option value="BurgerKing">Burger King</option>
            <option value="ShawarmaCompany">Shawarma Company</option>
            <option value="Naturals">Naturals</option>
            <option value="Paradise">Paradise</option>
            <option value="Dominos">Dominos</option>
            <option value="StarBucks">Star Bucks</option>
          </select>
          <div className="text-gray-600">Please Select Partner </div>
        </div>
      </div> */}

      
      <div className={clsx('d-flex d-lg-none align-items-center')} data-kt-search-element='toggle'>
        <div className={mobileToggleBtnClass}>
          <KTSVG path='' className='svg-icon-1' />
        </div>
      </div>

      <form
        data-kt-search-element='form'
        className='d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative'
        autoComplete='off'
      >
        <KTSVG
          path='/media/icons/duotune/arrows/gen004.svg'
          className='svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4'
        />

        <input
          className='form-control bg-transparent ps-13 fs-7 h-40px'
          placeholder='Quick Search'
        />
        <span
          className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
          data-kt-search-element='clear'
        >
          <KTSVG
            path=''
            className='svg-icon-2 svg-icon-lg-1 me-0'
          />
        </span>
      </form>
    </div>
  )
}

export { Search }

