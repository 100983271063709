import { useEffect, useState } from "react"
import GetAllStoresViewModel from "./GetAllStoresViewModel"
import GetAllStoresModel from "./GetAllStoresModel"
import PartnersViewModel from "../../MBackFillings/VPartners/PartnersViewModel"
import PartnersModel from "../../MBackFillings/VPartners/PartnersModel"
import BlockUi from "../../../app/BlockUI/BlockUi"
import { SpinnerDotted } from "spinners-react"
import { Link } from "react-router-dom"
import ReactPaginate from "react-paginate"

let GetAllStoresPaginationPage: React.FC = () => {

    const [loading, setLoading] = useState(true)
    const viewModel = new GetAllStoresViewModel()
    const [currentPage, setCurrentPage] = useState(1)
    const [status, setStatus] = useState<string>('')
    const [currentData, setCurrentData] = useState<GetAllStoresModel[]>([])
    const [partners, setPartners] = useState<PartnersModel[]>([])
    const [partnerid, setPartnerName] = useState<string>('all')
    const [pageCount, setPageCount] = useState(0)
    const rowsPerPage = 5
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);
    const partnersViewmodel = new PartnersViewModel()
    const [storesCount, setStoresCount] = useState(0);
    const [totalOrderCount, setTotalOrderCount] = useState(0);
    const [filterbyid, setFilterbyid] = useState<string>('');

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1)
    }

    const fetchStores = async (
        partnerid: string | undefined,
        page: any, rowsPerPage: number,
        status: string,
        filterbyid: any
    ) => {
        setLoading(true);
        await viewModel.LoadAllStoreList(page, rowsPerPage, partnerid, status, filterbyid);
        setCurrentData(viewModel.allstorelist);
        setPageCount(viewModel.totalPages);
        setStoresCount(viewModel.totalCount);
        setLoading(false);
    }

    const getpartners = async () => {
        await partnersViewmodel.LoadPartners()
        setPartners(partnersViewmodel.partners)
    }

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true)
            getpartners()
            await fetchStores(partnerid, currentPage, selectedRowsPerPage, status, filterbyid)
            setLoading(false)
        }
       
        fetchOrders()
    }, [currentPage, partnerid, selectedRowsPerPage])

    const handleSearch = async () => {
        setLoading(true);
        setCurrentPage(1);
        await fetchStores(partnerid,currentPage,selectedRowsPerPage,status, filterbyid);
        setLoading(false);
      };
    
      const handleInputChange = (event: any) => {
        setFilterbyid(event.target.value.trim()); // Remove leading/trailing whitespace
      };
    
      const handleInputKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent the form submission
          handleSearch();
        }
      };
      const handleInputBlur = () => {
        handleSearch();
      };
    return (
        <BlockUi blocking={loading} message=''>
            <div className='d-flex flex-column flex-lg-row'>
                {
                    <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
                        <div className='card'>

                            <div className='card-header border-0 pt-6'>
                                <div className='card-title'>
                                    <div className=' d-flex align-items-center position-relative my-1'>
                                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                                                <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"></path>
                                                <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <input
                                            className="form-control form-control-solid w-250px ps-13 fs-7 h-40px"
                                            placeholder="Quick Search"
                                            data-kt-customer-table-filter="search"
                                            onChange={handleInputChange}
                                            onKeyDown={handleInputKeyDown}
                                            onBlur={handleInputBlur}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-stack flex-wrap gap-2'>
                                    <div className='d-flex flex-stack flex-wrap gap-2'>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <select
                                                className='form-select clear form-select-solid'
                                                onChange={async (event) => {
                                                    setCurrentPage(1)
                                                    const selectedPartnerName = event.target.value
                                                    setPartnerName(selectedPartnerName)

                                                }}
                                               
                                            >
                                                <option value='all'>-Select Partner-</option>
                                                {partners.map((x) => (
                                                    <option value={x.name}>{x.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className=' w-150px me-3'>
                                        <select
                                            className='form-select clear form-select-solid'
                                            onChange={async (event) => {
                                                setCurrentPage(1);
                                                const selectedStatus = event.target.value
                                                setStatus(selectedStatus)
                                                await fetchStores(partnerid, 1, selectedRowsPerPage, selectedStatus, filterbyid);
                                            }}
                                        >
                                            <option value=''>-Select Status-</option>
                                            <option value='ACT'>Active</option>
                                            <option value='INA'>Inactive</option>
                                        </select>
                                    </div>
                                    <div className='d-flex'>
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-primary me-3'
                                        >
                                            Refresh
                                        </button>
                                    </div>
                                    <button
                                        type='button'
                                        className='d-none btn btn-light-primary me-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_client_export_modal'
                                    >
                                        <span className='svg-icon svg-icon-2'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='2'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                            >
                                                <rect
                                                    opacity='0.3'
                                                    x='12.75'
                                                    y='4.25'
                                                    width='12'
                                                    height='1.5'
                                                    rx='1'
                                                    transform='rotate(90 12.75 4.25)'
                                                    fill='black'
                                                />
                                                <path
                                                    d='M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z'
                                                    fill='black'
                                                />
                                                <path
                                                    d='M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z'
                                                    fill='#C4C4C4'
                                                />
                                            </svg>
                                        </span>
                                    </button> 
                                </div>
                            </div>
                            <div className='card-body pt-0'>
                                <table className='table align-middle table-row-dashed fs-6 gy-5' id='client_table'>
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                                            <th className='min-w-125px'>Id</th>
                                            <th className='min-w-125px'>Partner Name</th>
                                            <th className='min-w-125px'> Brand Name</th>
                                            <th className='min-w-125px'>Status</th>
                                            <th className='text-end min-w-70px'>Actions</th>
                                            {loading && (
                                                <SpinnerDotted
                                                    size={80}
                                                    thickness={120}
                                                    className='spinner'
                                                    speed={100}
                                                    color='#c8c6c2'
                                                />
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className='fw-bold text-gray-600'>
                                        {currentData.map((stores) => {

                                            return (
                                                <tr key={stores.id}>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {stores?.id?.toString()?.length &&
                                                            (stores.id.toString().length >= 7
                                                                ? `${stores.id
                                                                    .toString()
                                                                    .slice(0, 4)}...${stores.id.toString().slice(-2)}`
                                                                : stores.id.toString())}
                                                    </td>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {stores.partnerName}
                                                    </td>
                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {stores.brandName}
                                                    </td>
                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {(stores.status === 'INPROGRESS' ||
                                                            stores.status === 'INIT' ||
                                                            stores.status === 'IN_PROGRESS') && (
                                                                <div className='badge badge-light-primary fw-bolder'>IN PROGRESS</div>
                                                            )}
                                                        {stores.status === 'PAUSE' && (
                                                            <div
                                                                className='badge badge-light-primary fw-bolder'
                                                                style={{ color: '#f15738', backgroundColor: '#FBF2EF' }}
                                                            >
                                                                PAUSED
                                                            </div>
                                                        )}
                                                        {stores.status === 'PROCESSING' && (
                                                            <div className='badge badge-light-primary fw-bolder'>PROCESSING</div>
                                                        )}
                                                        {stores.status === 'COMPLETE' && (
                                                            <div className='badge badge-light-success fw-bolder'>COMPLETED</div>
                                                        )}
                                                        {stores.status === 'STOP' && (
                                                            <div className='badge badge-light-danger fw-bolder'>STOP</div>
                                                        )}
                                                        {stores.status === 'ERROR' && (
                                                            <div className='badge badge-light-danger fw-bolder'>ERROR</div>
                                                        )}
                                                        {stores.status === 'INA' && (
                                                            <div className='badge badge-light-danger fw-bolder'>Inactive</div>
                                                        )}
                                                        {stores.status === 'ACT' && (
                                                            <div className='badge badge-light-success fw-bolder'>Active</div>
                                                        )}
                                                    </td>
                                                    <td className='text-end'>
                                                        <div className='menu-item'>
                                                            <Link to={`/stores/${stores.id}/details`}>
                                                                <button
                                                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                                                    data-kt-menu-trigger='click'
                                                                    role='button'
                                                                    data-kt-menu-placement='bottom-end'
                                                                >
                                                                    <span className='svg-icon svg-icon-3'>
                                                                        <svg
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 -4 24 24'
                                                                            id='meteor-icon-kit__solid-eye'
                                                                            fill='none'
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                        >
                                                                            <path
                                                                                fillRule='evenodd'
                                                                                clipRule='evenodd'
                                                                                d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                                                                fill='#758CA3'
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className='d-flex justify-content-between'>
                                    <div className='dataTables_length d-flex align-items-center ' id='kt_ecommerce_products_table_length'>
                                        <label>
                                            <select
                                                name='kt_job_batches_table_length'
                                                aria-controls='kt_ecommerce_products_table'
                                                className='form-select form-select-sm form-select-solid'
                                                value={selectedRowsPerPage}
                                                onChange={(e) => {
                                                    const newRowsPerPage = Number(e.target.value);
                                                    setSelectedRowsPerPage(newRowsPerPage);
                                                    setCurrentPage(1); // Reset batch page to 1
                                                    // Reset order page to 1
                                                }}

                                            >
                                                <option value='5'>5</option>
                                                <option value='10'>10</option>
                                                <option value='20'>20</option>
                                                <option value='30'>30</option>
                                                <option value='40'>40</option>
                                                <option value='50'>50</option>
                                                <option value='60'>60</option>
                                                <option value='70'>70</option>
                                                <option value='80'>80</option>
                                                <option value='90'>90</option>
                                                <option value='100'>100</option>
                                            </select>
                                        </label>
                                        <div className='mr-2 fw-bolder fs-7 text-gray-700'>Total number of rows: {storesCount}</div>
                                    </div>

                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageClick}
                                        previousLabel={'<<'} // Disable the previous button for the first page
                                        nextLabel={'>>'}
                                        breakLabel={'...'}
                                        activeClassName={'active'}
                                        containerClassName={'pagination justify-content-end'}
                                        pageClassName={'page-item'}
                                        pageLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        previousClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextClassName={'page-item'}
                                        nextLinkClassName={'page-link'}
                                        initialPage={0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </BlockUi>
    )
}
export { GetAllStoresPaginationPage }