import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StoreDetailsModel from "./StoreDetailsModel";
import StoreDetailsViewModel from "./StoreDetailsViewModel";
import BlockUi from "../../../app/BlockUI/BlockUi";
import { toAbsoluteUrl } from "../../../_metronic/helpers";
import { SpinnerDotted } from "spinners-react";

let StoreDetailsPage : React.FC =() => {

    
    const [loading, setLoading] = useState(false)
    const {id } = useParams<{id :string} >()
    const [storeDetails , setstoreDetails] = useState<StoreDetailsModel | null>(null);

      

    const fetchStoresDetails = async () => {
        const command = new StoreDetailsViewModel();
        await command.LoadStoredetails(id);
        setstoreDetails(command.storeDetails);
    }

    useEffect(() => {
        setLoading(true)
        setTimeout(async () => {
          await fetchStoresDetails();
          setLoading(false)
        })
      }, []);
    return(
        <BlockUi blocking={loading} message=''>
        <div className='position-absolute top-0 start-50 translate-middle-x mt-20 pt-5'>
        {storeDetails?.status == 'ACT' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '30.0em', borderRadius: 4
           }}
            
          >
            Active
          </div>
        )}
         {storeDetails?.status == 'INA' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '30.0em', borderRadius: 4
           }}
            
          >
            Inactive
          </div>
        )}
        {(storeDetails?.status == 'INIT'|| storeDetails?.status=='INPROGRESS' || storeDetails?.status=='IN_PROGRESS') && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '32.0em', borderRadius: 4}}
          >
            IN PROGRESS
          </div>
        )}
        {storeDetails?.status == 'PAUSE' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4,color: 'var(--bs-primary-inverse)',
            backgroundColor: '#f73e00',}}
            
          >
            PAUSED
          </div>
        )}
          {storeDetails?.status == 'PROCESSING' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '32.0em', borderRadius: 4
           }}
            
          >
            PROCESSING
          </div>
        )}
          {storeDetails?.status == 'COMPLETE' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            COMPLETE
          </div>
        )}
         {storeDetails?.status == 'STOP' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            STOP
          </div>
        )}
         {storeDetails?.status == 'ERROR' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            ERROR
          </div>
        )}
        </div>
        <div className='d-flex flex-column flex-xl-row'>
          {/*begin::Sidebar*/}
          <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            {/*begin::Card*/}
            <div className='card mb-5 mb-xl-8'>
              {/*begin::Card body*/}
              <div className='card-body pt-15'>
                {/*begin::Summary*/}
                <div className='d-flex flex-center flex-column mb-5'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-100px symbol-rectangle mb-7'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/avatars/store.png')} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Name*/}
                  <a className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'></a>
                  {/*end::Name*/}
                </div>
                {/*end::Summary*/}
                {/*begin::Details toggle*/}
                {loading && (
                  <SpinnerDotted
                    size={80}
                    thickness={120}
                    className='spinner'
                    speed={100}
                    color='#c8c6c2'
                  />
                )}
                <div className='d-flex flex-stack fs-4 py-3'>
                  <a
                    className='fw-bolder text-black rotate collapsible collapsed'
                    href='#kt_customer_view_details'
                    data-bs-toggle='collapse'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_customer_view_details'
                  >
                    Details
                  </a>
                </div>
                {/*end::Details toggle*/}
                <div className='separator separator-dashed my-3'></div>
  
                {/*begin::Details content*/}
                <div id='kt_customer_view_details' className='collapse show'>
                  <div className='py-3 fs-6'>
                    <div className='fw-bolder mt-5'> Id</div>
                    <div className='text-gray-600'>{storeDetails?.id}</div>
                  </div> 
                  <div className='py-3 fs-6'>
                    <div className='fw-bolder mt-5'>Brand Name</div>
                    <div className='text-gray-600'>{storeDetails?.brandName}</div>
                  </div>
                
                  {/* <div className='py-3 fs-6'>
                    <div className='fw-bolder mt-5'>Brand</div>
                    <div className='text-gray-600'>{orederdetails?.brandName}</div>
                  </div>  */}
                </div>
                {/*end::Details content*/}
              </div>
              {/*end::Card body*/}
            </div>
          </div>
          <div className='flex-lg-row-fluid ms-lg-15'>
            {/*begin:::Tabs*/}
            <div className='d-flex justify-content-between'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
                {/*begin:::Tab item*/}
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#device_overview_tab'>
                    Overview
                  </a>
                </li>
              </ul>
              <div className='d-flex align-items-end justify-content-end mb-8'>
                <div className='ms-auto'>
                  <div className='me-0'>
                    {/* <button
                             className='btn btn-sm btn-primary me-3'
                             role='button'
                           >
                             Edit
                           </button> */}
                    {/* <button
                           className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                           data-kt-menu-trigger='click'
                           data-kt-menu-placement='bottom-end'
                           style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                         >
                           <i className='bi bi-three-dots fs-3'></i>{' '}
                         </button> */}
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <a
                          role='button'
                          className='menu-link px-3'
                        >
                          InActive User
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a role='button' className='menu-link px-3'>
                          Reset Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-content' id='myTabContent'>
              {/*begin:::Tab pane*/}
  
              <div className='tab-pane fade show active' id='device_overview_tab' role='tabpanel'>
                {/*begin::Card*/}
                <div className='card pt-4 mb-6 mb-xl-9'>
                  {/*begin::Card header*/}
                  <div className=''><h3 className='card-title fs-6 text-gray-700 pt-2'></h3></div>
  
                  <div className='card-body pt-0'>
                    <div className='mb-0'>
                      <div className='row gx-10 mb-5'>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Partner Name</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.partnerName}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Brand Name</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.brandName}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Provider Name</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.providerName}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Type</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.type}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Partner Config</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.partnerConfigName}
                            />
                          </div>
                        </div>
                         <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Adapter Name </label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                               storeDetails?.adapterName
                              }
                            />
                          </div>
                        </div> 
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Partner Store Id</label>
                           <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                             storeDetails?.partnerStoreId
                              }
                            />
                          </div> 
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Provider Store Id</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.providerStoreId}
                            />
                          </div>
                        </div> 
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Create Time</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.createTime?.slice(0, 10).replace("T", " ")}
                            />
                          </div>
                        </div> 
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified time</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.lastModifiedTime?.slice(0, 10).replace("T", " ")}
                            />
                          </div>
                        </div> 
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified By</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={storeDetails?.lastModifiedBy}
                            />
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BlockUi>
    )
}
export {StoreDetailsPage}