import axios from "axios";
import PartnerListModel from "./PartnerListModel";


export default class partnerListCommand{
    partnerlist : PartnerListModel[];

    constructor(){
        this.partnerlist=[];
    }

    async LoadPartnersList(entityName:any) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/partners/list`, {
                params: {
                    entityType : entityName,
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });
            const data = response.data.response.partners;
            this.partnerlist = data;
        } catch (error) {
            console.error('Failed to load Partner data:', error);
        }
    }
}