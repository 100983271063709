import axios from "axios";
import PartnersModel from "./PartnersModel";

export default class PartnersViewModel{
    partners : PartnersModel[];

    constructor(){
        this.partners=[];
    }

    async LoadPartners() {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/partners`, {
                headers:{
                    'Authorization': `${idToken}`
                }
            });
            const data = response.data.response.partners;
            this.partners = data;
        } catch (error) {
            console.error('Failed to load Partner data:', error);
        }
    }
}