import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import PartnerDetailsModel from "./PartnerDetailsModel"
import PartnerDetailsViewModel from "./PartnerDetailsViewModel"
import BlockUi from "../../../app/BlockUI/BlockUi"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import { SpinnerDotted } from "spinners-react"
import StorePagnationListModel from "../../MStoreOps/VStoresPaginationList/StorePagnationListModel"
import StorePaginationListViewModel from "../../MStoreOps/VStoresPaginationList/StorePaginationListViewModel"
import ReactPaginate from "react-paginate"

let PartnerDetailsPage : React.FC = () =>{


    const [loading, setLoading] = useState(false)
    const {id , partnerName} = useParams< string >()
    const [partnerDetails , setPartnerDetails] = useState<PartnerDetailsModel | null>(null);

   
const [currentStorePage, setCurrentStorePage] = useState(1)
const [currentData, setCurrentData] = useState<StorePagnationListModel[]>([])
const [pageCount, setPageCount] = useState(0)
const storeslistcommand = new StorePaginationListViewModel();
const [status, setStatus] = useState<string>('')
const [filterbyid, setFilterbyid] = useState<string>('');

const handlePageClick = ({selected}: {selected: number}) => {
  setCurrentStorePage(selected + 1)
} 

const [storesCount, setStoresCount] = useState(0);
const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);


   const fetchStoredList = async (partnerName : string|undefined, page : any, rowsPerPage: number , status:string,filterbyid: any) => {
     
    setLoading(true);
    await storeslistcommand.LoadStoreList (page,rowsPerPage,partnerName,status,filterbyid);
    setCurrentData(storeslistcommand.storelist);
    setPageCount(storeslistcommand.totalPages);
    setStoresCount(storeslistcommand.totalCount);
    setLoading(false);

   }

    const fetchPartnerDetails = async () => {

        const command = new PartnerDetailsViewModel();
        await command.LoadPartneDetails(id);
        setPartnerDetails(command.partnerDetails);
    }

    useEffect (() => {
       setLoading(true);
      const getAllDetails = async () =>{
            await fetchPartnerDetails();
            await fetchStoredList(partnerDetails?.partnerName,currentStorePage,selectedRowsPerPage,status, filterbyid);
        }
       setLoading(false);
       getAllDetails();
    },[currentStorePage,selectedRowsPerPage,partnerDetails?.partnerName,status])


    const handleSearch = async () => {
      setLoading(true);
      setCurrentStorePage(1);
      await fetchStoredList(partnerDetails?.partnerName,currentStorePage,selectedRowsPerPage,status, filterbyid);
      setLoading(false);
    };
  
    const handleInputChange = (event: any) => {
      setFilterbyid(event.target.value.trim()); // Remove leading/trailing whitespace
    };
  
    const handleInputKeyDown = (event: any) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the form submission
        handleSearch();
      }
    };
    const handleInputBlur = () => {
      handleSearch();
    };

    return (
        <BlockUi blocking={loading} message=''>
        <div className='position-absolute top-0 start-50 translate-middle-x mt-20 pt-5'>
        {partnerDetails?.status == 'ACT' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '26.0em', borderRadius: 4
           }}
            
          >
            Active
          </div>
        )}
         {partnerDetails?.status == 'INA' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '26.0em', borderRadius: 4
           }}
            
          >
            Inactive
          </div>
        )}
        {(partnerDetails?.status == 'INIT'|| partnerDetails?.status=='INPROGRESS' || partnerDetails?.status=='IN_PROGRESS') && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '32.0em', borderRadius: 4}}
          >
            IN PROGRESS
          </div>
        )}
        {partnerDetails?.status == 'PAUSE' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4,color: 'var(--bs-primary-inverse)',
            backgroundColor: '#f73e00',}}
            
          >
            PAUSED
          </div>
        )}
          {partnerDetails?.status == 'PROCESSING' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '32.0em', borderRadius: 4
           }}
            
          >
            PROCESSING
          </div>
        )}
          {partnerDetails?.status == 'COMPLETE' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            COMPLETE
          </div>
        )}
         {partnerDetails?.status == 'STOP' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            STOP
          </div>
        )}
         {partnerDetails?.status == 'ERROR' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '37.0em', borderRadius: 4
           }}
            
          >
            ERROR
          </div>
        )}
      
        </div>
        <div className='d-flex flex-column flex-xl-row'>
          {/*begin::Sidebar*/}
          <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
            {/*begin::Card*/}
            <div className='card mb-5 mb-xl-8'>
              {/*begin::Card body*/}
              <div className='card-body pt-15'>
                {/*begin::Summary*/}
                <div className='d-flex flex-center flex-column mb-5'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-100px symbol-rectangle mb-7'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Name*/}
                  <a className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'></a>
                  {/*end::Name*/}
                </div>
                {/*end::Summary*/}
                {/*begin::Details toggle*/}
                {loading && (
                  <SpinnerDotted
                    size={80}
                    thickness={120}
                    className='spinner'
                    speed={100}
                    color='#c8c6c2'
                  />
                )}
                <div className='d-flex flex-stack fs-4 py-3'>
                  <a
                    className='fw-bolder text-black rotate collapsible collapsed'
                    href='#kt_customer_view_details'
                    data-bs-toggle='collapse'
                    role='button'
                    aria-expanded='false'
                    aria-controls='kt_customer_view_details'
                  >
                    Details
                  </a>
                </div>
                {/*end::Details toggle*/}
                <div className='separator separator-dashed my-3'></div>
  
                {/*begin::Details content*/}
                <div id='kt_customer_view_details' className='collapse show'>
                  <div className='py-3 fs-6'>
                    <div className='fw-bolder mt-5'>Partner Name</div>
                    <div className='text-gray-600'>{partnerDetails?.partnerName}</div>
                  </div> 
                  <div className='py-3 fs-6'>
                    <div className='fw-bolder mt-5'>Provider Name</div>
                    <div className='text-gray-600'>{partnerDetails?.providerName}</div>
                  </div>
                </div>
                {/*end::Details content*/}
              </div>
              {/*end::Card body*/}
            </div>
          </div>
          <div className='flex-lg-row-fluid ms-lg-15'>
            {/*begin:::Tabs*/}
            <div className='d-flex justify-content-between'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
                {/*begin:::Tab item*/}
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#device_overview_tab'>
                    Overview
                  </a>
                </li>
                <li className='nav-item'>
                  <a className='nav-link' data-bs-toggle='tab' href='#partners_stores_tab'>
                    Stores
                  </a>
                </li>
              </ul>
              <div className='d-flex align-items-end justify-content-end mb-8'>
                <div className='ms-auto'>
                  <div className='me-0'>
                    <button
                             className='btn btn-sm btn-primary me-3'
                             role='button'
                           >
                             Edit
                           </button> 
                    {/* <button
                           className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                           data-kt-menu-trigger='click'
                           data-kt-menu-placement='bottom-end'
                           style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                         >
                           <i className='bi bi-three-dots fs-3'></i>{' '}
                         </button> */}
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <a
                          role='button'
                          className='menu-link px-3'
                        >
                          InActive User
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a role='button' className='menu-link px-3'>
                          Reset Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-content' id='myTabContent'>
              {/*begin:::Tab pane*/}
  
              <div className='tab-pane fade show active' id='device_overview_tab' role='tabpanel'>
                {/*begin::Card*/}
                <div className='card pt-4 mb-6 mb-xl-9'>
                  {/*begin::Card header*/}
                  <div className=''><h3 className='card-title fs-6 text-gray-700 pt-2'></h3></div>
  
                  <div className='card-body pt-0'>
                    <div className='mb-0'>
                      <div className='row gx-10 mb-5'>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Partner Name</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={partnerDetails?.partnerName}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Provider Name</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={partnerDetails?.providerName}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Type</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={partnerDetails?.type}
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Create Time</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                                   partnerDetails?.createTime?.slice(0, 10).replace("T", " ")
                              }
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified Time</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                                   partnerDetails?.lastModifiedTime?.slice(0, 10).replace("T", " ")
                              }
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified By</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                                   partnerDetails?.lastModifiedBy
                              }
                            />
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mb-5 mt-5 '>
                        <h2 className='mb-0 fs-5 fw-bold'>Key Values</h2>
                        <hr className='flex-grow-1 ms-3' />
                      </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Auth Url</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={partnerDetails?.authUrl}
                            />
                          </div>
                        </div>
                         <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Redemption Url</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                                partnerDetails?.redemptionUrl
                              }
                            />
                          </div>
                        </div> 
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Channels Url</label>
                           <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                               partnerDetails?.channelsUrl
                              }
                            />
                          </div> 
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Offer Url</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                              partnerDetails?.offerUrl
                              }
                            />
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <label className='form-label fs-6 text-gray-700 mb-3'>Offer Member Url</label>
                          <div className='mb-5'>
                            <input
                              disabled
                              className='form-control form-control-solid'
                              value={
                                   partnerDetails?.offerMemberUrl
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tab-pane fade' id='partners_stores_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
              <div className='d-flex flex-stack row'>
                  <div className='card-toolbar'>
                    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
                    <div className=' w-250px me-3'>
                        <form data-kt-search-element="form" className="d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative">
                        <span className="svg-icon svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                              <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"></path>
                            </svg>
                          </span>

                          <input
                            className="form-control form-control-solid w-250px ps-13 fs-7 h-40px"
                            placeholder="Quick Search"
                            data-kt-customer-table-filter="search"
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            onBlur={handleInputBlur}
                          />
                        
                          <span className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0"></span>
                          </span>
                        </form>
                      </div>
                        <div className='d-flex align-items-end ' data-kt-user-table-toolbar='base'>
                          <div className=' w-150px me-3'>
                            <select
                              className='form-select clear form-select-solid'
                              onChange={async (event) => {
                                setCurrentStorePage(1);
                                const selectedStatus = event.target.value
                                setStatus(selectedStatus)
                                await fetchStoredList(partnerDetails?.partnerName, 1, selectedRowsPerPage, selectedStatus, filterbyid);
                              }}
                            >
                    <option value=''>-Select Status-</option>
                    <option value='ACT'>Active</option>
                    <option value='INA'>Inactive</option>
                  </select> 
                            </div>

                            <div className='d-flex'>
                              <button
                                type='button'
                                className='btn btn-sm btn-primary me-3 mb-1 p-3'
                              
                              >
                                Refresh
                              </button>
                            </div>
                          </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='client_table'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                        <th className='min-w-125px'>Id</th>
                        <th className='min-w-125px'>Partner Name</th>
                        <th className='min-w-125px'> Brand Name</th>
                        <th className='min-w-125px'>Status</th>
                        <th className='text-end min-w-70px'>Actions</th>
                        {loading && (
                          <SpinnerDotted
                            size={80}
                            thickness={120}
                            className='spinner'
                            speed={100}
                            color='#c8c6c2'
                          />
                        )}
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {currentData.map((stores) => {
                        
                        return (
                          <tr key={stores.id}>
                       
                            <td className='fs-6 text-gray-700 text-hover-primary' title={stores?.id?.toString()}>
                              {stores?.id?.toString()?.length &&
                            (stores.id.toString().length >= 7
                              ? `${stores.id
                                .toString()
                                .slice(0, 4)}...${stores.id.toString().slice(-2)}`
                              : stores.id.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                             {stores.partnerName}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                           {stores.brandName}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {(stores.status === 'INPROGRESS' ||
                            stores.status === 'INIT' ||
                            stores.status === 'IN_PROGRESS') && (
                              <div className='badge badge-light-primary fw-bolder'>IN PROGRESS</div>
                            )}
                          {stores.status === 'PAUSE' && (
                            <div
                              className='badge badge-light-primary fw-bolder'
                              style={{ color: '#f15738', backgroundColor: '#FBF2EF' }}
                            >
                              PAUSED
                            </div>
                          )}
                          {stores.status === 'PROCESSING' && (
                            <div className='badge badge-light-primary fw-bolder'>PROCESSING</div>
                          )}
                          {stores.status === 'COMPLETE' && (
                            <div className='badge badge-light-success fw-bolder'>COMPLETED</div>
                          )}
                          {stores.status === 'STOP' && (
                            <div className='badge badge-light-danger fw-bolder'>STOP</div>
                          )}
                          {stores.status === 'ERROR' && (
                            <div className='badge badge-light-danger fw-bolder'>ERROR</div>
                          )}
                             {stores.status === 'INA' && (
                            <div className='badge badge-light-danger fw-bolder'>Inactive</div>
                          )}
                             {stores.status === 'ACT' && (
                            <div className='badge badge-light-success fw-bolder'>Active</div>
                          )}
                            </td>
                          
                         
                            <td className='text-end'>
                              <div className='menu-item'>
                                <Link to={`/stores/${stores.id}/details`}>
                                  <button
                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                    data-kt-menu-trigger='click'
                                    role='button'
                                    data-kt-menu-placement='bottom-end'
                                  >
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 -4 24 24'
                                        id='meteor-icon-kit__solid-eye'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fillRule='evenodd'
                                          clipRule='evenodd'
                                          d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                          fill='#758CA3'
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                  </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                    <div className='d-flex justify-content-between'>
                      <div className='dataTables_length d-flex align-items-center' id='kt_ecommerce_products_table_length'>

                        <label>
                          <select
                            name='kt_job_batches_table_length'
                            aria-controls='kt_ecommerce_products_table'
                            className='form-select form-select-sm form-select-solid'
                            value={selectedRowsPerPage}
                            onChange={(e) => {
                              const newRowsPerPage = Number(e.target.value);
                              setSelectedRowsPerPage(newRowsPerPage);
                              setCurrentStorePage(1); // Reset batch page to 1
                              // Reset order page to 1
                            }}
                          >
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='20'>20</option>
                            <option value='30'>30</option>
                            <option value='40'>40</option>
                            <option value='50'>50</option>
                            <option value='60'>60</option>
                            <option value='70'>70</option>
                            <option value='80'>80</option>
                            <option value='90'>90</option>
                            <option value='100'>100</option>
                          </select>
                        </label>
                        <div className="mr-2 fw-bolder fs-7 text-gray-700">Total number of rows: {storesCount}</div>
                      </div>
                      <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageClick}
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        activeClassName={'active'}
                        containerClassName={'pagination justify-content-end'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        disabledClassName={'disabled'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        initialPage={0}
                      />
                    </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </BlockUi>
    )
}

export {PartnerDetailsPage}