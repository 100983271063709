import * as Yup from 'yup'
import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useState } from 'react';
import GetCountriesModel from '../../MGetCountries/VGetCountries/GetCountriesModel';
import GetCountriesViewModel from '../../MGetCountries/VGetCountries/GetCountriesViewModel';
import UpdateUserCommand from './UpdateUserCommand';
import swal from 'sweetalert';
import { SpinnerDotted } from 'spinners-react';
import UserDetailsViewModel from '../VUsersDetails/UserDetailsViewModel';
import { useParams } from 'react-router-dom';
import UserDetailsModel from '../VUsersDetails/UserDetailsModel';
import UpdateUserModel, { updateUserDetails } from './UpadteUserModel';
import Select from 'react-select';
import { Auth } from 'aws-amplify';
import AdminResetUserPasswordCommand from './AdminResetUserPasswordCommand';
import { ThemeSome } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider';
import BlockUi from '../../../app/BlockUI/BlockUi';



const UpdateUserSchema = Yup.object().shape({
    FirstName: Yup.string()
        .min(1, 'First Name should be at least 2 characters')
        .matches(/^[A-Za-z\s]+$/, 'First Name must only contain alphabetic characters')
        .required('First Name must not be empty.'),
    LastName: Yup.string()
        .min(2, ' LastName should be at least 2 characters')
        .matches(/^[A-Za-z\s]+$/, 'Last Name must only contain alphabetic characters')
        .required('Last Name must not be empty.'),
    PreferredUsername: Yup.string()
        .min(1, 'Display Name should be at least 2 characters')
        .required('Display Name must not be empty.'),
    UserName: Yup.string()
        .min(2, ' User Name should be at least 2 characters')
        .required('User Name must not be empty.'),
    Email: Yup.string()
        .required('Email must not be empty.')
        .email('Invalid email address'),
    MobileCountryCode: Yup.string()
        .required('Country Code must not be empty.'),
});
const UpdateUserPage: React.FC = () => {
    const [isChecked, setIsChecked] = useState(false);
    const [countries, setCountries] = useState<GetCountriesModel[]>([]);
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const { Id, cognitoid } = useParams<string>();
    const command = new UpdateUserCommand();
    const adminResetUserPassword = new AdminResetUserPasswordCommand();
    const countriesViewModel = new GetCountriesViewModel();
    const userdetailsviewmodel = new UserDetailsViewModel();
    const [initialValues, setInitialValues] = useState({});

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (values: UpdateUserModel, { setSubmitting }: FormikHelpers<UpdateUserModel>) => {
        setSubmitting(false);
        setLoading(true);
        const response = await command.UpdateUser(values, Id, cognitoid);
        setLoading(false);
        console.log(response);
        if (command.statusCode == 200) {
            swal("Update User", "User Updated Successfully", "success");
            setIsChecked(false);
            const inputFields = document.getElementsByClassName('clear');
            console.log(inputFields);
            for (let i = 0; i < inputFields.length; i++) {
                const inputField = inputFields[i] as HTMLInputElement;
                inputField.value = '';
            }
        }
        if (command.statusCode == 400) {
            swal("Create User", command.responseErrorMessage, "error");
        }
    };

    useEffect(() => {
        const getCountries = async () => {
            await countriesViewModel.loadCountries();
            setCountries(countriesViewModel.countries);
        }
        setLoading(true)
        getCountries();
        setLoading(false)
        console.log(updateUserDetails);
    }, [ThemeSome])

    const [isSubmitting, setIsSubmitting] = useState(false);

    return (
        <BlockUi blocking={isSubmitting || loading} message="">
        <Formik initialValues={updateUserDetails} onSubmit={handleSubmit} validationSchema={UpdateUserSchema}>
            {({ isSubmitting, handleChange, handleBlur }) => (
                <div className="d-flex flex-column flex-lg-row">
                    {
                        <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
                            <div className="card">
                                <div className="card-body p-12">
                                    <Form >
                                        <div className="mb-0">
                                            <div className="row gx-10 mb-5">
                                                <div className="col-lg-12 d-flex align-items-center mb-10">
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> First Name</label>
                                                    <div className="mb-5">
                                                        <Field placeholder="" type='FirstName' id='FirstName' name='FirstName' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter User First Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'> <ErrorMessage name="FirstName" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Last Name</label>
                                                    <div className="mb-5">
                                                        <Field placeholder="" type='LastName' id='LastName' name='LastName' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid  fw-bold" />
                                                        <div className="text-gray-600">Please enter User Last Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="LastName" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Display Name</label>
                                                    <div className="mb-5">
                                                        <Field placeholder="" type='PreferredUsername' id='PreferredUsername' name='PreferredUsername' onChange={handleChange} onBlur={handleBlur} className="form-control clear form-control-solid fw-bold" />
                                                        <div className="text-gray-600">Please enter User Display Name</div>
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'><ErrorMessage name="PreferredUsername" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3">Username</label>
                                                    { ThemeSome === 'light' && <div className="mb-5">
                                                        <Field disabled placeholder="" type='UserName' id='UserName' name='UserName' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(220, 220, 220)' }} className="form-control clear form-control-solid fw-bold" />
                                                    </div>}
                                                    { ThemeSome === 'dark' && <div className="mb-5">
                                                    <Field disabled placeholder="" type='UserName' id='UserName' name='UserName' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(100, 100, 100)' }} className="form-control clear form-control-solid fw-bold" />

                                                    </div>}
                                                </div>
                                                {loading && <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color="#c8c6c2" />}
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Email</label>
                                                    { ThemeSome === 'light' && <div className="mb-5">
                                                        <Field disabled placeholder="" type='Email' id='Email' name='Email' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(220, 220, 220)' }} className="form-control clear form-control-solid fw-bold" />
                                                    </div>}
                                                    { ThemeSome === 'dark' && <div className="mb-5">
                                                    <Field disabled placeholder="" type='Email' id='Email' name='Email' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(100, 100, 100)' }} className="form-control clear form-control-solid fw-bold" />
                                                    </div>
                                                    }
                                                </div>
                                                <div className="col-lg-6">
                                                    <label className="required form-label fs-6 text-gray-600 fw-bold mb-3"> Mobile</label>
                                                    { ThemeSome === 'light' &&  <div className="mb-5">
                                                        <Field  disabled placeholder="" type='Mobile' id='Mobile' name='Mobile' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(220, 220, 220)' }} className="form-control clear form-control-solid fw-bold" />
                                                       
                                                    </div>}
                                                    {ThemeSome === 'dark' && <div className="mb-5">
                                                    <Field  disabled placeholder="" type='Mobile' id='Mobile' name='Mobile' onChange={handleChange} onBlur={handleBlur} style={{ backgroundColor: 'rgb(100, 100, 100)' }} className="form-control clear form-control-solid fw-bold" />
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-0">
                                            <div className="row gx-10 mb-5">
                                                <div className="d-flex flex-stack">
                                                    <div></div>
                                                    <div className="d-flex align-items-center me-2">
                                                        <button type="submit" role="button" disabled={isSubmitting} className="btn btn-sm btn-primary me-3">
                                                            Update
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            )}
        </Formik>
        </BlockUi>
    )


}
export { UpdateUserPage }