import { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import ReactPaginate from 'react-paginate'
import { Link, useParams } from 'react-router-dom'
import { SpinnerDotted } from 'spinners-react'
import swal from 'sweetalert'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import BlockUi from '../../../app/BlockUI/BlockUi'
import JobBatchesListModel from '../VJobBatches/JobBacthesListModel'
import JobBatchesListviewModel from '../VJobBatches/JobBathesListViewModel'
import JobFilesListModel from '../VJobFiles/JobFilesListModel'
import JobFilesListViewModel from '../VJobFiles/JobFilesListViewModel'
import JobOrdersCommand from '../VJobOrders/JobOrdersCommand'
import JobOrdersModel from '../VJobOrders/JobOrdersModel'
import ContinueJobStatusCommand from '../VJobStatus/ContinueJobStatus/ContinueJobStatusCommand'
import PauseJobStatusCommand from '../VJobStatus/PauseJobStatus/PauseJobStatusCommand'
import StopJobStatusCommand from '../VJobStatus/StopJobStatus/StopJobStatusCommand'
import ReprocessJobBatchesCommand from '../VReprocessJob/ReRunJobBatchCommand'
import ReprocessJobCommand from '../VReprocessJob/ReprocessJobCommand'
import RerunJobErrorBatchesCommand from '../VReprocessJob/RerunJobErrorBatchesCommand'
import JobDetailsViewModel from './JobDeatilsViewModel'
import JobDetailsModel from './JobDetailsModel'
import JobTransactionsModel from '../VJobTransactions/JobTransactionsModel'
import JobTransactionsCommand from '../VJobTransactions/JobTransactionsCommand'


interface TdCheckboxState {
  [key: string]: boolean
}

let JobDetailsPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { jobId } = useParams<{ jobId: string }>()
  const [jobDetails, setjobDetails] = useState<JobDetailsModel | null>(null)
  const pauseCommand = new PauseJobStatusCommand()
  const continueCommand = new ContinueJobStatusCommand()
  const stopCommand = new StopJobStatusCommand()
  const jobBatchviewModel = new JobBatchesListviewModel()
  const jobFilesViewModel = new JobFilesListViewModel()
  const jobOrdersCommand = new JobOrdersCommand()
  const jobTransactionsCommand = new JobTransactionsCommand()
  const [currentBatchPage, setCurrentBatchPage] = useState(1)
  const [currentFilePage, setCurrentFilePage] = useState(1)
  const [currentOrderPage, setCurrentOrderPage] = useState(1)
  const [currenttransactionsPage, setCurrenttransactionsPage] = useState(1)
  const [jobBatchesData, setJobBatchesData] = useState<JobBatchesListModel[]>([])
  const [jobFilesData, setJobFilesData] = useState<JobFilesListModel[]>([])
  const [jobOrdersData, setJobOrdersData] = useState<JobOrdersModel[]>([])
  const [jobTransactionsData, setJobTransactionsData] = useState<JobTransactionsModel[]>([])
  const [pageCount, setPageCount] = useState(0)
  const [filePageCount, setFilePageCount] = useState(0)
  const [orderPageCount, setOrderPageCount] = useState(0)
  const [transactionsPageCount, settransactionsPageCount] = useState(0)
  const [load, reload] = useState(0)
  const [status, setStatus] = useState<string>('')
  const [filterid, setFilterid] = useState<string>('');
  const [orderStatus, setOrderStatus] = useState<string>('')
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [totalbatchCount, setTotalbatchCount] = useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);
  const [selectedFilesRowsPerPage, setSelectedFilesRowsPerPage] = useState(5);
  const [selectedTransactionsRowsPerPage, setSelectedTransactionsRowsPerPage] = useState(5);
  const [selectedorderRowsPerPage, setSelectedorderRowsPerPage] = useState(5);
  const [recievedCount, setRecievedCount] = useState(0);

  // const { filterid } = useParams<{ filterid: string }>()
  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentBatchPage(selected + 1)
    setbatchLoaded(false);
  }

  const handlePage = ({ selected }: { selected: number }) => {
    setCurrentFilePage(selected + 1)
    setFilesTabLoaded(false);
  }

  const handleOrdersPage = ({ selected }: { selected: number }) => {
    setCurrentOrderPage(selected + 1)
    setOrdertabLoaded(false);
  }

  const [batchescount, setJobCount] = useState(0);
  const [filesCount, setFilesCount] = useState(0);
  const [orderscount, setOrdersCount] = useState(0);
  const [transactionscount, setTransactionsCount] = useState(0);


  const fetchJobBatches = async (jobId: string | undefined, page: any, status: string, rowsPerPage: number) => {
    await jobBatchviewModel.loadJobBatches(page, rowsPerPage, jobId, status);
    setJobBatchesData(jobBatchviewModel.jobbatches);
    setPageCount(jobBatchviewModel.totalPages);
    setJobCount(jobBatchviewModel.totalCount);
    const totalCount = jobBatchviewModel.totalPages * rowsPerPage;
    setTotalbatchCount(totalCount);
    setbatchLoaded(true);
  }


  const fetchJobFiles = async (jobId: string | undefined, page: any, rowsPerPage: number) => {
    await jobFilesViewModel.loadJobFiles(page, rowsPerPage, jobId)
    setJobFilesData(jobFilesViewModel.jobfiles)
    setFilePageCount(jobFilesViewModel.totalPages)
    setFilesCount(jobFilesViewModel.totalCount)
    setFilesTabLoaded(true);
  }

  const fetchJobOrders = async (jobId: string | undefined, page: any, status: any, rowsPerPage: number, filterid: any) => {
    await jobOrdersCommand.loadJobOrders(page, rowsPerPage, jobId, status, filterid)
    setJobOrdersData(jobOrdersCommand.jobOrders)
    setOrderPageCount(jobOrdersCommand.totalPages)
    setOrdersCount(jobOrdersCommand.totalCount)
    setOrdertabLoaded(true);
  }

  const fetchJobtransactions = async (jobId: string | undefined, page: any, status: any, rowsPerPage: number,  filterid: any) => {
    await jobTransactionsCommand.loadJobTransactions(page, rowsPerPage, jobId, status, filterid)
    setJobTransactionsData(jobTransactionsCommand.jobTransactions)
    settransactionsPageCount(jobTransactionsCommand.totalPages)
    setTransactionsCount(jobTransactionsCommand.totalCount)
    setTransactionsTabLoaded(true);
  }

  const getJobDetails = async () => {
    const viewModel = new JobDetailsViewModel()
    await viewModel.loadJobDetail(jobId).then(() => {
      setjobDetails(viewModel.jobdetails)
    })
    let recievedCount = viewModel.jobdetails.processedCount + viewModel.jobdetails.narCount
    setRecievedCount(recievedCount);
    console.log(recievedCount);
  }

  const [activeTab, setActiveTab] = useState('#job_overview_tab');
  const [batchLoaded, setbatchLoaded] = useState(false);
  const [ordertabloded, setOrdertabLoaded] = useState(false);
  const [filesTabLoaded, setFilesTabLoaded] = useState(false);
  const [transactionsTabLoaded, setTransactionsTabLoaded] = useState(false);



  useEffect(() => {
    const getAllDetails = async () => {
      setLoading(true);

      if (activeTab === '#job_overview_tab')
        await getJobDetails();

      if (activeTab === '#job_batch_tab' && !batchLoaded) {
        await fetchJobBatches(jobId, currentBatchPage, status, selectedRowsPerPage);
      }
      if (activeTab === '#orders_tab_content' && !ordertabloded) {
        await fetchJobOrders(jobId, currentOrderPage, orderStatus, selectedorderRowsPerPage, filterid);
      }

      if (activeTab === "#files_tab_content" && !filesTabLoaded)
        await fetchJobFiles(jobId, currentFilePage, selectedFilesRowsPerPage);

      if (activeTab === "#transactions_tab_content" && !transactionsTabLoaded)
       await fetchJobtransactions(jobId, currenttransactionsPage, orderStatus, selectedTransactionsRowsPerPage, filterid);

      setLoading(false);
    };
    getAllDetails();
  }, [currentBatchPage, currentFilePage, currentOrderPage, load, selectedRowsPerPage, selectedFilesRowsPerPage, selectedorderRowsPerPage, activeTab]);


  const pausejobStatusHandle = async (status: any) => {
    if (status === 'PAUSE') {
      swal('Update Status', 'Job already paused', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to pause the job.',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await pauseCommand.pauseJob(
            jobDetails?.jobId,
            jobDetails?.partnerName,
            jobDetails?.providerName
          )

          setLoading(false)
          if (pauseCommand.statusCode == 200) {
            swal('Job Paused Successfully.', {
              icon: 'success',
            })
            setLoading(true)
            reload(1)
            setbatchLoaded(false)
            getJobDetails();
            setLoading(false)
          } else {
            swal('update status', pauseCommand?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const stopJobStatushandle = async (status: any) => {
    if (status === 'STOP') {
      swal('Update Status', 'Job was already in stop state', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to stop the job.',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await stopCommand.stopJob(
            jobDetails?.jobId,
            jobDetails?.partnerName,
            jobDetails?.providerName
          )

          setLoading(false)
          if (stopCommand.statusCode == 200) {
            swal('Job Stopped Successfully.', {
              icon: 'success',
            })
            reload(2)
            setLoading(true)
            setbatchLoaded(false)
            setLoading(false)
          } else {
            swal('update status', stopCommand?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const getDate = (dateStr: string) => {
    const date = new Date(dateStr)
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    return formattedDate
  }

  function formatDateTimeString(dateTimeStr: any) {
    const dateTime = new Date(dateTimeStr)
    const formattedDateTime = dateTime.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    return formattedDateTime
  }

  const continueJobStatushandle = async (status: any) => {
    if (status === 'PROCESSING') {
      swal('Update Status', 'Job was already in processing state', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to resume the job.',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await continueCommand.continueJob(
            jobDetails?.jobId,
            jobDetails?.partnerName,
            jobDetails?.providerName
          )

          setLoading(false)
          if (continueCommand.statusCode == 200) {
            swal('Job Resumed Successfully.', {
              icon: 'success',
            })
            reload(3)
            setLoading(true)
            setbatchLoaded(false)
            setLoading(false)
          } else {
            swal('update status', continueCommand?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const handleReRun = async (ReRunErrorBatches: boolean) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to rerun the batches?',
      icon: 'warning',
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(async (willReprocess) => {
      if (willReprocess) {
        setLoading(true)
        const command = new ReprocessJobBatchesCommand()
        await command.ReRunJobBatches(jobDetails?.jobId, ReRunErrorBatches, selectedJobBatchIds)
        setLoading(false)
        if (command.responseErrorMessage === '') {
          reload(4)
          setSelectedJobBatchIds([])
          swal('Batches ReProcessed Successfully', {
            icon: 'success',
          })
        } else {
          swal('Reprocess Job', command.responseErrorMessage, 'error')
        }
      }
    })
  }

  const handleReRunErrors = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to rerun the Error batches?',
      icon: 'warning',
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(async (willReprocess) => {
      if (willReprocess) {
        setLoading(true)
        const command = new RerunJobErrorBatchesCommand()
        await command.RerunJobErrorBatches(jobDetails?.jobId)
        setLoading(false)
        if (command.responseErrorMessage === '') {
          reload(5)
          setSelectedJobBatchIds([])
          swal('Batches ReProcessed Successfully', {
            icon: 'success',
          })
        } else {
          swal('Reprocess Job', command.responseErrorMessage, 'error')
        }
      }
    })
  }

  const handleReprocess = async (id: string | undefined) => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to reprocess job?',
      icon: 'warning',
      buttons: ['No', 'Yes'],
      dangerMode: true,
    }).then(async (willReprocess) => {
      if (willReprocess) {
        setLoading(true)
        const command = new ReprocessJobCommand()
        await command.ReprocessJob(id)
        setLoading(false)
        if (command.responseErrorMessage === '') {
          reload(1)
          swal('Reprocess Job', {
            icon: 'success',
          })
        } else {
          swal('Reprocess Job', command.responseErrorMessage, 'error')
        }
      }
    })
  }

  const [selectedJobBatchIds, setSelectedJobBatchIds] = useState<string[]>([])
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, jobId: string) => {
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedJobBatchIds((prevIds) => [...prevIds, jobId])
    } else {
      setSelectedJobBatchIds((prevIds) => prevIds.filter((id) => id !== jobId))
    }
  }
  const handleButtonClick = () => {
    console.log('Selected Job Batch IDs:', selectedJobBatchIds)
  }



  const getProcessDuration = (startTimeStr: string | number | Date, endTimeStr: string | number | Date) => {
    const startTime = new Date(startTimeStr);
    const endTime = new Date(endTimeStr);
    const duration = Math.abs(endTime.getTime() - startTime.getTime());
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000)

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleSearch = async () => {
    setLoading(true);
    setCurrentBatchPage(1);
    await fetchJobOrders(jobId, currentBatchPage, orderStatus, selectedorderRowsPerPage, filterid);
    setLoading(false);
  };

  const handleInputChange = (event: any) => {
    setFilterid(event.target.value.trim()); // Remove leading/trailing whitespace
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the form submission
      handleSearch();
    }
  };
  const handleInputBlur = () => {
    handleSearch();
  };
  return (
    <BlockUi blocking={loading} message=''>
      {loading && (
        <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color='#c8c6c2' />
      )}
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className=' container-xxl ' id='kt_content_container'>
          <div className='card mb-6 mb-xl-9'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
                <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                  <img
                    className='mw-50px mw-lg-75px'
                    src={toAbsoluteUrl('/media/avatars/MicrosoftTeams-image (3).png')}
                    alt='image'
                  />
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-1'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                          Job -{jobDetails?.jobId}
                        </a>
                        <div className="row align-items-center">
                          <div className="col-auto">
                            {(jobDetails?.status === 'INPROGRESS' ||
                              jobDetails?.status === 'INIT' ||
                              jobDetails?.status === 'IN_PROGRESS') && (
                                <div className='badge badge-primary fw-bolder mr-3'>
                                  IN PROGRESS
                                </div>
                              )}
                            {jobDetails?.status === 'PAUSE' && (
                              <div
                                className='badge badge-primary fw-bolder mr-3'
                                style={{
                                  color: 'var(--bs-primary-inverse)',
                                  backgroundColor: '#f73e00',
                                }}
                              >
                                PAUSED
                              </div>
                            )}
                            {jobDetails?.status === 'PROCESSING' && (
                              <div className='badge badge-primary fw-bolder mr-3'>
                                PROCESSING
                              </div>
                            )}
                            {jobDetails?.status === 'COMPLETE' && (
                              <div className='badge badge-success fw-bolder mr-3'>
                                COMPLETED
                              </div>
                            )}
                            {jobDetails?.status === 'STOP' && (
                              <div className='badge badge-danger fw-bolder mr-3'>
                                STOP
                              </div>
                            )}
                            {jobDetails?.status === 'ERROR' && (
                              <div className='badge badge-danger fw-bolder mr-3'>
                                ERROR
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                      {/* <!--end::Description--> */}
                    </div>
                    {/* <!--end::Details--> */}
                    {/* <!--begin::Actions--> */}
                    {jobDetails?.status !== 'COMPLETE' && (
                    <div className='d-flex mb-4'>
                      <button
                        type='button'
                        className='btn btn-sm btn-primary me-3'
                        onClick={() => handleReprocess(jobDetails?.jobId)}
                      >
                        Reprocess Job
                      </button>
                      {jobDetails?.totalCount !== recievedCount && (
                        <div className='me-0'>
                          <button
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            <i className='bi bi-three-dots fs-3'></i>{' '}
                          </button>
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                            data-kt-menu='true'
                          >
                            {jobDetails?.status === 'PAUSE' && (
                              <>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    onClick={continueJobStatushandle}
                                    className='menu-link px-3'
                                  >
                                    Resume Job
                                  </a>
                                </div>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    onClick={stopJobStatushandle}
                                    className='menu-link px-3'
                                  >
                                    Stop Job
                                  </a>
                                </div>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    className='menu-link px-3'
                                    onClick={async () => await handleReRun(true)}
                                  >
                                    Re-Run Error Batches
                                  </a>
                                </div>
                              </>
                            )}
                            {jobDetails?.status === 'PROCESSING' && (
                              <>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    onClick={pausejobStatusHandle}
                                    className='menu-link px-3'
                                  >
                                    Pause Job
                                  </a>
                                </div>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    onClick={stopJobStatushandle}
                                    className='menu-link px-3'
                                  >
                                    Stop Job
                                  </a>
                                </div>
                                <div className='menu-item px-3'>
                                  <a
                                    role='button'
                                    className='menu-link px-3'
                                    onClick={async () => await handleReRun(true)}
                                  >
                                    Re-Run Error Batches
                                  </a>
                                </div>
                              </>
                            )}
                            {(jobDetails?.status === 'INPROGRESS' ||
                              jobDetails?.status === 'INIT' ||
                              jobDetails?.status === 'IN_PROGRESS') && (
                                <>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={pausejobStatusHandle}
                                      className='menu-link px-3'
                                    >
                                      Pause Job
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={stopJobStatushandle}
                                      className='menu-link px-3'
                                    >
                                      Stop Job
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      className='menu-link px-3'
                                      onClick={async () => await handleReRun(true)}
                                    >
                                      Re-Run Error Batches
                                    </a>
                                  </div>
                                </>
                              )}
                            {((jobDetails?.status === 'ERROR' || jobDetails?.status === 'COMPLETE' || jobDetails?.status == 'STOP') &&
                              (jobDetails?.totalCount != recievedCount)) && (
                                <>
                                {jobDetails.status === 'ERROR' && (
                                    <div className='menu-item px-3'>
                                      <a
                                        role='button'
                                        onClick={continueJobStatushandle}
                                        className='menu-link px-3'
                                      >
                                        Resume Job
                                      </a>
                                    </div>)}
                                  {jobDetails.status != 'STOP' && (
                                    <>
                                      <div className='menu-item px-3'>
                                        <a
                                          role='button'
                                          onClick={pausejobStatusHandle}
                                          className='menu-link px-3'
                                        >
                                          Pause Job
                                        </a>
                                      </div>

                                      <div className='menu-item px-3'>
                                        <a
                                          role='button'
                                          onClick={stopJobStatushandle}
                                          className='menu-link px-3'
                                        >
                                          Stop Job
                                        </a>
                                      </div>
                                    </>)}
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      className='menu-link px-3'
                                      onClick={async () => await handleReRun(true)}
                                    >
                                      Re-Run Error Batches
                                    </a>
                                  </div>
                                  
                                </>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                    )}
                    {/* <!--begin::Menu--> */}

                    {/* <!--end::Actions--> */}
                  </div>
                  {/* <!--end::Head--> */}

                  {/* <!--begin::Info--> */}
                  <div className='d-flex flex-wrap justify-content-start'>
                    {/* <!--begin::Stats--> */}
                    <div className='d-flex flex-wrap'>
                      {/* <!--begin::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <div className='d-flex align-items-center'>
                          <div className='fs-4 fw-bold text-hover-primary'>
                            {jobDetails?.createTime?.slice(0, 10).replace("T", " ")}
                          </div>
                        </div>

                        {/* <!--end::Number--> */}

                        {/* <!--begin::Label--> */}
                        <div className='fw-semibold fs-6 text-gray-400'>Job Date</div>
                        {/* <!--end::Label--> */}
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <span className="fs-1 fw-bold text-gray-800 lh-1">
                          <CountUp start={0} end={jobDetails?.totalCount ? jobDetails?.totalCount : 0} duration={2.5} separator="," className="counted" />
                          <i className="ki-duotone ki-arrow-up fs-1 text-success">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Total Count</span>
                      </div>
                      {/* <!--end::Stat--> */}

                      {/* <!--begin::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}

                        <span className="fs-1 fw-bold text-gray-800 lh-1">
                          <CountUp start={0} end={jobDetails?.processedCount ? jobDetails?.processedCount : 0} duration={2.5} separator="," className="counted" />
                          <i className="ki-duotone ki-arrow-up fs-1 text-success">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Processed</span>
                      </div>
                      {/* <!--end::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <span className="fs-1 fw-bold text-gray-800 lh-1">
                          <CountUp start={0} end={jobDetails?.narCount ? jobDetails?.narCount : 0} duration={2.5} separator="," className="counted" />
                          <i className="ki-duotone ki-arrow-up fs-1 text-success">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">NAR</span>
                      </div>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <span className="fs-1 fw-bold text-gray-800 lh-1">
                          <CountUp start={0} end={jobDetails?.errorCount ? jobDetails?.errorCount : 0} duration={2.5} separator="," className="counted" />
                          <i className="ki-duotone ki-arrow-up fs-1 text-success">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                        </span>
                        <span className="fs-6 fw-semibold text-muted d-block lh-1 pt-2">Error</span>
                      </div>
                    </div>
                    {/* <!--end::Stats--> */}

                    {/* <!--begin::Users--> */}
                    {/* <!--end::Users--> */}
                  </div>
                  {/* <!--end::Info--> */}
                </div>
                {/* <!--end::Wrapper--> */}
              </div>
              {/* <!--end::Details--> */}

              <div className='separator'></div>

              {/* <!--begin::Nav--> */}
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                <li className='nav-item py-5 me-6'>
                  <a
                    className={`nav-link py-5 me-6 ${activeTab === '#job_overview_tab' ? 'text-active-primary active' : 'text-active-primary'}`}
                    data-bs-toggle='tab'
                    href='#job_overview_tab'
                    onClick={() => setActiveTab('#job_overview_tab')}
                  >
                    Overview
                  </a>
                </li>
                <li className='nav-item py-5 me-6'>
                  <a
                    className={`nav-link py-5 text-active-primary me-6 ${activeTab === '#job_batch_tab' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#job_batch_tab'
                    onClick={() => setActiveTab('#job_batch_tab')}
                  >
                    Batches
                  </a>
                </li>
                <li className='nav-item py-5 me-6'>
                  <a
                    className={`nav-link py-5 text-active-primary me-6 ${activeTab === '#files_tab_content' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#files_tab_content'
                    onClick={() => setActiveTab('#files_tab_content')}
                  >
                    Files
                  </a>
                </li>
                <li className='nav-item py-5 me-6'>
                  <a
                    className={`nav-link py-5 text-active-primary me-6 ${activeTab === '#orders_tab_content' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#orders_tab_content'
                    onClick={() => setActiveTab('#orders_tab_content')}
                  >
                    Orders
                  </a>
                </li>
                <li className='nav-item py-5 me-6'>
                  <a
                    className={`nav-link py-5 text-active-primary me-6 ${activeTab === '#transactions_tab_content' ? 'active' : ''}`}
                    data-bs-toggle='tab'
                    href='#transactions_tab_content'
                    onClick={() => setActiveTab('#transactions_tab_content')}
                  >
                    Transactions
                  </a>
                </li>
              </ul>
              {/* <!--end::Nav--> */}
            </div>
          </div>
          {/* <!--end::Navbar--> */}
          {/* <!--begin::Row--> */}
          <div className='tab-content' id='myTabContent'>
            {/*begin:::Tab pane*/}
            <div className='tab-pane fade show active' id='job_overview_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Table*/}
                <div className='card-body pt-0'>
                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Job Date</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.jobDate?.slice(0, 10)
                                ? getDate(jobDetails.jobDate?.slice(0, 10))
                                : jobDetails?.jobDate?.slice(0, 10)
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Status</label>
                        <div className='mb-5'>

                          <input
                            disabled
                            value={jobDetails?.status === 'INIT' ? 'In Progress' : jobDetails?.status}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.providerName
                                ? jobDetails?.providerName.charAt(0).toUpperCase() +
                                jobDetails?.providerName.slice(1)
                                : jobDetails?.providerName
                            }

                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.partnerName
                                ? jobDetails?.partnerName.charAt(0).toUpperCase() +
                                jobDetails?.partnerName.slice(1).toLowerCase()
                                : jobDetails?.partnerName
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Entity Type</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.entityType
                                ? jobDetails?.entityType.charAt(0).toUpperCase() +
                                jobDetails?.entityType.slice(1).toLowerCase()
                                : jobDetails?.entityType
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>

                      <div className='col-lg-3'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Job Type</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.jobType
                                ? jobDetails?.jobType.charAt(0).toUpperCase() +
                                jobDetails?.jobType.slice(1).toLowerCase()
                                : jobDetails?.jobType
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Flow Type</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.flowType
                                ? jobDetails?.flowType.charAt(0).toUpperCase() +
                                jobDetails?.flowType.slice(1).toLowerCase()
                                : jobDetails?.flowType
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          Process Start Time
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.processingStartTime?.slice(0, 16).replace('T', ' ').startsWith('9999') ||
                                jobDetails?.processingStartTime?.slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : jobDetails?.processingStartTime?.slice(0, 16).replace('T', ' ')
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Process End Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.processingEndTime?.slice(0, 16).replace('T', ' ').startsWith('9999') ||
                                jobDetails?.processingEndTime?.slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : jobDetails?.processingEndTime?.slice(0, 16).replace('T', ' ')
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Duration</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.processDuration}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          Total
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.totalCount}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          Processed
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.processedCount}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>NAR</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.narCount}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          Error {' '}
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.errorCount}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Time Frame Start </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.timeFrameStart?.slice(0, 16).replace("T", " ")
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Time Frame End</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.timeFrameEnd?.slice(0, 16).replace("T", " ")
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-2'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Slot Duration</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.slotDuration}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>


                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Create Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.createTime?.slice(0, 16).replace("T", " ")
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Created By</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              jobDetails?.createdBy
                                ? jobDetails?.createdBy.charAt(0).toUpperCase() +
                                jobDetails?.createdBy.slice(1).toLowerCase()
                                : jobDetails?.createdBy
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Batch Size</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.batchSize}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Brand Name</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.brandName}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mb-5 mt-5 '>
                        <h2 className='mb-0 fs-5 fw-bold'>Error</h2>
                        <hr className='flex-grow-1 ms-3' />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          {' '}
                          Source{' '}
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.errorSource == '' ? '-' : jobDetails?.errorSource}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Explanation  </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={jobDetails?.errorExplanation == '' ? '-' : jobDetails?.errorExplanation}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          {' '}
                          Message{' '}
                        </label>
                        <div className='mb-5'>
                          <textarea
                            disabled
                            value={
                              jobDetails?.errorMessage == ''
                                ? '-'
                                : jobDetails?.errorMessage
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='job_batch_tab' role='tabpanel'>
              <div className='card pt-4 p-4 mb-6 mb-xl-9'>
                <div className='d-flex flex-stack row'>
                  <div className='card-toolbar'>
                    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
                      <div className=' w-150px me-3'>
                        <select
                          className='form-select clear form-select-solid'
                          onChange={async (event) => {
                            setLoading(true)
                            setCurrentBatchPage(1)
                            const selectedStatus = event.target.value
                            setStatus(selectedStatus)
                            await fetchJobBatches(jobId, currentBatchPage, selectedStatus, rowsPerPage)
                            setLoading(false)
                          }}
                        >
                          <option value=''>-Select Status-</option>
                          <option value='INIT'>In Progress</option>
                          <option value='PROCESSING'>Processing</option>
                          <option value='PAUSE'>Paused</option>
                          <option value='COMPLETE'>Completed</option>
                          <option value='STOP'>Stopped</option>
                          <option value='ERROR'>Error</option>
                        </select>
                      </div>
                      <div className='d-flex'>
                        <button
                          type='button'
                          disabled={selectedJobBatchIds.length === 0}
                          className='btn btn-sm btn-primary me-3'
                          onClick={async () => await handleReRun(false)}
                        >
                          Re-Run Selected
                        </button>
                      </div>
                      <div className='d-flex'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary me-3'
                          onClick={async () => {
                            setLoading(true);
                            await fetchJobBatches(jobId, currentBatchPage, status, selectedRowsPerPage);
                            setLoading(false);
                          }}
                        >
                          Refresh
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-stack flex-wrap gap-2'>
                  <button
                    type='button'
                    className='d-none btn btn-light-primary me-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_client_export_modal'
                  >
                    <span className='svg-icon svg-icon-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='2'
                        viewBox='0 0 24 24'
                        fill='none'
                      >
                        <rect
                          opacity='0.3'
                          x='12.75'
                          y='4.25'
                          width='12'
                          height='1.5'
                          rx='1'
                          transform='rotate(90 12.75 4.25)'
                          fill='black'
                        />
                        <path
                          d='M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z'
                          fill='black'
                        />
                        <path
                          d='M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z'
                          fill='#C4C4C4'
                        />
                      </svg>
                    </span>
                  </button>
                </div>
                {/*begin::Table*/}
                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='kt_table_batches'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                        <th role='columnheader' className='w-10px pe-2'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={selectedJobBatchIds.length === jobBatchesData.length}
                              onChange={(e) => {
                                const isChecked = e.target.checked
                                const batchIds = jobBatchesData.map(
                                  (batch: any) => batch.jobBatchId
                                )
                                setSelectedJobBatchIds(isChecked ? batchIds : [])
                              }}
                            />
                          </div>
                        </th>
                        <th className='min-w-125px'>Batch Id</th>
                        <th className='min-w-125px'>Total</th>
                        <th className='min-w-125px'>Processed</th>
                        <th className='min-w-125px'>Duration</th>
                        <th className='min-w-125px'>Sequence</th>
                        <th className='min-w-125px text-start'>Status</th>
                        <th className='text-end min-w-70px'>Actions</th>
                        {loading && (
                          <SpinnerDotted
                            size={80}
                            thickness={120}
                            className='spinner'
                            speed={100}
                            color='#c8c6c2'
                          />
                        )}
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {jobBatchesData.map((batches) => {
                        return (
                          <tr key={batches.jobBatchId}>
                            <td>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  checked={selectedJobBatchIds.includes(
                                    batches.jobBatchId ? batches.jobBatchId : ''
                                  )}
                                  disabled={batches.status === 'COMPLETE'}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      batches.jobBatchId ? batches.jobBatchId : ''
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={batches?.jobBatchId?.toString()}>
                              {batches?.jobBatchId?.toString()?.length &&
                                (batches.jobBatchId.toString().length >= 7
                                  ? `${batches.jobBatchId
                                    .toString()
                                    .slice(0, 4)}...${batches.jobBatchId.toString().slice(-2)}`
                                  : batches.jobBatchId.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {batches.totalCount}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              <a className="me-2 fs-6 text-gray-700" data-bs-toggle="tooltip" data-bs-placement="top" title="Processed/NAR/Error">
                                {batches.processedCount}/{batches.narCount}/{batches.errorCount}
                              </a>
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {batches.processDuration}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {batches.batchStartNumber} - {batches.batchEndNumber}

                            </td>
                            <td className="fs-6 text-gray-700 text-hover-primary text-start">
                              <div className="d-flex align-items-center">
                                <div className="d-flex">
                                  {(batches.status === 'INPROGRESS' ||
                                    batches.status === 'INIT' ||
                                    batches.status === 'IN_PROGRESS') && (
                                      <div className="badge badge-light-primary fw-bolder">
                                        IN PROGRESS
                                      </div>
                                    )}
                                  {batches.status === 'PAUSE' && (
                                    <div
                                      className="badge badge-light-primary fw-bolder"
                                      style={{ color: '#f15738', backgroundColor: '#FBF2EF' }}
                                    >
                                      PAUSED
                                    </div>
                                  )}
                                  {batches.status === 'STOP' && (
                                    <div className="badge badge-light-danger fw-bolder">STOP</div>
                                  )}
                                  {batches.status === 'PROCESSING' && (
                                    <div className="badge badge-light-primary fw-bolder">
                                      PROCESSING
                                    </div>
                                  )}
                                  {batches.status === 'COMPLETE' && (
                                    <div className="badge badge-light-success fw-bolder">COMPLETED</div>
                                  )}
                                  {batches.status === 'ERROR' && (
                                    <div className="badge badge-light-danger fw-bolder">ERROR</div>
                                  )}
                                </div>

                              </div>
                            </td>

                            <td className='text-end'>
                              <div className='menu-item'>
                                <Link to={`/jobs/${batches.jobId}/jobbatches/${batches.jobBatchId}/details`}>
                                  <button
                                    className='btn btn-icon  btn-bg-light btn-active-color-primary btn-sm me-1'
                                    data-kt-menu-trigger='click'
                                    role='button'
                                    data-kt-menu-placement='bottom-end'
                                  >
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 -4 24 24'
                                        id='meteor-icon-kit__solid-eye'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fillRule='evenodd'
                                          clipRule='evenodd'
                                          d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                          fill='#758CA3'
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between'>
                    <div className='dataTables_length d-flex align-items-center' id='kt_ecommerce_products_table_length'>
                      <label>
                        <select
                          name='kt_job_batches_table_length'
                          aria-controls='kt_ecommerce_products_table'
                          className='form-select form-select-sm form-select-solid'
                          value={selectedRowsPerPage}
                          onChange={async (e) => {
                            const newRowsPerPage = Number(e.target.value);
                            setSelectedRowsPerPage(newRowsPerPage);
                            setCurrentBatchPage(1);
                            setLoading(true);
                            await fetchJobBatches(jobId, currentBatchPage, status, newRowsPerPage);
                            setLoading(false);
                          }}

                        >
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='20'>20</option>
                          <option value='30'>30</option>
                          <option value='40'>40</option>
                          <option value='50'>50</option>
                          <option value='60'>60</option>
                          <option value='70'>70</option>
                          <option value='80'>80</option>
                          <option value='90'>90</option>
                          <option value='100'>100</option>
                        </select>
                      </label>
                      <div className='mr-2 fw-bolder fs-7 text-gray-700' >Total number of rows: {batchescount}</div>
                    </div>

                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      activeClassName={'active'}
                      containerClassName={'pagination'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      initialPage={0}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='files_tab_content' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Table*/}
                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='client_table'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                        <th className='min-w-125px'>FileId</th>
                        <th className='min-w-125px'>Name</th>
                        <th className='min-w-125px'>Folder</th>
                        <th className='min-w-125px text-center'>Receive Time</th>
                        {loading && (
                          <SpinnerDotted
                            size={80}
                            thickness={120}
                            className='spinner'
                            speed={100}
                            color='#c8c6c2'
                          />
                        )}
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {jobFilesData.map((files) => {

                        return (
                          <tr key={files.jobFileId}>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={files?.jobFileId?.toString()}>
                              {files?.jobFileId?.toString()?.length &&
                                (files.jobFileId.toString().length >= 7
                                  ? `${files.jobFileId.toString().slice(0, 4)}...${files.jobFileId
                                    .toString()
                                    .slice(-2)}`
                                  : files.jobFileId.toString())}
                            </td>

                            <td
                              className='fs-6 text-gray-700 text-hover-primary'
                              style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {files.fileName}
                            </td>
                            <td
                              className='fs-6 text-gray-700 text-hover-primary'
                              style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {files.filePath}
                            </td>
                            <td className='fs-6 p-2 text-gray-700 text-hover-primary text-end'>
                              {
                                files?.fileReceiveTime?.slice(0, 16).replace("T", " ")
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between'>
                    <div className='dataTables_length d-flex align-items-center' id='kt_ecommerce_products_table_length'>
                      <label>
                        <select
                          name='kt_job_batches_table_length'
                          aria-controls='kt_ecommerce_products_table'
                          className='form-select form-select-sm form-select-solid'
                          value={selectedFilesRowsPerPage}
                          onChange={(e) => {
                            const newRowsPerPage = Number(e.target.value);
                            setSelectedFilesRowsPerPage(newRowsPerPage);
                            setCurrentFilePage(1); // Reset batch page to 1
                            setFilesTabLoaded(true);
                          }}

                        >
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='20'>20</option>
                          <option value='30'>30</option>
                          <option value='40'>40</option>
                          <option value='50'>50</option>
                          <option value='60'>60</option>
                          <option value='70'>70</option>
                          <option value='80'>80</option>
                          <option value='90'>90</option>
                          <option value='100'>100</option>
                        </select>
                      </label>
                      <div className='mr-2 fw-bolder fs-7 text-gray-700' >Total number of rows: {filesCount}</div>
                    </div>

                    <ReactPaginate
                      pageCount={filePageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handlePage}
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      activeClassName={'active'}
                      containerClassName={'pagination justify-content-end'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      initialPage={0}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='orders_tab_content' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                <div className='d-flex flex-stack row'>
                  <div className='card-toolbar'>
                    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
                      <div className=' w-250px me-3'>
                        <form data-kt-search-element="form" className="d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative">
                          <span className="svg-icon svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                              <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"></path>
                            </svg>
                          </span>

                          <input
                            className="form-control bg-transparent ps-13 fs-7 h-40px"
                            placeholder="Quick Search"
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            onBlur={handleInputBlur}
                          />
                          <span className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0"></span>
                          </span>
                        </form>
                      </div>
                      <div className='d-flex flex-stack flex-wrap gap-2'>
                        <div className="d-flex justify-content-between align-items-center" data-kt-user-table-toolbar="base">
                          <div className="d-flex align-items-end">
                          </div>
                          <div className='d-flex align-items-end ' data-kt-user-table-toolbar='base'>
                            <div className=' w-150px me-3'>
                              <select
                                className='form-select clear form-select-solid'
                                onChange={async (event) => {
                                  setLoading(true)
                                  setCurrentBatchPage(1)
                                  const selectedStatus = event.target.value
                                  setOrderStatus(selectedStatus)
                                  await fetchJobOrders(jobId, currentBatchPage, selectedStatus, selectedorderRowsPerPage, filterid)
                                  setLoading(false)
                                }}
                              >
                                <option value=''>-Select Status-</option>
                                <option value='1'>In Progress</option>
                                <option value='2'>Created</option>
                                <option value='3'>Accepted</option>
                                <option value='4'>Denied</option>
                                <option value='5'>Unknown</option>
                                <option value='6'>Notfound</option>
                                <option value='7'>Duplicate</option>
                                <option value='8'>Error</option>
                                <option value='9'>Providerupdated</option>
                                <option value='10'>Cancelled</option>
                                <option value='11'>Forwarded</option>
                                <option value='12'>CancelInprogress</option>
                                <option value='13'>Partnercancel</option>
                                <option value='14'>Abandoned</option>
                                <option value='15'>Completed</option>
                                <option value='16'>Checkoutupdated</option>
                                <option value='17'>LocationNotFound</option>
                                <option value='18'>Reschedule</option>
                                <option value='19'>Dropped</option>
                              </select>
                            </div>

                            <div className='d-flex'>
                              <button
                                type='button'
                                className='btn btn-sm btn-primary me-3 mb-1 p-3'
                                onClick={async () => {
                                  setLoading(true);
                                  await fetchJobOrders(jobId, currentOrderPage, orderStatus, selectedorderRowsPerPage, filterid);
                                  setLoading(false);
                                }}
                              >
                                Refresh
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='client_table'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                        <th className='min-w-125px'>KS Order Id</th>
                        <th className='min-w-125px'>Partner Order Id</th>
                        <th className='min-w-125px'>Receive Time</th>
                        <th className='min-w-125px'>Status</th>
                        <th className='text-end min-w-70px'>Actions</th>
                        {loading && (
                          <SpinnerDotted
                            size={80}
                            thickness={120}
                            className='spinner'
                            speed={100}
                            color='#c8c6c2'
                          />
                        )}
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {jobOrdersData.map((orders) => {

                        return (
                          <tr key={orders.ksOrderId}>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={orders?.ksOrderId?.toString()}>

                              {orders?.ksOrderId?.toString()?.length &&
                                (orders.ksOrderId.toString().length >= 7
                                  ? `${orders.ksOrderId.toString().slice(0, 4)}...${orders.ksOrderId
                                    .toString()
                                    .slice(-2)}`
                                  : orders.ksOrderId.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={orders?.partnerOrderId?.toString()}>
                              {orders?.partnerOrderId?.toString()?.length &&
                                (orders.partnerOrderId.toString().length >= 7
                                  ? `${orders.partnerOrderId.toString().slice(0, 4)}...${orders.partnerOrderId
                                    .toString()
                                    .slice(-2)}`
                                  : orders.partnerOrderId.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {orders?.ksOrderReceiveTime?.slice(0, 16).replace("T", " ")}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {(orders.orderStatusId === 'INPROGRESS' ||
                                orders.orderStatusId === 'INIT' ||
                                orders.orderStatusId === 'IN_PROGRESS') && (
                                  <div className='badge badge-light-primary fw-bolder'>
                                    IN PROGRESS
                                  </div>
                                )}
                              {(orders.orderStatusId === 'CREATED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  CREATED
                                </div>
                              )}
                              {(orders.orderStatusId === 'ACCEPTED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  ACCEPTED
                                </div>
                              )}
                              {(orders.orderStatusId === 'DENIED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  DENIED
                                </div>
                              )}
                              {(orders.orderStatusId === 'UNKNOWN') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  UNKNOWN
                                </div>
                              )}
                              {(orders.orderStatusId === 'NOTFOUND') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  NOTFOUND
                                </div>
                              )}
                              {(orders.orderStatusId === 'DUPLICATE') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  DUPLICATE
                                </div>
                              )}
                              {(orders.orderStatusId === 'ERROR') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  ERROR
                                </div>
                              )}
                              {(orders.orderStatusId === 'PROVIDERUPDATED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  PROVIDER UPDATED
                                </div>
                              )}
                              {(orders.orderStatusId === 'CANCELLED') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  CANCELLED
                                </div>
                              )}
                              {(orders.orderStatusId === 'FORWARDED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  FORWARDED
                                </div>
                              )}
                              {(orders.orderStatusId === 'CANCELINPROGRESS') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  CANCELINPROGRESS
                                </div>
                              )}
                              {(orders.orderStatusId === 'PARTNERCANCEL') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  PARTNERCANCEL
                                </div>
                              )}
                              {(orders.orderStatusId === 'ABANDONED') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  ABANDONED
                                </div>
                              )}
                              {(orders.orderStatusId === 'COMPLETED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  COMPLETED
                                </div>
                              )}
                              {(orders.orderStatusId === 'CHECKOUTUPDATED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  CHECKOUTUPDATED
                                </div>
                              )}
                              {(orders.orderStatusId === 'LOCATION_NOT_FOUND') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  LOCATION_NOT_FOUND
                                </div>
                              )}
                              {(orders.orderStatusId === 'RESCHEDULE') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  RESCHEDULE
                                </div>
                              )}
                              {(orders.orderStatusId === 'DROPPED') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  DROPPED
                                </div>
                              )}

                            </td>
                            <td className='text-end'>
                              <div className='menu-item'>
                                <Link to={`/jobs/${orders.jobId}/joborders/${orders.ksOrderId}/details`}>
                                  <button
                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                    data-kt-menu-trigger='click'
                                    role='button'
                                    data-kt-menu-placement='bottom-end'
                                  >
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 -4 24 24'
                                        id='meteor-icon-kit__solid-eye'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fillRule='evenodd'
                                          clipRule='evenodd'
                                          d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                          fill='#758CA3'
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between'>
                    <div className='dataTables_length d-flex align-items-center' id='kt_ecommerce_products_table_length'>
                      <label>
                        <select
                          name='kt_job_batches_table_length'
                          aria-controls='kt_ecommerce_products_table'
                          className='form-select form-select-sm form-select-solid'
                          value={selectedorderRowsPerPage}
                          onChange={async (e) => {
                            const newRowsPerPage = Number(e.target.value);
                            setSelectedorderRowsPerPage(newRowsPerPage);
                            setCurrentOrderPage(1);
                            setLoading(true);
                            await fetchJobOrders(jobId, currentBatchPage, orderStatus, newRowsPerPage, filterid);
                            setLoading(false);
                          }}
                        >
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='20'>20</option>
                          <option value='30'>30</option>
                          <option value='40'>40</option>
                          <option value='50'>50</option>
                          <option value='60'>60</option>
                          <option value='70'>70</option>
                          <option value='80'>80</option>
                          <option value='90'>90</option>
                          <option value='100'>100</option>
                        </select>
                      </label>
                      <div className="mr-2 fw-bolder fs-7 text-gray-700">Total number of rows: {orderscount}</div>
                    </div>
                    <ReactPaginate
                      pageCount={orderPageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handleOrdersPage}
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      activeClassName={'active'}
                      containerClassName={'pagination justify-content-end'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      initialPage={0}
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='transactions_tab_content' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                <div className='d-flex flex-stack row'>
                  <div className='card-toolbar'>
                    <div className='d-flex justify-content-end ' data-kt-user-table-toolbar='base'>
                      <div className=' w-250px me-3'>
                        <form data-kt-search-element="form" className="d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative">
                          <span className="svg-icon svg-icon svg-icon-2 svg-icon-gray-700 position-absolute top-50 translate-middle-y ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="mh-50px">
                              <path d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z" fill="currentColor"></path>
                              <path opacity="0.3" d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z" fill="currentColor"></path>
                            </svg>
                          </span>

                          <input
                            className="form-control bg-transparent ps-13 fs-7 h-40px"
                            placeholder="Quick Search"
                            onChange={handleInputChange}
                            onKeyDown={handleInputKeyDown}
                            onBlur={handleInputBlur}
                          />
                          <span className="btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4" data-kt-search-element="clear">
                            <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0"></span>
                          </span>
                        </form>
                      </div>
                      <div className='d-flex flex-stack flex-wrap gap-2'>
                        <div className="d-flex justify-content-between align-items-center" data-kt-user-table-toolbar="base">
                          <div className="d-flex align-items-end">
                          </div>
                          <div className='d-flex align-items-end ' data-kt-user-table-toolbar='base'>
                            <div className=' w-150px me-3'>
                              <select
                                className='form-select clear form-select-solid'
                                onChange={async (event) => {
                                  setLoading(true)
                                  setCurrentBatchPage(1)
                                  const selectedStatus = event.target.value
                                  setOrderStatus(selectedStatus)
                                  await fetchJobtransactions(jobId, currentBatchPage, selectedStatus, selectedorderRowsPerPage, filterid)
                                  setLoading(false)
                                }}
                              >
                                <option value=''>-Select Status-</option>
                                <option value='1'>In Progress</option>
                                <option value='2'>Created</option>
                                <option value='3'>Accepted</option>
                                <option value='4'>Denied</option>
                                <option value='5'>Unknown</option>
                                <option value='6'>Notfound</option>
                                <option value='7'>Duplicate</option>
                                <option value='8'>Error</option>
                                <option value='9'>Providerupdated</option>
                                <option value='10'>Cancelled</option>
                                <option value='11'>Forwarded</option>
                                <option value='12'>CancelInprogress</option>
                                <option value='13'>Partnercancel</option>
                                <option value='14'>Abandoned</option>
                                <option value='15'>Completed</option>
                                <option value='16'>Checkoutupdated</option>
                                <option value='17'>LocationNotFound</option>
                                <option value='18'>Reschedule</option>
                                <option value='19'>Dropped</option>
                              </select>
                            </div>

                            <div className='d-flex'>
                              <button
                                type='button'
                                className='btn btn-sm btn-primary me-3 mb-1 p-3'
                                onClick={async () => {
                                  setLoading(true);
                                  await fetchJobtransactions(jobId, currentOrderPage, orderStatus, selectedorderRowsPerPage, filterid);
                                  setLoading(false);
                                }}
                              >
                                Refresh
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0'>
                  <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='client_table'
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                        <th className='min-w-125px'>KS Id</th>
                        <th className='min-w-125px'>Partner Id</th>
                        <th className='min-w-125px'>Receive Time</th>
                        <th className='min-w-125px'>Status</th>
                        <th className='text-end min-w-70px'>Actions</th>
                        {loading && (
                          <SpinnerDotted
                            size={80}
                            thickness={120}
                            className='spinner'
                            speed={100}
                            color='#c8c6c2'
                          />
                        )}
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {jobTransactionsData.map((transactions) => {

                        return (
                          <tr key={transactions.ksId}>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={transactions?.ksId?.toString()}>

                              {transactions?.ksId?.toString()?.length &&
                                (transactions.ksId.toString().length >= 7
                                  ? `${transactions.ksId.toString().slice(0, 4)}...${transactions.ksId
                                    .toString()
                                    .slice(-2)}`
                                  : transactions.ksId.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary' title={transactions?.partnerId?.toString()}>
                              {transactions?.partnerId?.toString()?.length &&
                                (transactions.partnerId.toString().length >= 7
                                  ? `${transactions.partnerId.toString().slice(0, 4)}...${transactions.partnerId
                                    .toString()
                                    .slice(-2)}`
                                  : transactions.partnerId.toString())}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {transactions?.createTime?.toString().slice(0, 16).replace("T", " ")}
                            </td>
                            <td className='fs-6 text-gray-700 text-hover-primary'>
                              {(transactions.statusId === 'INPROGRESS' ||
                                transactions.statusId === 'INIT' ||
                                transactions.statusId === 'IN_PROGRESS') && (
                                  <div className='badge badge-light-primary fw-bolder'>
                                    IN PROGRESS
                                  </div>
                                )}
                              {(transactions.statusId === 'CREATED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  CREATED
                                </div>
                              )}
                              {(transactions.statusId === 'ACCEPTED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  ACCEPTED
                                </div>
                              )}
                              {(transactions.statusId === 'DENIED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  DENIED
                                </div>
                              )}
                              {(transactions.statusId === 'UNKNOWN') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  UNKNOWN
                                </div>
                              )}
                              {(transactions.statusId === 'NOTFOUND') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  NOTFOUND
                                </div>
                              )}
                              {(transactions.statusId === 'DUPLICATE') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  DUPLICATE
                                </div>
                              )}
                              {(transactions.statusId === 'ERROR') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  ERROR
                                </div>
                              )}
                              {(transactions.statusId === 'PROVIDERUPDATED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  PROVIDER UPDATED
                                </div>
                              )}
                              {(transactions.statusId === 'CANCELLED') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  CANCELLED
                                </div>
                              )}
                              {(transactions.statusId === 'FORWARDED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  FORWARDED
                                </div>
                              )}
                              {(transactions.statusId === 'CANCELINPROGRESS') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  CANCELINPROGRESS
                                </div>
                              )}
                              {(transactions.statusId === 'PARTNERCANCEL') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  PARTNERCANCEL
                                </div>
                              )}
                              {(transactions.statusId === 'ABANDONED') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  ABANDONED
                                </div>
                              )}
                              {(transactions.statusId === 'COMPLETED') && (
                                <div className='badge badge-light-success fw-bolder'>
                                  COMPLETED
                                </div>
                              )}
                              {(transactions.statusId === 'CHECKOUTUPDATED') && (
                                <div className='badge badge-light-primary fw-bolder'>
                                  CHECKOUTUPDATED
                                </div>
                              )}
                              {(transactions.statusId === 'LOCATION_NOT_FOUND') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  LOCATION_NOT_FOUND
                                </div>
                              )}
                              {(transactions.statusId === 'RESCHEDULE') && (
                                <div className='badge badge-light-warning fw-bolder'>
                                  RESCHEDULE
                                </div>
                              )}
                              {(transactions.statusId === 'DROPPED') && (
                                <div className='badge badge-light-danger fw-bolder'>
                                  DROPPED
                                </div>
                              )}

                            </td>
                            <td className='text-end'>
                              <div className='menu-item'>
                                <Link to={`/jobs/${transactions.jobId}/transactions/${transactions.ksId}/details`}>
                                  <button
                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                    data-kt-menu-trigger='click'
                                    role='button'
                                    data-kt-menu-placement='bottom-end'
                                  >
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 -4 24 24'
                                        id='meteor-icon-kit__solid-eye'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fillRule='evenodd'
                                          clipRule='evenodd'
                                          d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                          fill='#758CA3'
                                        />
                                      </svg>
                                    </span>
                                  </button>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='d-flex justify-content-between'>
                    <div className='dataTables_length d-flex align-items-center' id='kt_ecommerce_products_table_length'>
                      <label>
                        <select
                          name='kt_job_batches_table_length'
                          aria-controls='kt_ecommerce_products_table'
                          className='form-select form-select-sm form-select-solid'
                          value={selectedorderRowsPerPage}
                          onChange={async (e) => {
                            const newRowsPerPage = Number(e.target.value);
                            setSelectedorderRowsPerPage(newRowsPerPage);
                            setCurrentOrderPage(1);
                            setLoading(true);
                            await fetchJobOrders(jobId, currentBatchPage, orderStatus, newRowsPerPage, filterid);
                            setLoading(false);
                          }}
                        >
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='20'>20</option>
                          <option value='30'>30</option>
                          <option value='40'>40</option>
                          <option value='50'>50</option>
                          <option value='60'>60</option>
                          <option value='70'>70</option>
                          <option value='80'>80</option>
                          <option value='90'>90</option>
                          <option value='100'>100</option>
                        </select>
                      </label>
                      <div className="mr-2 fw-bolder fs-7 text-gray-700">Total number of rows: {orderscount}</div>
                    </div>
                    <ReactPaginate
                      pageCount={transactionsPageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handleOrdersPage}
                      previousLabel={'<<'}
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      activeClassName={'active'}
                      containerClassName={'pagination justify-content-end'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      initialPage={0}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* <!--end::Card--> */}
        </div>
        {/* <!--end::Container--> */}{' '}
      </div>
    </BlockUi>
  )
}

export { JobDetailsPage }

