import React, { FC } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../_metronic/helpers'
import { PageLink } from '../../_metronic/layout/core'
import { useHref } from 'react-router-dom';

type Props = {
  className: string
}

const VProviderList: React.FC<Props> = ({ className }) => {

  // const href = useHref({ pathname: '/path-to-link' });
  // const handle = useHref({ pathname: '/path-to-link' });


  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2x mb-1'>Users</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {/* <a
              href='#'
              className='btn btn-sm btn-light-primary'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
              New Member
            </a> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
                                    <tr className="text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0">
                                        <th className="w-10px pe-2">
                                            <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                <input className="form-check-input" type="checkbox" data-kt-check="true"
                                                    data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                                            </div>
                                        </th>
                                        <th className="min-w-125px">Partner</th>
                                        <th className="min-w-125px">Provider</th>
                                        <th className="min-w-125px">Status</th>
                                        <th className="min-w-125px">CreateTime</th>
                                        {/* <th className="min-w-125px">createdBy</th> */}
                                        <th className="text-end min-w-70px">Actions</th>
                                       
                                    </tr>
                                </thead>
            {/* <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-150px'>Created</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-170px text-satrt'>Actions</th>
              </tr>
            </thead> */}
            {/* end::Table head */}
            {/* begin::Table body */}
             <tbody>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Ana Simmons
                      </a>
                  
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Intertico
                  </a>
              
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex  flex-shrink-0'>
                    <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">

                      <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                        </svg>
                      </span>
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                  
                    </a>

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                 
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Jessie Clarcson
                      </a>
                    
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    Agoda
                  </a>
              
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>70%</span>
                    </div>
                 
                  </div>
                </td>
                <td>
                  <div className='d-flex  flex-shrink-0'>
                    <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">

                      <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                        </svg>
                      </span>
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >

                    </a>

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                 
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Lebron Wayde
                      </a>
                 
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
              
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>60%</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex  flex-shrink-0'>
                    <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">

                      <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                        </svg>
                      </span>
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                      
                    </a>

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
              
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Natali Goodwin
                      </a>
                  
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    The Hill
                  </a>
              
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>50%</span>
                    </div>
                   
                  </div>
                </td>
                <td>
                  <div className='d-flex  flex-shrink-0'>
                    <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">

                      <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                        </svg>
                      </span>
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                    
                    </a>

                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                  </div>
                </td>
                <td>
                  <div className='d-flex align-items-center'>
                   
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                        Kevin Leonard
                      </a>
                      
                    </div>
                  </div>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                    RoadGee
                  </a>
            
                </td>
                <td className='text-end'>
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-muted me-2 fs-7 fw-semibold'>90%</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div className='d-flex  flex-shrink-0'>
                    <a href="" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-kt-menu-trigger="click" role="button" data-kt-menu-placement="bottom-end">

                      <span className="svg-icon svg-icon-3">
                        <svg width="24" height="24" viewBox="0 -4 24 24" id="meteor-icon-kit__solid-eye" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z" fill="#758CA3" />
                        </svg>
                      </span>
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                    >
                   
                    </a>

                  </div>
                </td>
              </tr>
            </tbody> 
       
          </table>


        </div>
    

        <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='fs-6 fw-bold text-gray-700'></div>

          <ul className='pagination'>
            <li className='page-item previous'>
              <a href='#' className='page-link'>
                <i className='previous'></i>
              </a>
            </li>

            <li className='page-item active'>
              <a href='#' className='page-link'>
                1
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                2
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                3
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                4
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                5
              </a>
            </li>

            <li className='page-item'>
              <a href='#' className='page-link'>
                6
              </a>
            </li>

            <li className='page-item next'>
              <a href='#' className='page-link'>
                <i className='next'></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* begin::Body */}

    </div>


  )
}
export { VProviderList };