import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SpinnerDotted } from "spinners-react"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import BlockUi from "../../../app/BlockUI/BlockUi"
import TransactionDetailsModel from "./TransactionDetailsModel"
import TransactionDetailsCommand from "./TransactionDetailsCommand"

let TransactionDetailsPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { jobid, ksid } = useParams<string>()
  const [transactiondetails, setTransactiondetails] = useState<TransactionDetailsModel | null>(null);


  const getTransactionDetails = async () => {
    const command = new TransactionDetailsCommand();
    await command.loadTransactionDetails(jobid, ksid);
    setTransactiondetails(command.transactionDetails);
    console.log(transactiondetails);
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      await getTransactionDetails();
      setLoading(false)
    })
  }, []);

  function formatDateTimeString(dateTimeStr: any) {
    const dateTime = new Date(dateTimeStr)
    const formattedDateTime = dateTime.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    return formattedDateTime
  }

  return ( 
    <BlockUi blocking={loading} message=''>
      <div className='position-absolute top-0 start-50 translate-middle-x mt-20 pt-5'>
      {(transactiondetails?.statusId == 'INIT'|| transactiondetails?.statusId=='INPROGRESS' || transactiondetails?.statusId=='IN_PROGRESS') && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '45.0em', borderRadius: 4}}
          >
            IN PROGRESS
          </div>
        )}
         {transactiondetails?.statusId == 'CREATED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            CREATED
          </div>
        )}
         {transactiondetails?.statusId == 'ACCEPTED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            ACCEPTED
          </div>
        )}
         {transactiondetails?.statusId == 'DENIED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            DENIED
          </div>
        )}
         {transactiondetails?.statusId == 'UNKNOWN' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            UNKNOWN
          </div>
        )}
         {transactiondetails?.statusId == 'NOTFOUND' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            NOTFOUND
          </div>
        )}
         {transactiondetails?.statusId == 'DUPLICATE' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            DUPLICATE
          </div>
        )}
         {transactiondetails?.statusId == 'ERROR' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            ERROR
          </div>
        )}
         {transactiondetails?.statusId == 'PROVIDERUPDATED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '28.0em', borderRadius: 4}}
          >
            PROVIDER UPDATED
          </div>
        )}
         {transactiondetails?.statusId == 'CANCELLED' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            CANCELLED
          </div>
        )}
         {transactiondetails?.statusId == 'FORWARDED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            FORWARDED
          </div>
        )}
         {transactiondetails?.statusId == 'CANCELINPROGRESS' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            CANCELINPROGRESS
          </div>
        )}
         {transactiondetails?.statusId == 'PARTNERCANCEL' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            PARTNERCANCEL
          </div>
        )}
         {transactiondetails?.statusId == 'ABANDONED' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            ABANDONED
          </div>
        )}
         {transactiondetails?.statusId == 'COMPLETED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            COMPLETED
          </div>
        )}
         {transactiondetails?.statusId == 'CHECKOUTUPDATED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            CHECKOUTUPDATED
          </div>
        )}
         {transactiondetails?.statusId == 'LOCATION_NOT_FOUND' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '26.0em', borderRadius: 4}}
          >
            LOCATION_NOT_FOUND
          </div>
        )}
         {transactiondetails?.statusId == 'RESCHEDULE' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            RESCHEDULE
          </div>
        )}
         {transactiondetails?.statusId == 'DROPPED' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            DROPPED
          </div>
        )}
      </div>
      <div className='d-flex flex-column flex-xl-row'>
        {/*begin::Sidebar*/}
        <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
          {/*begin::Card*/}
          <div className='card mb-5 mb-xl-8'>
            {/*begin::Card body*/}
            <div className='card-body pt-15'>
              {/*begin::Summary*/}
              <div className='d-flex flex-center flex-column mb-5'>
                {/*begin::Avatar*/}
                <div className='symbol symbol-100px symbol-rectangle mb-7'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/avatars/transaction.png')} />
                </div>
                {/*end::Avatar*/}
                {/*begin::Name*/}
                <a className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'></a>
                {/*end::Name*/}
              </div>
              {/*end::Summary*/}
              {/*begin::Details toggle*/}
              {loading && (
                <SpinnerDotted
                  size={80}
                  thickness={120}
                  className='spinner'
                  speed={100}
                  color='#c8c6c2'
                />
              )}
              <div className='d-flex flex-stack fs-4 py-3'>
                <a
                  className='fw-bolder text-black rotate collapsible collapsed'
                  href='#kt_customer_view_details'
                  data-bs-toggle='collapse'
                  role='button'
                  aria-expanded='false'
                  aria-controls='kt_customer_view_details'
                >
                  Details
                </a>
              </div>
              {/*end::Details toggle*/}
              <div className='separator separator-dashed my-3'></div>

              {/*begin::Details content*/}
              <div id='kt_customer_view_details' className='collapse show'>
                <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>Ks Id</div>
                  <div className='text-gray-600'>{transactiondetails?.ksId}</div>
                </div> 
                <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>Provider Id</div>
                  <div className='text-gray-600'>{transactiondetails?.providerId}</div>
                </div>
               <div className='py-3 fs-6'>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div className='flex-lg-row-fluid ms-lg-15'>
          {/*begin:::Tabs*/}
          <div className='d-flex justify-content-between'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
              {/*begin:::Tab item*/}
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#device_overview_tab'>
                  Overview
                </a>
              </li>
            </ul>
            <div className='d-flex align-items-end justify-content-end mb-8'>
              <div className='ms-auto'>
                <div className='me-0'>
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <a
                        role='button'
                        className='menu-link px-3'
                      >
                        InActive User
                      </a>
                    </div>
                    <div className='menu-item px-3'>
                      <a role='button' className='menu-link px-3'>
                        Reset Password
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-content' id='myTabContent'>
            {/*begin:::Tab pane*/}

            <div className='tab-pane fade show active' id='device_overview_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Card header*/}
                <div className=''><h3 className='card-title fs-6 text-gray-700 pt-2'></h3></div>

                <div className='card-body pt-0'>
                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.providerId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.partnerId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner Store Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.partnerStoreId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider Store Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.providerStoreId}
                          />
                        </div>
                      </div>
                       <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Receive Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              transactiondetails?.createTime?.toString().slice(0, 16).replace("T", " ")
                            }
                          />
                        </div>
                      </div> 
                     
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              transactiondetails?.lastModifiedTime?.toString().slice(0, 16).replace('T', ' ').startsWith('9999') ||
                              transactiondetails?.lastModifiedTime?.toString().slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : transactiondetails?.lastModifiedTime?.toString().slice(0, 16).replace('T', ' ')
                            }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              transactiondetails?.lastActivityTime?.toString().slice(0, 16).replace('T', ' ').startsWith('9999') ||
                              transactiondetails?.lastActivityTime?.toString().slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : transactiondetails?.lastActivityTime?.toString().slice(0, 16).replace('T', ' ')
                            }
                          />
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mb-5 mt-5 '>
                        <h2 className='mb-0 fs-5 fw-bold'>Error</h2>
                        <hr className='flex-grow-1 ms-3' />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Source</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.errorSource}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Explanation</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.errorExplanation}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Message</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={transactiondetails?.errorMessage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  )
}
export { TransactionDetailsPage }

