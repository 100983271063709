import React, {useEffect, useState} from 'react'

import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import swal from 'sweetalert'
import backFillDetailsModel from './BackFillDeatilsModel'
import BaclFillDetailsViewModel from './BackFillDetailsViewModel'
import {SpinnerDotted} from 'spinners-react'
import BlockUi from '../../../app/BlockUI/BlockUi'
import UpdateBackFillStatusCommand from '../VBackFillStatus/PauseBackfillStatus/UpdateBackFillStatusCommand'
import BackFillBatchesCommand from '../VBackFillBatches/BackFillBatchesCommand'
import ReactPaginate from 'react-paginate'
import BackFillBatchesModel from '../VBackFillBatches/BackFillBatchesModel'
import {Tooltip} from 'bootstrap'
import ContinueBackFillStatusCommand from '../VBackFillStatus/ContinueBackFillStatus/ContinueBackFillStatusCommand'
import StopBackFillStatusCommand from '../VBackFillStatus/StopBackfillStatus/StopBackFillStatusCommand'

let BackFillDetailsPage: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const {id} = useParams<string>()
  const {entityName} = useParams<string>()
  let [backFillDetails, setbackFillDetails] = useState<backFillDetailsModel | null>(null)
  const command = new UpdateBackFillStatusCommand()
  const continuecommand = new ContinueBackFillStatusCommand()
  const stopcommand = new StopBackFillStatusCommand()
  const [themeMode, setThemeMode] = useState<string | null>('')
  const [entityType, setEntityType] = useState(entityName)
  const [reload, setReload] = useState(0)
  const viewModel = new BackFillBatchesCommand()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentData, setCurrentData] = useState<BackFillBatchesModel[]>([])
  const [pageCount, setPageCount] = useState(0)
  const rowsPerPage = 5

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected + 1)
  }

  const fetchbatches = async (
    id: string | undefined,
    entityName: string | undefined,
    page: any
  ) => {
    await viewModel.loadBatches(page, rowsPerPage, id, entityName)
    setCurrentData(viewModel.backfillbatches)
    setPageCount(viewModel.totalPages)
  }

  const getBackfilDetails = async () => {
    const viewModel = new BaclFillDetailsViewModel()
    await viewModel.loadData(id, entityName).then(() => {
      setbackFillDetails(viewModel.backFillDetails)
    })
  }

  useEffect(() => {
    const batches = async () => {
      setLoading(true)
      getBackfilDetails()
      await fetchbatches(id, entityName, currentPage)
      setLoading(false)
    }
    batches()
  }, [currentPage, reload])

  const handleUpdateStatus = async (status: any) => {
    if (status === 'PAUSED') {
      swal('Update Status', 'Backfill already paused', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to pause the backfill',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await command.UpdateStatus(
            backFillDetails?.id,
            entityName,
            backFillDetails?.partnerName,
            backFillDetails?.providerName
          )
          setReload(1)
          setLoading(false)
          if (command.statusCode == 200) {
            swal('Backfill Paused Successfully.', {
              icon: 'success',
            })
            setLoading(true)
            setLoading(false)
          } else {
            swal('update status', command?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const ContinueStatusUpdate = async (status: any) => {
    if (status === 'PROCESSING') {
      swal('Update Status', 'Backfill already Continue', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to resume the backfill',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await continuecommand.ContinueStaust(
            backFillDetails?.id,
            entityName,
            backFillDetails?.partnerName,
            backFillDetails?.providerName
          )
          setReload(2)
          setLoading(false)
          if (continuecommand.statusCode == 200) {
            swal('Backfill Resumed Successfully.', {
              icon: 'success',
            })
            setLoading(true)
            setLoading(false)
          } else {
            swal('update status', continuecommand?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const stopStatusUpdate = async (status: any) => {
    if (status === 'STOP') {
      swal('Update Status', 'Backfill already STOP', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'You want to stop the backfill',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willUpdate) => {
        if (willUpdate) {
          setLoading(true)
          const response = await stopcommand.stopStaust(
            backFillDetails?.id,
            entityName,
            backFillDetails?.partnerName,
            backFillDetails?.providerName
          )
          setReload(3)
          setLoading(false)
          if (stopcommand.statusCode == 200) {
            swal('Backfill Stopped Successfully.', {
              icon: 'success',
            })
            setLoading(true)
            setLoading(false)
          } else {
            swal('update status', stopcommand?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }

  const getDate = (dateStr: string) => {
    const date = new Date(dateStr)
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
    return formattedDate
  }

  function formatDateTimeString(dateTimeStr: any) {
    const dateTime = new Date(dateTimeStr)
    const formattedDateTime = dateTime.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    return formattedDateTime
  }

  return (
    <BlockUi blocking={loading} message=''>
      {loading && (
        <SpinnerDotted size={80} thickness={120} className='spinner' speed={100} color='#c8c6c2' />
      )}
      <div className='content d-flex flex-column flex-column-fluid' id='kt_content'>
        <div className=' container-xxl ' id='kt_content_container'>
          <div className='card mb-6 mb-xl-9'>
            <div className='card-body pt-9 pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-6'>
                <div className='d-flex flex-center flex-shrink-0 bg-light rounded w-100px h-100px w-lg-150px h-lg-150px me-7 mb-4'>
                  <img
                    className='mw-50px mw-lg-75px'
                    src={toAbsoluteUrl('/media/svg/brand-logos/volicity-9.svg')}
                    alt='image'
                  />
                </div>
                <div className='flex-grow-1'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-1'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bold me-3'>
                          Backfill Dashboard
                        </a>
                        {(backFillDetails?.status === 'INPROGRESS' ||
                          backFillDetails?.status === 'INIT' ||
                          backFillDetails?.status === 'IN_PROGRESS') && (
                          <div className='badge badge-primary fw-bolder'>IN PROGRESS</div>
                        )}
                        {backFillDetails?.status == 'PAUSED' && (
                          <div className='badge badge-primary fw-bolder'>PAUSED</div>
                        )}
                        {backFillDetails?.status == 'PROCESSING' && (
                          <div
                            className='badge badge-primary fw-bolder'
                            style={{marginRight: '20.0em', borderRadius: 4}}
                          >
                            PROCESSING
                          </div>
                        )}
                        {backFillDetails?.status == 'COMPLETE' && (
                          <div
                            className='badge badge-success fw-bolder'
                            style={{marginRight: '20.0em', borderRadius: 4}}
                          >
                            COMPLETE
                          </div>
                        )}
                        {backFillDetails?.status == 'STOP' && (
                          <div
                            className='badge badge-danger fw-bolder'
                            style={{marginRight: '20.0em', borderRadius: 4}}
                          >
                            STOP
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <!--end::Description--> */}
                    {/* <!--end::Details--> */}

                    {/* <!--begin::Actions--> */}
                    <div className='d-flex mb-4'>
                      {backFillDetails?.status !== 'STOP' &&
                        backFillDetails?.status !== 'COMPLETE' && (
                          <div className='me-0'>
                            <button
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              <i className='bi bi-three-dots fs-3'></i>{' '}
                            </button>
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                              data-kt-menu='true'
                            >
                              {backFillDetails?.status === 'PAUSED' && (
                                <>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={ContinueStatusUpdate}
                                      className='menu-link px-3'
                                    >
                                      Resume Backfill
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={stopStatusUpdate}
                                      className='menu-link px-3'
                                    >
                                      Stop Backfill
                                    </a>
                                  </div>
                                </>
                              )}
                              {backFillDetails?.status === 'PROCESSING' && (
                                <>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={handleUpdateStatus}
                                      className='menu-link px-3'
                                    >
                                      Pause Backfill
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={stopStatusUpdate}
                                      className='menu-link px-3'
                                    >
                                      Stop Backfill
                                    </a>
                                  </div>
                                </>
                              )}
                              {(backFillDetails?.status === 'INPROGRESS' ||
                                backFillDetails?.status === 'INIT' ||
                                backFillDetails?.status === 'IN_PROGRESS') && (
                                <>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={handleUpdateStatus}
                                      className='menu-link px-3'
                                    >
                                      Pause Backfill
                                    </a>
                                  </div>
                                  <div className='menu-item px-3'>
                                    <a
                                      role='button'
                                      onClick={stopStatusUpdate}
                                      className='menu-link px-3'
                                    >
                                      Stop Backfill
                                    </a>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                    {/* <!--begin::Menu--> */}

                    {/* <!--end::Actions--> */}
                  </div>
                  {/* <!--end::Head--> */}

                  {/* <!--begin::Info--> */}
                  <div className='d-flex flex-wrap justify-content-start'>
                    {/* <!--begin::Stats--> */}
                    <div className='d-flex flex-wrap'>
                      {/* <!--begin::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <div className='d-flex align-items-center'>
                          <div className='fs-4 fw-bold text-hover-primary'>
                            {backFillDetails?.createTime?.slice(0, 10)
                              ? getDate(backFillDetails?.createTime?.slice(0, 10))
                              : backFillDetails?.createTime?.slice(0, 10)}
                          </div>
                        </div>
                        {/* <!--end::Number--> */}

                        {/* <!--begin::Label--> */}
                        <div className='fw-semibold fs-6 text-gray-400'>Start Date</div>
                        {/* <!--end::Label--> */}
                      </div>
                      {/* <!--end::Stat--> */}

                      {/* <!--begin::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <div className='d-flex align-items-center'>
                          <div className='fs-4 fw-bold counted text-hover-primary'>
                            {backFillDetails?.timeFrameStart?.slice(0, 10)
                              ? formatDateTimeString(backFillDetails?.timeFrameStart?.slice(0, 10))
                              : backFillDetails?.timeFrameStart?.slice(0, 10)}
                          </div>
                        </div>
                        {/* <!--end::Number--> */}

                        {/* <!--begin::Label--> */}
                        <div className='fw-semibold fs-6 text-gray-400'>TimeFrameStart</div>
                        {/* <!--end::Label--> */}
                      </div>
                      {/* <!--end::Stat--> */}

                      {/* <!--begin::Stat--> */}
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                        {/* <!--begin::Number--> */}
                        <div className='d-flex align-items-center'>
                          <div className='fs-4 fw-bold counted text-hover-primary'>
                            {backFillDetails?.timeFrameEnd?.slice(0, 10)
                              ? formatDateTimeString(backFillDetails?.timeFrameEnd?.slice(0, 10))
                              : backFillDetails?.timeFrameEnd?.slice(0, 10)}
                          </div>
                        </div>
                        {/* <!--end::Number--> */}

                        {/* <!--begin::Label--> */}
                        <div className='fw-semibold fs-6 text-gray-400'>TimeFrameEnd</div>
                        {/* <!--end::Label--> */}
                      </div>

                      {/* <!--end::Stat--> */}
                    </div>
                    {/* <!--end::Stats--> */}

                    {/* <!--begin::Users--> */}
                    {/* <!--end::Users--> */}
                  </div>
                  {/* <!--end::Info--> */}
                </div>
                {/* <!--end::Wrapper--> */}
              </div>
              {/* <!--end::Details--> */}

              <div className='separator'></div>

              {/* <!--begin::Nav--> */}
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
                {/* <!--begin::Nav item--> */}
                <li className='nav-item py-5 me-6'>
                  <a
                    className='nav-link py-5 me-6 text-active-primary active'
                    data-bs-toggle='tab'
                    href='#backfill_overview_tab'
                  >
                    Overview
                  </a>
                </li>
                <li className='nav-item py-5 me-6'>
                  <a
                    className='nav-link py-5 text-active-primary me-6 '
                    data-bs-toggle='tab'
                    href='#backfill_batch_tab'
                  >
                    Batches
                  </a>
                </li>
                {/* <!--end::Nav item--> */}
              </ul>
              {/* <!--end::Nav--> */}
            </div>
          </div>
          {/* <!--end::Navbar--> */}
          {/* <!--begin::Row--> */}
          <div className='tab-content' id='myTabContent'>
            {/*begin:::Tab pane*/}
            <div className='tab-pane fade show active' id='backfill_overview_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Table*/}
                <div className='card-body pt-0'>
                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.providerName
                                ? backFillDetails?.providerName.charAt(0).toUpperCase() +
                                  backFillDetails?.providerName.slice(1)
                                : backFillDetails?.providerName
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Entity Type</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              entityName
                                ? entityName?.charAt(0).toUpperCase() + entityName?.slice(1)
                                : entityName
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.partnerName
                                ? backFillDetails?.partnerName.charAt(0).toUpperCase() +
                                  backFillDetails?.partnerName.slice(1)
                                : backFillDetails?.partnerName
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Brand</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={backFillDetails?.brand == '' ? '-' : backFillDetails?.brand}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          Time Frame Start
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.timeFrameStart?.slice(0, 10)
                                ? formatDateTimeString(
                                    backFillDetails?.timeFrameStart?.slice(0, 10)
                                  )
                                : backFillDetails?.timeFrameStart?.slice(0, 10)
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Time Frame End</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.timeFrameEnd?.slice(0, 10)
                                ? formatDateTimeString(backFillDetails?.timeFrameEnd?.slice(0, 10))
                                : backFillDetails?.timeFrameEnd?.slice(0, 10)
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Slot Duration</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={backFillDetails?.slotDuration}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Create Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.createTime?.slice(0, 10)
                                ? getDate(backFillDetails?.createTime?.slice(0, 10))
                                : backFillDetails?.createTime?.slice(0, 10)
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Created By</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={backFillDetails?.createdBy?.toLowerCase()}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          {' '}
                          Error Message{' '}
                        </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.errorMessage == ''
                                ? '-'
                                : backFillDetails?.errorMessage
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'> Error Source </label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={
                              backFillDetails?.errorSource == ''
                                ? '-'
                                : backFillDetails?.errorSource
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>
                          {' '}
                          Error Explanation{' '}
                        </label>
                        <div className='mb-5'>
                          <textarea
                            disabled
                            value={
                              backFillDetails?.errorExplanation == ''
                                ? '-'
                                : backFillDetails?.errorExplanation
                            }
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='backfill_batch_tab' role='tabpanel'>
              {/*begin::Card*/}
              {currentData.length > 0 ? (
                <div className='card pt-4 mb-6 mb-xl-9'>
                  {/*begin::Table*/}
                  <div className='card-body pt-0'>
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5'
                      id='client_table'
                    >
                      <thead>
                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                          <th className='min-w-125px'>BatchID</th>
                          <th className='min-w-125px'>Total</th>
                          <th className='min-w-125px'>Processed</th>
                          <th className='min-w-125px'>TimeFrameStart</th>
                          <th className='min-w-125px'>TimeFrameEnd</th>
                          <th className='min-w-125px'>Status</th>
                          <th className='text-end min-w-70px'>Actions</th>
                          {loading && (
                            <SpinnerDotted
                              size={80}
                              thickness={120}
                              className='spinner'
                              speed={100}
                              color='#c8c6c2'
                            />
                          )}
                        </tr>
                      </thead>
                      <tbody className='fw-bold text-gray-600'>
                        {currentData.map((batches) => {
                          console.log(batches)
                          return (
                            <tr key={batches.id}>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                {batches?.id?.toString()?.length &&
                                  (batches.id.toString().length >= 7
                                    ? `${batches.id.toString().slice(0, 4)}...${batches.id
                                        .toString()
                                        .slice(-2)}`
                                    : batches.id.toString())}
                              </td>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                {batches.totalCount}
                              </td>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                ({batches.processedCount})
                              </td>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                {backFillDetails?.timeFrameStart?.slice(0, 10)
                                  ? formatDateTimeString(
                                      backFillDetails?.timeFrameStart?.slice(0, 10)
                                    )
                                  : backFillDetails?.timeFrameStart?.slice(0, 10)}
                              </td>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                {backFillDetails?.timeFrameEnd?.slice(0, 10)
                                  ? formatDateTimeString(
                                      backFillDetails?.timeFrameEnd?.slice(0, 10)
                                    )
                                  : backFillDetails?.timeFrameEnd?.slice(0, 10)}
                              </td>
                              <td className='fs-6 text-gray-700 text-hover-primary'>
                                {(batches.status === 'INPROGRESS' ||
                                  batches.status === 'INIT' ||
                                  batches.status === 'IN_PROGRESS') && (
                                  <div className='badge badge-light-primary fw-bolder'>
                                    IN PROGRESS
                                  </div>
                                )}
                                {batches.status === 'PAUSED' && (
                                  <div
                                    className='badge badge-light-danger fw-bolder'
                                    style={{color: '#f15738', backgroundColor: '#FBF2EF'}}
                                  >
                                    PAUSED
                                  </div>
                                )}
                                {batches.status === 'PROCESSING' && (
                                  <div className='badge badge-light-primary fw-bolder'>
                                    PROCESSING
                                  </div>
                                )}
                                {batches.status === 'STOP' && (
                                  <div className='badge badge-light-danger fw-bolder'>STOP</div>
                                )}
                                {batches.status === 'COMPLETE' && (
                                  <div className='badge badge-light-success fw-bolder'>
                                    COMPLETED
                                  </div>
                                )}
                              </td>

                              <td className='text-end'>
                                <div className='menu-item'>
                                  <a
                                    className='btn btn-icon disabled btn-bg-light btn-active-color-primary btn-sm me-1'
                                    data-kt-menu-trigger='click'
                                    role='button'
                                    data-kt-menu-placement='bottom-end'
                                  >
                                    <span className='svg-icon svg-icon-3'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 -4 24 24'
                                        id='meteor-icon-kit__solid-eye'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          fill-rule='evenodd'
                                          clip-rule='evenodd'
                                          d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                          fill='#758CA3'
                                        />
                                      </svg>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <ReactPaginate
                      pageCount={pageCount}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      previousLabel={'<<'} // Disable the previous button for the first page
                      nextLabel={'>>'}
                      breakLabel={'...'}
                      activeClassName={'active'}
                      containerClassName={'pagination justify-content-end'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      disabledClassName={'disabled'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      initialPage={0}
                    />
                  </div>
                </div>
              ) : (
                <div className='card pt-4 mb-6 mb-xl-9'>
                  {/*begin::Table*/}
                  <div className='card-body pt-0 d-flex flex-column align-items-center'>
                    <h3 className='text-center p-4'>No Batches</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <!--end::Card--> */}
        </div>
        {/* <!--end::Container--> */}{' '}
      </div>
    </BlockUi>
  )
}
export {BackFillDetailsPage}
