import axios from "axios";

export default class PartnerDetailsViewModel {

    partnerDetails : any;

    constructor (){
        this.partnerDetails=null;
    }

    async LoadPartneDetails(id : any){
        try {
            const API_URL = process.env.REACT_APP_API_URL;
            const idToken: string | null = localStorage.getItem("IdToken");
            const response = await axios.get(
              `${API_URL}/v1/partners/${id}`,
              {
                headers: {
                  Authorization: `${idToken}`,
                },
              }
            );
      
            const data = response.data.response; // Assumes server returns a single item
            this.partnerDetails = data;
          } catch (error) {
            console.error("Failed to load partner detais data:", error);
          }
        }

    }
