import React from 'react'
import { PageLink } from '../_metronic/layout/core'
import {
  FeedsWidget2,
  FeedsWidget3,
  FeedsWidget4,
  FeedsWidget5,
  FeedsWidget6,
  ChartsWidget1,
  ListsWidget5,
  MixedWidget2,
  MixedWidget1,
  MixedWidget13,
  MixedWidget15,
  MixedWidget14,
  TablesWidget3,

} from '../_metronic/partials/widgets'

import { useHref } from 'react-router-dom';
import { MixedWidget16 } from '../_metronic/partials/widgets/mixed/MixedWidget16';
import { TablesWidget14 } from '../_metronic/partials/widgets/tables/TableWidget14';

    


export function SupportDashboard() {
    const href = useHref({ pathname: '/path-to-link' });
    const handle = useHref({ pathname: '/path-to-link' });
  return (
    <div className='row g-5 g-xxl-8'>

       <h1 > Support Dashboard </h1>

    </div>
  )
}
