import { type } from "os";
import { useState } from "react";
import PartnerModel from "./PartnerModel";

export class PartnerListViewModel{
    partners : PartnerModel[];

    constructor(){
        this.partners = [];
    }

    async loadData(){

        const partnersList : PartnerModel[] = [
            new PartnerModel('1','Taco Bell ','17/02/2023','Active'),
            new PartnerModel('2','Dominos ','09/02/2023','InActive'),
            new PartnerModel('3','Pizza Hut ','01/02/2023','Active'),
            new PartnerModel('4','Oven Story ','28/01/2023','Active'),
            new PartnerModel('5','KFC ','15/02/2023','InActive'),
        ];
    
        this.partners = partnersList;
    }

    
}

   


