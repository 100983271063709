import React from 'react'
import { PageLink } from '../_metronic/layout/core'
import {
  
  MixedWidget15,
  MixedWidget14,
  TablesWidget3,

} from '../_metronic/partials/widgets'

import { useHref } from 'react-router-dom';

    


export function InfraDashboard() {
    const href = useHref({ pathname: '/path-to-link' });
    const handle = useHref({ pathname: '/path-to-link' });
  return (
    <div className='row g-5 g-xxl-8'>
      
      <h1> Infra Dashboard </h1>

    </div>
  )
}
