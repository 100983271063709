export default class ProviderDetailsModel {
    Id ? : string;
    Name ? : string;
    Created?:string;
    Email?:string;
    Status ? : string;


    constructor(id: string, name: string ,created:string, email:string, status : string) {
        this.Id = id;
        this.Name = name;
        this.Created=created;
        this.Email=email;
        this.Status=status;

      }
}
