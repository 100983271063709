
import axios from "axios";
import UserDetailsModel from "./UserDetailsModel";

export default class UserDetailsViewModel {
  userDetails: any; // Change type to match expected response format
  constructor() {
    this.userDetails = null;
  }

  async loadData(userId: any , cognitoUserId: any) {
    try {
      const API_URL = process.env.REACT_APP_API_URL;
      const idToken: string | null = localStorage.getItem("IdToken");
      const response = await axios.get(
        `${API_URL}/v1/users/${userId}/cognito/${cognitoUserId}/details`,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );

      const data = response.data.response; // Assumes server returns a single item
      this.userDetails = data;
    } catch (error) {
      console.error("Failed to load user data:", error);
    }
  }
}

