import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import ProviderDetailsModel from './ProviderDetailsModel';
import { ProviderDetailsViewModel } from './ProviderDetailsViewModel';


// const href = useHref({ pathname: '/path-to-link' });

// const getProvider =async (id:string) => {
//     var provider = new ProviderDetailsViewModel();
//     return await provider.getProvider(id);
// }


let ProviderDetailsPage: React.FC = () => {
    const Id = useParams<{Id : string}>();

    const[provider,setProvider] = useState<ProviderDetailsModel | null>(null);

    useEffect(() =>{
       const getProvider = async () => {
       let viewModel = new ProviderDetailsViewModel();

        let provider = await viewModel.getProvider(Id); 
        
        if(provider !== undefined){
            await setProvider(provider);
        }
    }; getProvider()},[Id])

    return (

        <div className="d-flex flex-column flex-xl-row">
            {/*begin::Sidebar*/}
            <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                {/*begin::Card*/}
                <div className="card mb-5 mb-xl-8">
                    {/*begin::Card body*/}
                    <div className="card-body pt-15">
                        {/*begin::Summary*/}
                        <div className="d-flex flex-center flex-column mb-5">
                            {/*begin::Avatar*/}
                            <div className="symbol symbol-100px symbol-circle mb-7">
                               <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                            </div>
                            {/*end::Avatar*/}
                            {/*begin::Name*/}
                            <a className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-1"></a>
                            {/*end::Name*/}
                        </div>
                        {/*end::Summary*/}
                        {/*begin::Details toggle*/}
                        <div className="d-flex flex-stack fs-4 py-3">
                            <div className="fw-bolder rotate collapsible collapsed"  data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">
                                Details
                                <span className="ms-2 rotate-180">
                                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr072.svg*/}
                                    <span className="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                    {/*end::Svg Icon*/}
                                </span>
                            </div>
                            <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="" data-bs-original-title="Edit customer details">
                                <a className="btn btn-sm btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_update_customer">Edit</a>
                            </span>
                        </div>
                        {/*end::Details toggle*/}
                        <div className="separator separator-dashed my-3"></div>

                        {/*begin::Details content*/}
                        <div id="kt_customer_view_details" className="collapse show">
                            <div className="py-5 fs-6">
                                {/*begin::Details item*/}
                                <div className="fw-bolder mt-5">Name</div>
                                <div className="text-gray-600">{provider?.Name}</div>
                                {/*begin::Details item*/}
                                <div className="fw-bolder mt-5">Contact Email</div>
                                <div className="text-gray-600">{provider?.Status}</div>
                                {/*begin::Details item*/}
                            </div>
                        </div>
                        {/*end::Details content*/}
                    </div>
                    {/*end::Card body*/}
                </div>
            </div>
            <div className="flex-lg-row-fluid ms-lg-15">
                {/*begin:::Tabs*/}
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                    {/*begin:::Tab item*/}
                    <li className="nav-item">
                        <a className="nav-link pb-4 " data-bs-toggle="tab" href="#device_overview_tab">Overview</a>
                    </li>
                  
                </ul>
                <div className="tab-content" id="myTabContent">
                    {/*begin:::Tab pane*/}

                    <div className="tab-pane fade show active" id="device_overview_tab" role="tabpanel">
                        {/*begin::Card*/}
                        <div className="card pt-4 mb-6 mb-xl-9">
                            {/*begin::Card header*/}
                            <div className="card-header border-0">
                                {/*begin::Card title*/}
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body pt-0">
                                {/*begin::Table*/}
                                <div className="mb-0">
                                    <div className="row gx-10 mb-5">
                                        <div className="col-lg-6">
                                            <label  className=" form-label fs-6 text-gray-700 mb-3">Name</label>
                                            {/*begin::Input group*/}
                                            <div className="mb-5">
                                                <input disabled value={provider?.Name} className="form-control form-control-solid" />
                                                {/*end::Select*/}
                                            </div>
                                            {/*end::Input group*/}
                                        </div>
                                        <div className="col-lg-6">
                                            <label  className="form-label fs-6 text-gray-700 mb-3">CreatedDate</label>
                                            {/*begin::Input group*/}
                                            <div className="mb-5">
                                                <input disabled value={provider?.Created} className="form-control form-control-solid" />
                                                {/*end::Select*/}
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export { ProviderDetailsPage }