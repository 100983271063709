import { AuthModel, UserModel } from "./LogInModel"
import * as authHelper from './AuthHelpers'
import {
    FC,
    useState,
    createContext,
    useContext,
    Dispatch,
    SetStateAction,
  } from 'react'
import { WithChildren } from "../../_metronic/helpers"

type AuthContextProps = {
    auth: AuthModel | undefined
    saveAuth: (auth: AuthModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
  }
  
  const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {},
    currentUser: undefined,
    setCurrentUser: () => {},
    logout: () => {},
  }
  
  const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)
  
  const useAuth = () => {
    return useContext(AuthContext)
  }

  const storeTokens = (idToken: string, accessToken : string) => {
      localStorage.setItem("IdToken",idToken);
      localStorage.setItem("AccessToken",accessToken);
  }
  
  const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel>()
    const saveAuth = (auth: AuthModel | undefined) => {
      setAuth(auth)
      if (auth) {
        authHelper.setAuth(auth)
        console.log(currentUser)
      } else {
        authHelper.removeAuth()
      }
    }
  
    const logout = () => {
      saveAuth(undefined)
      setCurrentUser(undefined)
    }
  
    return (
      <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
        {children}
      </AuthContext.Provider>
    )
  }

  export {AuthProvider, useAuth, storeTokens}