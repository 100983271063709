export default class ProviderListModel {
  Id ? : string;
    Name ? : string;
    CreatedDate ? : String;
    Status ? : string;

    constructor(id: string, name: string, createdate: string , status : string) {
        this.Id = id;
        this.Name = name;
        this.CreatedDate = createdate;
        this.Status=status;
      }
}
