import ReactPaginate from "react-paginate"
import BlockUi from "../../../app/BlockUI/BlockUi"
import { useEffect, useState } from "react"
import OrdersListModel from "./OrderListModel"
import { SpinnerDotted } from "spinners-react"
import OrdersListViewModel from "./OrdersListViewModel"
import PartnersModel from "../../MBackFillings/VPartners/PartnersModel"
import PartnersViewModel from "../../MBackFillings/VPartners/PartnersViewModel"
import DatePicker from 'react-datepicker'
import { Link } from "react-router-dom"
import { orders } from "../../../_metronic/partials/layout/sidebar-widgets/sidebarData"


let OrdersListPage: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const viewModel = new OrdersListViewModel()
    const [currentPage, setCurrentPage] = useState(1)
    const [currentData, setCurrentData] = useState<OrdersListModel[]>([])
    const [partners, setPartners] = useState<PartnersModel[]>([])
    const [partnerName, setPartnerName] = useState<string>('')
    const [pageCount, setPageCount] = useState(0)
    const rowsPerPage = 5
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);
    const partnersViewmodel = new PartnersViewModel()
    const [toDate, setTodate] = useState<Date>(new Date())
    const maxDate = new Date(toDate.getTime() + 24 * 60 * 60 * 1000)

    const currentDate = new Date()
    const minDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 2,
        currentDate.getDate()
    )
    const dateBefore7Days = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7
    )
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        dateBefore7Days,
        new Date(),
    ])
    const [startDate, endDate] = dateRange

    const [dateEmpty, setdateEmpty] = useState(false)

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1)
    }
    const [OrderCount, setOrderCount] = useState(0);
    const [totalOrderCount, setTotalOrderCount] = useState(0);


    const fetchOrdersData = async (
        partnerName: string,
        page: number,
        fromDate: Date | null,
        toDate: Date | null,
        rowsPerPage: number
    ) => {
        setLoading(true);
        const adjustedFromDate = fromDate ? new Date(fromDate.getTime() - 24 * 60 * 60 * 1000) : null;
        const adjustedToDate = toDate ? new Date(toDate.getTime() + 24 * 60 * 60 * 1000) : null;

        // Load jobs for the current page and retrieve the total count
        await viewModel.loadOrders(
            page,
            rowsPerPage,
            partnerName,
            adjustedFromDate,
            adjustedToDate
        );


        setCurrentData(viewModel.Orders);
        setPageCount(viewModel.totalPages);
        setOrderCount(viewModel.totalCount)
        console.log(viewModel.totalCount);
        setLoading(false);

        // Calculate the total count of jobs across all pages
        const totalCount = viewModel.totalPages * rowsPerPage;
        setTotalOrderCount(totalCount);
    }

    const getpartners = async () => {
        await partnersViewmodel.LoadPartners()
        setPartners(partnersViewmodel.partners)
    }

    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true)
            getpartners()
            await fetchOrdersData(partnerName, currentPage, startDate, endDate, selectedRowsPerPage)
            setLoading(false)
        }
        fetchOrders()
    }, [currentPage, partnerName, selectedRowsPerPage])



    const getDate = (dateStr: string) => {
        const date = new Date(dateStr)
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
        return formattedDate
    }

    return (
        <BlockUi blocking={loading} message=''>
            <div className='d-flex flex-column flex-lg-row'>
                {
                    <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
                        <div className='card'>

                            <div className='card-header border-0 pt-6'>
                                <div className='card-title'>
                                    <div className='d-none d-flex align-items-center position-relative my-1'>
                                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                            >
                                                <rect
                                                    opacity='0.5'
                                                    x='17.0365'
                                                    y='15.1223'
                                                    width='8.15546'
                                                    height='2'
                                                    rx='1'
                                                    transform='rotate(45 17.0365 15.1223)'
                                                    fill='black'
                                                />
                                                <path
                                                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                                    fill='black'
                                                />
                                            </svg>
                                        </span>
                                        <input
                                            type='text'
                                            data-kt-customer-table-filter='search'
                                            className='form-control form-control-solid w-250px ps-15'
                                            placeholder='Search Client'
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-stack flex-wrap gap-2'>
                                    <div className='d-flex flex-stack flex-wrap gap-2'>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <select
                                                className='form-select clear form-select-solid'
                                                onChange={async (event) => {
                                                    setCurrentPage(1)
                                                    const selectedPartnerName = event.target.value
                                                    setPartnerName(selectedPartnerName)
                                                    await fetchOrdersData(selectedPartnerName, 1, startDate, endDate, selectedRowsPerPage)
                                                }}
                                            >
                                                <option value=''>-Select Partner-</option>
                                                {partners.map((x) => (
                                                    <option value={x.name}>{x.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <DatePicker
                                                className='form-control clear form-control-solid fw-bold'
                                                calendarClassName='custom-calendar'
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={async (update) => {
                                                    if (update[0] == null || update[1] == null) {
                                                        setdateEmpty(true)
                                                    }else
                                                    setdateEmpty(false)
                                                    setDateRange(update)
                                                    setLoading(true)
                                                    if (dateEmpty) {
                                                        await fetchOrdersData(partnerName, currentPage, update[0], update[1], selectedRowsPerPage)
                                                    }
                                                    setLoading(false)
                                                }}
                                                isClearable={true}
                                                placeholderText='Select From To Dates'
                                                maxDate={maxDate}
                                                minDate={minDate}
                                            />
                                        </div>
                                        {dateEmpty && (
                                            <label className="text-danger">From and To date must not be empty</label>
                                        )}
                                    </div>
                                    {/* <button
                  type='button'
                  className='d-none btn btn-light-primary me-3'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_client_export_modal'
                >
                  <span className='svg-icon svg-icon-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='2'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <rect
                        opacity='0.3'
                        x='12.75'
                        y='4.25'
                        width='12'
                        height='1.5'
                        rx='1'
                        transform='rotate(90 12.75 4.25)'
                        fill='black'
                      />
                      <path
                        d='M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z'
                        fill='black'
                      />
                      <path
                        d='M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z'
                        fill='#C4C4C4'
                      />
                    </svg>
                  </span>
                </button> */}
                                </div>
                            </div>
                            <div className='card-body pt-0'>
                                <table className='table align-middle table-row-dashed fs-6 gy-5' id='client_table'>
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                                            <th className='min-w-125px'>ID
                                            </th>
                                            <th className='min-w-125px'>Provider</th>
                                            <th className='min-w-125px'>Partner</th>
                                            {/* <th className='min-w-125px'>Recevied Time</th> */}
                                            <th className='min-w-125px'>Status</th>
                                            <th className='text-end min-w-70px'>Actions</th>
                                            {loading && (
                                                <SpinnerDotted
                                                    size={80}
                                                    thickness={120}
                                                    className='spinner'
                                                    speed={100}
                                                    color='#c8c6c2'
                                                />
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className='fw-bold text-gray-600'>
                                        {currentData.map((Orders) => {
                                            console.log(Orders)
                                            return (
                                                <tr key={Orders.ksOrderId}>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Orders.ksOrderId}
                                                    </td>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Orders.providerName}
                                                    </td>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Orders.partnerName}
                                                    </td>

                                                    {/* <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Orders?.ksOrderReceiveTime?.slice(0, 10)
                                                            ? getDate(Orders?.ksOrderReceiveTime?.slice(0, 10))
                                                            : Orders?.ksOrderReceiveTime?.slice(0, 10)}
                                                    </td> */}

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {(Orders.orderStatusId === 'INPROGRESS' ||
                                                            Orders.orderStatusId === 'INIT' ||
                                                            Orders.orderStatusId === 'IN_PROGRESS') && (
                                                                <div className='badge badge-light-primary fw-bolder'>
                                                                    IN PROGRESS
                                                                </div>
                                                            )}
                                                        {(Orders.orderStatusId === 'CREATED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                CREATED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'ACCEPTED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                ACCEPTED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'DENIED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                DENIED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'UNKNOWN') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                UNKNOWN
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'NOTFOUND') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                NOTFOUND
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'DUPLICATE') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                DUPLICATE
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'ERROR') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                ERROR
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'PROVIDERUPDATED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                PROVIDER UPDATED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'CANCELLED') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                CANCELLED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'FORWARDED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                FORWARDED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'CANCELINPROGRESS') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                CANCELINPROGRESS
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'PARTNERCANCEL') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                PARTNERCANCEL
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'ABANDONED') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                ABANDONED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'COMPLETED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                COMPLETED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'CHECKOUTUPDATED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                CHECKOUTUPDATED
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'LOCATION_NOT_FOUND') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                LOCATION_NOT_FOUND
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'RESCHEDULE') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                RESCHEDULE
                                                            </div>
                                                        )}
                                                        {(Orders.orderStatusId === 'DROPPED') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                DROPPED
                                                            </div>
                                                        )}

                                                    </td>

                                                    <td className='text-end'>
                                                        <div className='menu-item'>
                                                            <Link to={`/jobs/${Orders.jobId}/joborders/${Orders.ksOrderId}/details`}>
                                                                <button
                                                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                                                    data-kt-menu-trigger='click'
                                                                    role='button'
                                                                    data-kt-menu-placement='bottom-end'
                                                                >
                                                                    <span className='svg-icon svg-icon-3'>
                                                                        <svg
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 -4 24 24'
                                                                            id='meteor-icon-kit__solid-eye'
                                                                            fill='none'
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                        >
                                                                            <path
                                                                                fillRule='evenodd'
                                                                                clipRule='evenodd'
                                                                                d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                                                                fill='#758CA3'
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageClick}
                                    previousLabel={'<<'} // Disable the previous button for the first page
                                    nextLabel={'>>'}
                                    breakLabel={'...'}
                                    activeClassName={'active'}
                                    containerClassName={'pagination justify-content-end'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    disabledClassName={'disabled'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </BlockUi>
    )
}

export { OrdersListPage }

