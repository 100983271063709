import axios from "axios";

export default class JobDetailsViewModel{

  jobdetails : any

    constructor()
    {
     this.jobdetails=null;
    }


    async loadJobDetail(jobid:any) {
        try {
          const API_URL = process.env.REACT_APP_API_URL;
          const idToken: string | null = localStorage.getItem("IdToken");
          const response = await axios.get(
            `${API_URL}/v1/jobs/${jobid}/details`,
            {
              headers: {
                Authorization: `${idToken}`,
              },
            }
          );
    
          const data = response.data.response; // Assumes server returns a single item
          this.jobdetails = data;
        } catch (error) {
          console.error("Failed to load jobdetails data:", error);
        }
      }
}