import axios from "axios";
import TransactionsListModel from "./TransactionsListModel";


export default class TransactionsListViewModel {

  Transactions : TransactionsListModel[];
  totalPages: number;
  totalCount: number;

  constructor() {
    this.Transactions = [];
    this.totalPages = 0;
    this.totalCount = 0;
  }

  async loadTransactions(pageNumber: number, pageSize: number, partnerName: string, fromDate: Date | null, toDate: Date | null,brandName : string) {
    try {
      const API_URL = process.env.REACT_APP_API_URL
      const idToken: string | null = localStorage.getItem("IdToken");
      if (fromDate) {
        fromDate.setHours(fromDate.getHours() + 5, fromDate.getMinutes() + 30);
      }
      if (toDate) {
        toDate.setHours(toDate.getHours() + 5, toDate.getMinutes() + 30);
      }
      const response = await axios.get(`${API_URL}/v1/transactions`, {
        params: {
          page: pageNumber,
          pageSize: pageSize,
          partnerName: partnerName,
          fromDate: fromDate,
          toDate: toDate,
          brandName : brandName
        },
        headers: {
          'Authorization': `${idToken}`
        }
      });

      const data = response.data.response.items;
      this.Transactions = data;
      this.totalPages = response.data.response.totalPages
      this.totalCount = response.data.response.totalCount
    } catch (error: any) {
      console.error('Failed to load transactions data:', error);
    }
  }
}