import axios from 'axios'

export default class ReprocessJobBatchesCommand {
  responseErrorMessage: string
  statusCode: number
  constructor() {
    this.responseErrorMessage = ''
    this.statusCode = 0
  }

  async ReRunJobBatches(jobId: any, runErrorBatches: boolean, JobBatchIds: string[]) {
    const API_URL = process.env.REACT_APP_API_URL
    const idToken: string | null = localStorage.getItem('IdToken')
    const config = {
      headers: {
        Authorization: `${idToken}`,
      },
    }
    try {
      const response = await axios.post(
        `${API_URL}/v1/jobs/${jobId}/batches/re-run`,
        {
          JobBatchIds: JobBatchIds,
          ReRunErrorBatches: runErrorBatches
        },
        config
      )
      this.statusCode = response.status
      return response
      const data = response.data.response // Assumes server returns a single item
    } catch (error: any) {
      if (error.response && error.response.data) {
        this.responseErrorMessage = error.response.data.message
        this.statusCode = error.response.status
      } else {
        // Handle the generic error message
        throw new Error('Failed to  reprocess the job')
      }
    }

  }

}