import axios from "axios";
import JobBatchesTransactionsListModel from "./JobBatchesTransactionsListModel";

export default class JobBatchesTransactionsListCommand{

    jobBatchesTransactions : JobBatchesTransactionsListModel[];
    totalPages: number;
    totalCount : number;

    constructor()
    {
      this.jobBatchesTransactions=[];
      this.totalPages=0;
      this.totalCount=0;
    }

    async loadJobBatchesTransactions(pageNumber: any, pageSize: any, jobid:any, jobbatchid:any, status: any,filterid:any) {
      try {
          const API_URL = process.env.REACT_APP_API_URL
          const idToken : string | null = localStorage.getItem("IdToken");
          const response = await axios.get(`${API_URL}/v1/jobs/${jobid}/jobbatches/${jobbatchid}/transactions`, {
              params: {
                  page: pageNumber,
                  pageSize: pageSize,
                  status:status,
                  filterid:filterid
              },
              headers:{
                  'Authorization': `${idToken}`
              }
          });

          const data = response.data.response.items;
          this.jobBatchesTransactions = data;
          this.totalPages = response.data.response.totalPages
          this.totalCount=response.data.response.totalCount
      } catch (error) {
          console.error('Failed to load job batches transactions data:', error);
      }
  }
}