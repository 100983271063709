import axios from "axios";
import { ErrorMessage } from "formik";
import React from "react";
import swal from "sweetalert/typings/core";
import { AddUserModel } from "./AddUserModel";


export default class AddUserViewModel {
  responseErrorMessage : string
    statusCode: number;
    constructor() {
        this.responseErrorMessage = '';
        this.statusCode = 0;
    }

   async CreateUser(user: AddUserModel) {
    const API_URL = process.env.REACT_APP_API_URL
    const idToken : string | null = localStorage.getItem("IdToken");
    const config = {
      headers: { Authorization: `${idToken}` }
    };
   
    
    try {
      const response = await axios.post(`${API_URL}/v1/users`, 
      {firstName : user.FirstName,
       lastName : user.LastName,
       displayname : user.PreferredUsername,
       password:user.Password,
       username:user.UserName,
       email:user.Email,
       mobile: user.Mobile,
       mobileCountryCode : user.MobileCountryCode,
       autoGeneratedPassword : user.AutoGeneratePassword
      },config);
      this.statusCode = response.status;
      return response;
    } catch (error :any) {
      if (error.response && error.response.data) {
        this.responseErrorMessage = error.response.data.message;
        this.statusCode = error.response.status
      } else {
        // Handle the generic error message
        throw new Error("Failed to create user");
      }
    }
  }
  
  }
  


   


