import React, {useEffect, useState} from 'react'

import {Link, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import UserDetailsModel from './UserDetailsModel'
import UserDetailsViewModel from './UserDetailsViewModel'
import swal from 'sweetalert'
import {updateUserDetails} from '../VUpdateUser/UpadteUserModel'
import AdminResetUserPasswordCommand from '../VUpdateUser/AdminResetUserPasswordCommand'
import DeleteUserCommand from '../VDeleteUser/DeleteUserCommand'
import BlockUi from '../../../app/BlockUI/BlockUi'
import {SpinnerDotted} from 'spinners-react'
import { get } from 'https'

let UserDetailsPage: React.FC = () => {
  const adminResetUserPassword = new AdminResetUserPasswordCommand()
  const [loading, setLoading] = useState(false)
  const {Id, cognitoid} = useParams<string>()
  const command = new DeleteUserCommand()
  const [userDetails, setuserDetails] = useState<UserDetailsModel | null>(null)

  const [themeMode, setThemeMode] = useState<string | null>('')
  useEffect(() => {
    setLoading(true)
    setTimeout(async () =>{
        await getUserDetails();
        setLoading(false)
    })
  }, [])

  const getUserDetails = async () =>{
    const viewModel = new UserDetailsViewModel();
    await viewModel.loadData(Id, cognitoid);
    setuserDetails(viewModel.userDetails)
  }

  const handleEdit = () => {
    console.log(userDetails)
    let mobileCountryCode = `+${userDetails?.mobileCountryCode}${userDetails?.mobile}`
    updateUserDetails.FirstName = userDetails?.firstname
    updateUserDetails.Email = userDetails?.email
    updateUserDetails.LastName = userDetails?.lastname
    updateUserDetails.PreferredUsername = userDetails?.preferredUsername
    updateUserDetails.Mobile = mobileCountryCode.slice(-13);
    updateUserDetails.UserName = userDetails?.username
    updateUserDetails.MobileCountryCode = `+${userDetails?.mobileCountryCode}`
  }

  const updatePassword = async () => {
    swal({
      title: 'Are you sure?',
      text: 'Do you want to reset user password?',
      icon: 'warning',
      buttons: ['No', 'Yes'],
      dangerMode: true,
    })
      .then(async (willReset) => {
        if (willReset) {
          setLoading(true)
          await adminResetUserPassword.AdminResetUserPassword(userDetails?.username, Id, cognitoid)
          if (adminResetUserPassword.statusCode == 200) {
            swal('success', 'Password Reset Successfully', 'success')
            setLoading(false)
          }
          if (adminResetUserPassword.statusCode === 400) {
            swal('Reset Password', adminResetUserPassword.responseErrorMessage, 'error')
            setLoading(false)
          }
        } else {
          swal('Ok')
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleDelete = async (
    id: string | undefined,
    cognitoId: string | undefined,
    status: string | undefined
  ) => {
    if (status === 'inactive') {
      swal('Delete User', 'User already deleted', 'error')
    } else {
      swal({
        title: 'Are you sure?',
        text: 'Once deleted,you will not be able to recover this User',
        icon: 'warning',
        buttons: ['No', 'Yes'],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          setLoading(true)
          const response = await command.DeleteUser(id, cognitoId)
          setLoading(false)
          if (command.statusCode == 200) {
            swal('Delete User', {
              icon: 'success',
            })
            setLoading(true)
            setLoading(false)
          } else {
            swal('Delete User', command?.responseErrorMessage, 'error')
          }
        }
      })
    }
  }
  return (
    <BlockUi blocking={loading} message=''>
      <div className='position-absolute top-0 start-50 translate-middle-x mt-20 pt-5'>
        {userDetails?.statusCode == 'active' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '30.0em', borderRadius: 4}}
          >
            Active
          </div>
        )}
        {userDetails?.statusCode == 'inactive' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '30.0em', borderRadius: 4}}
          >
            Inactive
          </div>
        )}
      </div>
      <div className='d-flex flex-column flex-xl-row'>
        {/*begin::Sidebar*/}
        <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
          {/*begin::Card*/}
          <div className='card mb-5 mb-xl-8'>
            {/*begin::Card body*/}
            <div className='card-body pt-15'>
              {/*begin::Summary*/}
              <div className='d-flex flex-center flex-column mb-5'>
                {/*begin::Avatar*/}
                <div className='symbol symbol-100px symbol-circle mb-7'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
                </div>
                {/*end::Avatar*/}
                {/*begin::Name*/}
                <a className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'></a>
                {/*end::Name*/}
              </div>
              {/*end::Summary*/}
              {/*begin::Details toggle*/}
              {loading && (
                <SpinnerDotted
                  size={80}
                  thickness={120}
                  className='spinner'
                  speed={100}
                  color='#c8c6c2'
                />
              )}
              <div className='d-flex flex-stack fs-4 py-3'>
                <a
                  className='fw-bolder text-black rotate collapsible collapsed'
                  href='#kt_customer_view_details'
                  data-bs-toggle='collapse'
                  role='button'
                  aria-expanded='false'
                  aria-controls='kt_customer_view_details'
                >
                  Details
                </a>
              </div>
              {/*end::Details toggle*/}
              <div className='separator separator-dashed my-3'></div>

              {/*begin::Details content*/}
              <div id='kt_customer_view_details' className='collapse show'>
                <div className='py-5 fs-6'>
                  {/*begin::Details item
                                    <div className="fw-bolder mt-5">User Name </div>
                                    <div className="text-gray-600">{userDetails?.username}</div>
                                    begin::Details item*/}
                  <div className='fw-bolder mt-5'>Contact Email</div>
                  <div className='text-gray-600'>{userDetails?.email}</div>
                  {/*begin::Details item*/}
                </div>
              </div>
              {/*end::Details content*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>
        <div className='flex-lg-row-fluid ms-lg-15'>
          {/*begin:::Tabs*/}
          <div className='d-flex justify-content-between'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
              {/*begin:::Tab item*/}
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#device_overview_tab'>
                  Overview
                </a>
              </li>
            </ul> 

            {userDetails?.statusCode === 'active' && (
              <div className='d-flex align-items-end justify-content-end mb-8'>
                <div className='ms-auto'>
                  <div className='me-0'>
                    <Link to={`/update/user/${Id}/cognito/${cognitoid}`}>
                      <button
                        className='btn btn-sm btn-primary me-3'
                        onClick={handleEdit}
                        role='button'
                      >
                        Edit
                      </button>
                    </Link>
                    <button
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                    >
                      <i className='bi bi-three-dots fs-3'></i>{' '}
                    </button>
                    <div
                      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                      data-kt-menu='true'
                    >
                      <div className='menu-item px-3'>
                        <a
                          role='button'
                          onClick={() =>
                            handleDelete(
                              userDetails?.id,
                              userDetails?.cognitoUserId,
                              userDetails?.statusCode
                            )
                          }
                          className='menu-link px-3'
                        >
                          InActive User
                        </a>
                      </div>
                      <div className='menu-item px-3'>
                        <a role='button' onClick={updatePassword} className='menu-link px-3'>
                          Reset Password
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='tab-content' id='myTabContent'>
            {/*begin:::Tab pane*/}

            <div className='tab-pane fade show active' id='device_overview_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Card header*/}
                <div className='card-header border-0'></div>

                <div className='card-body pt-0'>
                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>First Name</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={userDetails?.firstname}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Last Name</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={userDetails?.lastname}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Username</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={userDetails?.username}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Created Date</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            value={userDetails?.createTime?.slice(0, 10)}
                            className='form-control form-control-solid'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  )
}
export {UserDetailsPage}
