import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { SpinnerDotted } from "spinners-react"
import { toAbsoluteUrl } from "../../../_metronic/helpers"
import BlockUi from "../../../app/BlockUI/BlockUi"
import JobOrdersDetailsCommand from "./JobOrdersDetailsCommand"
import JobOrdersDetailsModel from "./JobOrdersDetailsModel"

let JobOrderDetailsPage: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const { jobid, ksorderid } = useParams<string>()
  const [orederdetails, setOrderDetails] = useState<JobOrdersDetailsModel | null>(null);


  const getOrderDetails = async () => {
    const command = new JobOrdersDetailsCommand();
    await command.loadJobOrderDetail(jobid, ksorderid);
    setOrderDetails(command.joborderdetails);
    console.log(orederdetails);
  }

  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      await getOrderDetails();
      setLoading(false)
    })
  }, []);

  function formatDateTimeString(dateTimeStr: any) {
    const dateTime = new Date(dateTimeStr)
    const formattedDateTime = dateTime.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
    return formattedDateTime
  }

  return ( 
    <BlockUi blocking={loading} message=''>
      <div className='position-absolute top-0 start-50 translate-middle-x mt-20 pt-5'>
      {(orederdetails?.orderStatusId == 'INIT'|| orederdetails?.orderStatusId=='INPROGRESS' || orederdetails?.orderStatusId=='IN_PROGRESS') && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            IN PROGRESS
          </div>
        )}
         {orederdetails?.orderStatusId == 'CREATED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            CREATED
          </div>
        )}
         {orederdetails?.orderStatusId == 'ACCEPTED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            ACCEPTED
          </div>
        )}
         {orederdetails?.orderStatusId == 'DENIED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            DENIED
          </div>
        )}
         {orederdetails?.orderStatusId == 'UNKNOWN' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            UNKNOWN
          </div>
        )}
         {orederdetails?.orderStatusId == 'NOTFOUND' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            NOTFOUND
          </div>
        )}
         {orederdetails?.orderStatusId == 'DUPLICATE' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            DUPLICATE
          </div>
        )}
         {orederdetails?.orderStatusId == 'ERROR' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '35.0em', borderRadius: 4}}
          >
            ERROR
          </div>
        )}
         {orederdetails?.orderStatusId == 'PROVIDERUPDATED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '28.0em', borderRadius: 4}}
          >
            PROVIDER UPDATED
          </div>
        )}
         {orederdetails?.orderStatusId == 'CANCELLED' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            CANCELLED
          </div>
        )}
         {orederdetails?.orderStatusId == 'FORWARDED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            FORWARDED
          </div>
        )}
         {orederdetails?.orderStatusId == 'CANCELINPROGRESS' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            CANCELINPROGRESS
          </div>
        )}
         {orederdetails?.orderStatusId == 'PARTNERCANCEL' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            PARTNERCANCEL
          </div>
        )}
         {orederdetails?.orderStatusId == 'ABANDONED' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            ABANDONED
          </div>
        )}
         {orederdetails?.orderStatusId == 'COMPLETED' && (
          <div
            className='badge badge-success fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            COMPLETED
          </div>
        )}
         {orederdetails?.orderStatusId == 'CHECKOUTUPDATED' && (
          <div
            className='badge badge-primary fw-bolder'
            style={{marginRight: '27.0em', borderRadius: 4}}
          >
            CHECKOUTUPDATED
          </div>
        )}
         {orederdetails?.orderStatusId == 'LOCATION_NOT_FOUND' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '26.0em', borderRadius: 4}}
          >
            LOCATION_NOT_FOUND
          </div>
        )}
         {orederdetails?.orderStatusId == 'RESCHEDULE' && (
          <div
            className='badge badge-warning fw-bolder'
            style={{marginRight: '33.0em', borderRadius: 4}}
          >
            RESCHEDULE
          </div>
        )}
         {orederdetails?.orderStatusId == 'DROPPED' && (
          <div
            className='badge badge-danger fw-bolder'
            style={{marginRight: '34.0em', borderRadius: 4}}
          >
            DROPPED
          </div>
        )}
      </div>
      <div className='d-flex flex-column flex-xl-row'>
        {/*begin::Sidebar*/}
        <div className='flex-column flex-lg-row-auto w-100 w-xl-350px mb-10'>
          {/*begin::Card*/}
          <div className='card mb-5 mb-xl-8'>
            {/*begin::Card body*/}
            <div className='card-body pt-15'>
              {/*begin::Summary*/}
              <div className='d-flex flex-center flex-column mb-5'>
                {/*begin::Avatar*/}
                <div className='symbol symbol-100px symbol-rectangle mb-7'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/avatars/order-food.png')} />
                </div>
                {/*end::Avatar*/}
                {/*begin::Name*/}
                <a className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-1'></a>
                {/*end::Name*/}
              </div>
              {/*end::Summary*/}
              {/*begin::Details toggle*/}
              {loading && (
                <SpinnerDotted
                  size={80}
                  thickness={120}
                  className='spinner'
                  speed={100}
                  color='#c8c6c2'
                />
              )}
              <div className='d-flex flex-stack fs-4 py-3'>
                <a
                  className='fw-bolder text-black rotate collapsible collapsed'
                  href='#kt_customer_view_details'
                  data-bs-toggle='collapse'
                  role='button'
                  aria-expanded='false'
                  aria-controls='kt_customer_view_details'
                >
                  Details
                </a>
              </div>
              {/*end::Details toggle*/}
              <div className='separator separator-dashed my-3'></div>

              {/*begin::Details content*/}
              <div id='kt_customer_view_details' className='collapse show'>
                <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>KS Order Id</div>
                  <div className='text-gray-600'>{orederdetails?.ksOrderId}</div>
                </div> 
                <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>Provider</div>
                  <div className='text-gray-600'>{orederdetails?.providerName}</div>
                </div>
               <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>Partner</div>
                  <div className='text-gray-600'>
                    {orederdetails?.partnerName},
                    <span style={{ marginLeft: '5px' }}>{orederdetails?.brandName}</span>
                  </div>

                </div> 
                {/* <div className='py-3 fs-6'>
                  <div className='fw-bolder mt-5'>Brand</div>
                  <div className='text-gray-600'>{orederdetails?.brandName}</div>
                </div>  */}
              </div>
              {/*end::Details content*/}
            </div>
            {/*end::Card body*/}
          </div>
        </div>
        <div className='flex-lg-row-fluid ms-lg-15'>
          {/*begin:::Tabs*/}
          <div className='d-flex justify-content-between'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8'>
              {/*begin:::Tab item*/}
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#device_overview_tab'>
                  Overview
                </a>
              </li>
            </ul>
            <div className='d-flex align-items-end justify-content-end mb-8'>
              <div className='ms-auto'>
                <div className='me-0'>
                  {/* <button
                           className='btn btn-sm btn-primary me-3'
                           role='button'
                         >
                           Edit
                         </button> */}
                  {/* <button
                         className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                         data-kt-menu-trigger='click'
                         data-kt-menu-placement='bottom-end'
                         style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                       >
                         <i className='bi bi-three-dots fs-3'></i>{' '}
                       </button> */}
                  <div
                    className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px py-3'
                    data-kt-menu='true'
                  >
                    <div className='menu-item px-3'>
                      <a
                        role='button'
                        className='menu-link px-3'
                      >
                        InActive User
                      </a>
                    </div>
                    <div className='menu-item px-3'>
                      <a role='button' className='menu-link px-3'>
                        Reset Password
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='tab-content' id='myTabContent'>
            {/*begin:::Tab pane*/}

            <div className='tab-pane fade show active' id='device_overview_tab' role='tabpanel'>
              {/*begin::Card*/}
              <div className='card pt-4 mb-6 mb-xl-9'>
                {/*begin::Card header*/}
                <div className=''><h3 className='card-title fs-6 text-gray-700 pt-2'></h3></div>

                <div className='card-body pt-0'>
                  <div className='mb-0'>
                    <div className='row gx-10 mb-5'>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider Order Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.providerOrderId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner Order Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.partnerOrderId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Partner Store Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.partnerStoreId}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider Store Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.providerStoreId}
                          />
                        </div>
                      </div>
                       <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Receive Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              orederdetails?.ksOrderReceiveTime?.slice(0, 16).replace("T", " ")
                            }
                          />
                        </div>
                      </div> 
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Provider Order Time</label>
                         <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              orederdetails?.providerOrderTimestamp?.slice(0, 16).replace("T", " ")
                            }
                          />
                        </div> 
                      </div>
                      {/* <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Order Status</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.orderStatusId === 'INIT' ? 'In Progress' : orederdetails?.orderStatusId}
                          />
                        </div>
                      </div>

                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Source Id</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.sourceId}
                          />
                        </div>
                      </div> */}
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Last Modified Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              orederdetails?.lastModifiedTime?.slice(0, 16).replace('T', ' ').startsWith('9999') ||
                              orederdetails?.lastModifiedTime?.slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : orederdetails?.lastModifiedTime?.slice(0, 16).replace('T', ' ')
                            }
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>last Activity Time</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={
                              orederdetails?.lastActivityTime?.slice(0, 16).replace('T', ' ').startsWith('9999') ||
                              orederdetails?.lastActivityTime?.slice(0, 16).replace('T', ' ').startsWith('2000')
                                ? ''
                                : orederdetails?.lastActivityTime?.slice(0, 16).replace('T', ' ')
                            }
                          />
                        </div>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mb-5 mt-5 '>
                        <h2 className='mb-0 fs-5 fw-bold'>Error</h2>
                        <hr className='flex-grow-1 ms-3' />
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Source</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.errorSource}
                          />
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Explanation</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.errorExplanation}
                          />
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <label className='form-label fs-6 text-gray-700 mb-3'>Message</label>
                        <div className='mb-5'>
                          <input
                            disabled
                            className='form-control form-control-solid'
                            value={orederdetails?.errorMessage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BlockUi>
  )
}
export { JobOrderDetailsPage }

