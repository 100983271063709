import axios from "axios";
import BackFillListModel from "./BackFillListModel";

export default class BackFillListViewModel {
    backfillinds: BackFillListModel[];
    totalPages: number;
    constructor() {
        this.backfillinds = [];
        this.totalPages = 0;
    }

    async loadData(pageNumber: number, pageSize: number, brand: string, partnerName: string, entityName: string) {
        try {
            if (partnerName === '-Select Partner-') {
                partnerName = ''
                brand = ''
            }
 
            if (brand === '-Select Brand-')
                brand = ''
                 
            const API_URL = process.env.REACT_APP_API_URL
            const idToken: string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/backfill`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    brand: brand.replace(' ', ''),
                    partnerName: partnerName.replace(' ', ''),
                    entityType: entityName
                },
                headers: {
                    'Authorization': `${idToken}`
                }
            });

            const data = response.data.response.items;
            this.backfillinds = data;
            this.totalPages = response.data.response.totalPages
        } catch (error) {
            console.error('Failed to load backfill data:', error);
        }
    }
}