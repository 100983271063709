import axios from "axios";
import JobOrdersModel from "./JobOrdersModel";

export default class JobBatchesListviewModel{
      jobOrders : JobOrdersModel[];
      totalPages: number;
      totalCount : number;

      constructor()
      {
        this.jobOrders=[];
        this.totalPages=0;
        this.totalCount=0;
      }

      async loadJobOrders(pageNumber: number, pageSize: number, jobid:any, status:any , filterid:any) {
        try {
            const API_URL = process.env.REACT_APP_API_URL
            const idToken : string | null = localStorage.getItem("IdToken");
            const response = await axios.get(`${API_URL}/v1/jobs/${jobid}/orders`, {
                params: {
                    page: pageNumber,
                    pageSize: pageSize,
                    status : status,
                    filterid:filterid
                },
                headers:{
                    'Authorization': `${idToken}`
                }
            });

            const data = response.data.response.items;
            this.jobOrders = data;
            this.totalPages = response.data.response.totalPages
            this.totalCount=response.data.response.totalCount
        } catch (error) {
            console.error('Failed to load job batches data:', error);
        }
    }
}