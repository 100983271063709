import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DisableSidebar, PageTitle, PageLink } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { SampleDash } from '../../Dashboard/OverviewDashboard'
import { FileDashboard } from '../../Dashboard/FileDashboard'
import { ProvidersDashboard } from '../../Dashboard/ProviderDashboard'
import { PartnerDashboard } from '../../Dashboard/PartnerDashboard'
import { SupportDashboard } from '../../Dashboard/SupportDashboard'
import { StoresDashboard } from '../../Dashboard/StoresDashboard'
import { InfraDashboard } from '../../Dashboard/InfraDashboard'

import { VStoresList } from '../../Components/MStores/VStoresList'

import { VAddStores } from '../../Components/MStores/VAddStores'
import { FileImportsList } from '../../Components/MFileImports/FileImportsList'
import { PartnerListPage } from '../../Components/MPartners/VPartnerList/PartnerListpage';
import { ProviderListPage } from '../../Components/MProviders/VProviderList/ProviderListPage'
import { ProviderDetailsPage } from '../../Components/MProviders/VProviderDetails/ProviderDetailsPage'
import { UsersListPagination } from '../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import { UserListPage } from '../../Components/MUsers/VUsersList/UsersListPage'
import { AddUserPage } from '../../Components/MUsers/VAddUser/AddUserPage'
import { UserDetailsPage } from '../../Components/MUsers/VUsersDetails/UserDetailsPage'
import { PasswordChangePage } from '../../Components/MUsers/VUserPasswordChange/PasswordChangePage'
import { UpdateUserPage } from '../../Components/MUsers/VUpdateUser/UpdateUserPage'
import AddUserViewModel from '../../Components/MUsers/VAddUser/AddUserViewModel'
import { AddBackFillingPage } from '../../Components/MBackFillings/VAddBackFilling/AddBackFillingPage'
import { BackFillDetailsPage } from '../../Components/MBackFillings/VBackFillDetails/BackFillDetailsPage'
import { BackFillListPage } from '../../Components/MBackFillings/VBackFillingList/BackFillListPage'
import { UserProfilePage } from '../../Components/MUsers/VUserMyProfile/UserProfilePage'
import { JobsListPage } from '../../Components/MJobs/VJobList/JobListPage'
import { JobDetailsPage } from '../../Components/MJobs/VJobDetails/JobDetailsPage'
import { JobBatchesDetailsPage } from '../../Components/MJobs/VjobBatchesDetails/JobBatchesDetailsPage'
import { JobOrderDetailsPage } from '../../Components/MJobs/VJobOrdersDeatils/JobOrderDetailsPage'
import { OrdersListPage } from '../../Components/MOrder/VOrderList/OrderListPage'
import { PartnersPaginationPage } from '../../Components/MPartnersOps/VPartnersListPagination/PartnersPaginationPage'
import { PartnerDetailsPage } from '../../Components/MPartnersOps/VPartnerDetails/PartnerDetailsPage'
import { StoreDetailsPage } from '../../Components/MStoreOps/VStoreDetails/StoreDetailsPage'
import { TransactionsListPage } from '../../Components/MTransactions/VTransactionList/TransactionsListPage'
import { TransactionDetailsPage } from '../../Components/MTransactions/VTransactionDetails/TransactionDetailsPage'
import { GetAllStoresPaginationPage } from '../../Components/MStoreOps/VGetAllStores/GetAllStoresPaginationPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  const profileBreadCrumbs: Array<PageLink> = [

  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard/fileimport' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/overview'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Overview</PageTitle>
              <SampleDash />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/partner'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard-partner</PageTitle>
              <PartnerDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/provider'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard-Provider</PageTitle>
              <ProvidersDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/stores'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard-Stores</PageTitle>
              <StoresDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/fileimport'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard-Files</PageTitle>
              <FileDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/infra'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard Infra</PageTitle>
              <InfraDashboard />
            </SuspensedView>
          }
        />
        <Route
          path='/dashboard/support'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Dashboard Support</PageTitle>
              <SupportDashboard />
            </SuspensedView>
          }
        />  

       <Route
          path='/providers/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Providers List</PageTitle>
              <ProviderListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/order/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Orders</PageTitle>
              <OrdersListPage />
            </SuspensedView>
          }
        />

        <Route
          path='/users/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
              <UserListPage />
            </SuspensedView>
          }
        />
       <Route
          path='/users/new'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Add User</PageTitle>
              <AddUserPage />
            </SuspensedView>
          }
        />
       <Route
          path='/provider/:Id/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Provider Details</PageTitle>
              <ProviderDetailsPage  />
            </SuspensedView>
          }
        />

        <Route
          path='/users/:Id/cognito/:cognitoid/view'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>User Details</PageTitle>
              <UserDetailsPage  />
            </SuspensedView>
          }
        />

       <Route
          path='/users/:cognitoid/myprofile'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>My Profile</PageTitle>
              <UserProfilePage  />
            </SuspensedView>
          }
        />

       <Route 
          path='/stores/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Stores List</PageTitle>
              <VStoresList />
            </SuspensedView>
          }
        />

       <Route 
          path='/add/stores'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Add Stores </PageTitle>
              <VAddStores className='' />
            </SuspensedView>
          }
        />

      <Route 
          path='/file/imports'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>File Imports </PageTitle>
              <FileImportsList  />
            </SuspensedView>
          }
        />
         <Route 
          path='/partners/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Partner List</PageTitle>
              <PartnerListPage />
            </SuspensedView>
          }
        />
         <Route 
          path='/reset/password'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Change Password</PageTitle>
              <PasswordChangePage />
            </SuspensedView>
          }
        />
    <Route 
          path='/update/user/:Id/cognito/:cognitoid'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Edit User</PageTitle>
              <UpdateUserPage />
            </SuspensedView>
          }
        />
<Route
          path='/backfill/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Backfill</PageTitle>
              <BackFillListPage />
            </SuspensedView>
          }
        />
        <Route
          path='/backfill/new'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Add Backfill</PageTitle>
              <AddBackFillingPage />
            </SuspensedView>
          }
        />
     <Route  
          path='/baclfill/:id/details/:entityName'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>BackFill Details</PageTitle>
              <BackFillDetailsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/jobs/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Jobs</PageTitle>
              <JobsListPage />
            </SuspensedView>
          }
        />
         <Route  
          path='/jobs/:jobId/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Job Details</PageTitle>
              <JobDetailsPage />
            </SuspensedView>
          }
        />
         <Route  
          path='/jobs/:jobid/jobbatches/:jobbatchid/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Batch Details</PageTitle>
              <JobBatchesDetailsPage />
            </SuspensedView>
          }
        />
          <Route  
          path='/jobs/:jobid/joborders/:ksorderid/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Orders Details</PageTitle>
              <JobOrderDetailsPage />
            </SuspensedView>
          }
        />

       <Route    
          path='/partner/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Partners</PageTitle>
              <PartnersPaginationPage />
            </SuspensedView>
          }
        />
         <Route    
          path='/partner/:id/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Partner Details</PageTitle>
              <PartnerDetailsPage />
            </SuspensedView>
          }
        />
           <Route    
          path='/stores/:id/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Store Details</PageTitle>
              <StoreDetailsPage />
            </SuspensedView>
          }
        />
         <Route  
          path='/transaction/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Transactions</PageTitle>
              <TransactionsListPage />
            </SuspensedView>
          }
        />
         <Route  
          path='/jobs/:jobid/transactions/:ksid/details'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Details</PageTitle>
              <TransactionDetailsPage />
            </SuspensedView>
          }
        />
          <Route  
          path='patners/stores/list'
          element={
            <SuspensedView>
              <PageTitle breadcrumbs={profileBreadCrumbs}>Stores</PageTitle>
              <GetAllStoresPaginationPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      <DisableSidebar>{children}</DisableSidebar>
    </Suspense>
  )
}

export { PrivateRoutes }
