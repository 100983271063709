import React from 'react'
import { PageLink } from '../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget1,
  MixedWidget13,
  MixedWidget15,
  MixedWidget14,
  TablesWidget3,

} from '../_metronic/partials/widgets'

import { useHref } from 'react-router-dom';
import { MixedWidget16 } from '../_metronic/partials/widgets/mixed/MixedWidget16';
import { TablesWidget14 } from '../_metronic/partials/widgets/tables/TableWidget14';
import { TablesWidget15 } from '../_metronic/partials/widgets/tables/TableWidget15';
import { VProviderList } from '../Components/DashboardProvider/VProviderList';
import { DashbordBackfilling } from '../Components/DashboardProvider/DashbordBackfilling';
import { DashboardJobs } from '../Components/DashboardProvider/DashboardJobs';

    


export function SampleDash() {
    const href = useHref({ pathname: '/path-to-link' });
    const handle = useHref({ pathname: '/path-to-link' });
  return (
    <div className='row g-5 g-xxl-8'>
      
      <div className='col-xl-6'>
        <MixedWidget13 className='card-xl-stretch mb-xl-10' backGroundColor='#F7D9E3' chartHeight={''} />
      </div>
      {/* <div className='col-xl-4'>
        <MixedWidget14 className='card-xl-stretch mb-xl-10' backGroundColor='#CBF0F4' chartHeight={''} />
      </div> */}
      {/* <div className='col-xl-3'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#F3D6EF' chartHeight={''} />
      </div> */}
      <div className='col-xl-6'>
        <MixedWidget16 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div>
      <div className='col-xl-12'>
        <DashboardJobs />
      </div>
      
    </div>
  )
}
