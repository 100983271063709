import ProviderDetailsModel from "./ProviderDetailsModel";


export class ProviderDetailsViewModel{
    provider : ProviderDetailsModel;

    constructor(){
        this.provider = new ProviderDetailsModel('','','','','') ;
    }

    async getProvider(id : any){

        const providerlist : ProviderDetailsModel[] = [
            new ProviderDetailsModel('1','Zomoto ','10/2/23','Active','Zomoto@gamil.com'),
            new ProviderDetailsModel('2','DoorDash ','21/1/23','InActive','DoorDash@gamil.com'),
            new ProviderDetailsModel('3','Cloud Kitchen ','12/2/23','Active','CloudKitchen@gmail.com'),
            new ProviderDetailsModel('4','Wisely ','12/1/23','InActive','Wisely@gmail.com'),
            new ProviderDetailsModel('5','Uber Eats ','11/2/23','InActive','Ubereats@gamil.com'),
        ];
        
        
        let item = providerlist.find(x=>x.Id === id.Id);

        return item;
    }

    
    
}

   


