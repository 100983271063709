import ReactPaginate from "react-paginate"
import TransactionsListModel from "./TransactionsListModel"
import { useEffect, useState } from "react"
import TransactionsListViewModel from "./TransactionsListViewModel"
import PartnersModel from "../../MBackFillings/VPartners/PartnersModel"
import PartnersViewModel from "../../MBackFillings/VPartners/PartnersViewModel"
import BlockUi from "../../../app/BlockUI/BlockUi"
import { SpinnerDotted } from "spinners-react"
import { Link } from "react-router-dom"
import DatePicker from 'react-datepicker'
import BrandsModel from "../../MBackFillings/VBrands/BarndsModel"
import BrandsViewmodel from "../../MBackFillings/VBrands/BrandsViewmodel"


let TransactionsListPage: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const viewModel = new TransactionsListViewModel()
    const [currentPage, setCurrentPage] = useState(1)
    const [currentData, setCurrentData] = useState<TransactionsListModel[]>([])
    const [partners, setPartners] = useState<PartnersModel[]>([])
    const [partnerName, setPartnerName] = useState<string>('')
    const [brandName, setBrandName] = useState<string>('')
    const [brands, setBrands] = useState<BrandsModel[]>([])
    const brandViewModel = new BrandsViewmodel()
    const [pageCount, setPageCount] = useState(0)
    const rowsPerPage = 5
    const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);
    const partnersViewmodel = new PartnersViewModel()
    const [toDate, setTodate] = useState<Date>(new Date())
    const maxDate = new Date(toDate.getTime() + 24 * 60 * 60 * 1000)

    const currentDate = new Date()
    const minDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 2,
        currentDate.getDate()
    )
    const dateBefore7Days = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 7
    )
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
        dateBefore7Days,
        new Date(), 
    ])
    const [startDate, endDate] = dateRange

    const [dateEmpty, setdateEmpty] = useState(false)

    const handlePageClick = ({ selected }: { selected: number }) => {
        setCurrentPage(selected + 1)
    }
    const [TransactionCount, setTransactionCount] = useState(0);
    const [totalTransactionCount, setTotalTransactionCount] = useState(0);


    const fetchTransactionData = async (
        partnerName: string,
        page: number,
        fromDate: Date | null,
        toDate: Date | null,
        rowsPerPage: number,
        brandName: string,
    ) => {
        setLoading(true);
        const adjustedFromDate = fromDate ? new Date(fromDate.getTime() - 24 * 60 * 60 * 1000) : null;
        const adjustedToDate = toDate ? new Date(toDate.getTime() + 24 * 60 * 60 * 1000) : null;

        // Load jobs for the current page and retrieve the total count
        await viewModel.loadTransactions(
            page,
            rowsPerPage,
            partnerName,
            adjustedFromDate,
            adjustedToDate,
            brandName

        );


        setCurrentData(viewModel.Transactions);
        setPageCount(viewModel.totalPages);
        setTransactionCount(viewModel.totalCount)
        console.log(viewModel.totalCount);
        setLoading(false);

        // Calculate the total count of jobs across all pages
        const totalCount = viewModel.totalPages * rowsPerPage;
        setTotalTransactionCount(totalCount);
    }

    const getpartners = async () => {
        await partnersViewmodel.LoadPartners()
        setPartners(partnersViewmodel.partners)
    }

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true)
            getpartners()
            await fetchTransactionData(partnerName, currentPage, startDate, endDate, selectedRowsPerPage,brandName)
            setLoading(false)
        }
        fetchTransactions()
    }, [currentPage, partnerName, selectedRowsPerPage])



    const getDate = (dateStr: string) => {
        const date = new Date(dateStr)
        const formattedDate = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
        return formattedDate
    }
    
    const fetchBrandData = async (
        partnerName: string
      ) => {
        setLoading(true);
        // Load jobs for the current page and retrieve the total count
        await brandViewModel.LoadBrands(
          partnerName
        );
        setBrands(brandViewModel.brands);
        setLoading(false);
      }

    return (
        <BlockUi blocking={loading} message=''>
            <div className='d-flex flex-column flex-lg-row'>
                {
                    <div className='flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10'>
                        <div className='card'>
                            <div className='card-header border-0 pt-6'>
                                <div className='card-title'>
                                    <div className='d-none d-flex align-items-center position-relative my-1'>
                                        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='24'
                                                height='24'
                                                viewBox='0 0 24 24'
                                                fill='none'
                                            >
                                                <rect
                                                    opacity='0.5'
                                                    x='17.0365'
                                                    y='15.1223'
                                                    width='8.15546'
                                                    height='2'
                                                    rx='1'
                                                    transform='rotate(45 17.0365 15.1223)'
                                                    fill='black'
                                                />
                                                <path
                                                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                                    fill='black'
                                                />
                                            </svg>
                                        </span>
                                        <input
                                            type='text'
                                            data-kt-customer-table-filter='search'
                                            className='form-control form-control-solid w-250px ps-15'
                                            placeholder='Search Client'
                                        />
                                    </div>
                                </div>
                                <div className='d-flex flex-stack flex-wrap gap-2'>
                                    <div className='d-flex flex-stack flex-wrap gap-2'>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <select
                                                className='form-select clear form-select-solid'
                                                onChange={async (event) => {
                                                    setCurrentPage(1)
                                                    const selectedPartnerName = event.target.value
                                                    setPartnerName(selectedPartnerName)
                                                    setBrandName(''); 
                                                    setBrands([]);
                                                    await fetchBrandData(event.target.value);
                                                    await fetchTransactionData(selectedPartnerName, 1, startDate, endDate, selectedRowsPerPage,'')
                                                }}
                                            >
                                                <option value=''>-Select Partner-</option>
                                                {partners.map((x) => (
                                                    <option value={x.name}>{x.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <select
                                                className='form-select clear form-select-solid'
                                                onChange={async (event) => {
                                                    setCurrentPage(1)
                                                    const selectedBrandName = event.target.value
                                                    setBrandName(selectedBrandName)
                                                    await fetchTransactionData(partnerName, 1, startDate, endDate, selectedRowsPerPage,event.target.value)

                                                }}
                                            >
                                                <option value=''>-Select Brand-</option>
                                                {brands.map((x) => (
                                                    <option value={x.name}>{x.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center fw-bold'>
                                            <DatePicker
                                                className='form-control clear form-control-solid fw-bold'
                                                calendarClassName='custom-calendar'
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={async (update) => {
                                                    if (update[0] == null || update[1] == null) {
                                                        setdateEmpty(true)
                                                    } else
                                                        setdateEmpty(false)
                                                    setDateRange(update)
                                                    setLoading(true)
                                                    if (dateEmpty) {
                                                        await fetchTransactionData(partnerName, currentPage, update[0], update[1], selectedRowsPerPage,brandName)
                                                    }
                                                    setLoading(false)
                                                }}
                                                isClearable={true}
                                                placeholderText='Select From To Dates'
                                                maxDate={maxDate}
                                                minDate={minDate}
                                            />
                                        </div>
                                        {dateEmpty && (
                                            <label className="text-danger">From and To date must not be empty</label>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-body pt-0'>
                                <table className='table align-middle table-row-dashed fs-6 gy-5' id='client_table'>
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bolder fs-7 text-camelcasel gs-0'>
                                            <th className='min-w-125px'>ID
                                            </th>
                                            <th className='min-w-125px'>Provider</th>
                                            <th className='min-w-125px'>Partner</th>
                                            <th className='min-w-125px'>Brand</th>
                                            {/* <th className='min-w-125px'>Recevied Time</th> */}
                                            <th className='min-w-125px'>Status</th>
                                            <th className='text-end min-w-70px'>Actions</th>
                                            {loading && (
                                                <SpinnerDotted
                                                    size={80}
                                                    thickness={120}
                                                    className='spinner'
                                                    speed={100}
                                                    color='#c8c6c2'
                                                />
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className='fw-bold text-gray-600'>
                                        {currentData.map((Transaction) => {
                                            console.log(Transaction)
                                            return (
                                                <tr key={Transaction.ksId}>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Transaction.ksId}
                                                    </td>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Transaction.providerName}
                                                    </td>

                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Transaction.partnerName}
                                                    </td>
                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {Transaction.brandName ? Transaction.brandName : 'N/A'}
                                                    </td>
                                                    <td className='fs-6 text-gray-700 text-hover-primary'>
                                                        {(Transaction.statusId === 'INPROGRESS' ||
                                                            Transaction.statusId === 'INIT' ||
                                                            Transaction.statusId === 'IN_PROGRESS') && (
                                                                <div className='badge badge-light-primary fw-bolder'>
                                                                    IN PROGRESS
                                                                </div>
                                                            )}
                                                        {(Transaction.statusId === 'CREATED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                CREATED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'ACCEPTED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                ACCEPTED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'DENIED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                DENIED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'UNKNOWN') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                UNKNOWN
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'NOTFOUND') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                NOTFOUND
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'DUPLICATE') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                DUPLICATE
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'ERROR') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                ERROR
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'PROVIDERUPDATED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                PROVIDER UPDATED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'CANCELLED') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                CANCELLED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'FORWARDED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                FORWARDED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'CANCELINPROGRESS') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                CANCELINPROGRESS
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'PARTNERCANCEL') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                PARTNERCANCEL
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'ABANDONED') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                ABANDONED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'COMPLETED') && (
                                                            <div className='badge badge-light-success fw-bolder'>
                                                                COMPLETED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'CHECKOUTUPDATED') && (
                                                            <div className='badge badge-light-primary fw-bolder'>
                                                                CHECKOUTUPDATED
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'LOCATION_NOT_FOUND') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                LOCATION_NOT_FOUND
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'RESCHEDULE') && (
                                                            <div className='badge badge-light-warning fw-bolder'>
                                                                RESCHEDULE
                                                            </div>
                                                        )}
                                                        {(Transaction.statusId === 'DROPPED') && (
                                                            <div className='badge badge-light-danger fw-bolder'>
                                                                DROPPED
                                                            </div>
                                                        )}

                                                    </td>

                                                    <td className='text-end'>
                                                        <div className='menu-item'>
                                                            <Link to={`/jobs/${Transaction.jobId}/transactions/${Transaction.ksId}/details`}>
                                                                <button
                                                                    className='btn btn-icon btn-bg-light  btn-active-color-primary btn-sm me-1'
                                                                    data-kt-menu-trigger='click'
                                                                    role='button'
                                                                    data-kt-menu-placement='bottom-end'
                                                                >
                                                                    <span className='svg-icon svg-icon-3'>
                                                                        <svg
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 -4 24 24'
                                                                            id='meteor-icon-kit__solid-eye'
                                                                            fill='none'
                                                                            xmlns='http://www.w3.org/2000/svg'
                                                                        >
                                                                            <path
                                                                                fillRule='evenodd'
                                                                                clipRule='evenodd'
                                                                                d='M13.2891 5.29237C13.1078 5.47338 12.9957 5.72359 12.9957 6C12.9957 6.5523 13.4434 7 13.9957 7C14.2721 7 14.5223 6.8879 14.7033 6.7066C14.8907 7.0982 14.9957 7.5369 14.9957 8C14.9957 9.6569 13.6525 11 11.9957 11C10.3388 11 8.99568 9.6569 8.99568 8C8.99568 6.3431 10.3388 5 11.9957 5C12.4588 5 12.8975 5.10495 13.2891 5.29237zM11.9967 16C7.69743 16 3.82272 13.7042 0.407613 9.2101C-0.135884 8.4948 -0.135868 7.505 0.407642 6.7899C3.82274 2.29581 7.69744 0 11.9967 0C16.2961 0 20.1708 2.29582 23.5859 6.7899C24.1294 7.5052 24.1294 8.495 23.5859 9.2101C20.1708 13.7042 16.2961 16 11.9967 16zM11.9957 13C14.7571 13 16.9957 10.7614 16.9957 8C16.9957 5.23858 14.7571 3 11.9957 3C9.23425 3 6.99568 5.23858 6.99568 8C6.99568 10.7614 9.23425 13 11.9957 13z'
                                                                                fill='#758CA3'
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </td>

                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageClick}
                                    previousLabel={'<<'} // Disable the previous button for the first page
                                    nextLabel={'>>'}
                                    breakLabel={'...'}
                                    activeClassName={'active'}
                                    containerClassName={'pagination justify-content-end'}
                                    pageClassName={'page-item'}
                                    pageLinkClassName={'page-link'}
                                    disabledClassName={'disabled'}
                                    previousClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextClassName={'page-item'}
                                    nextLinkClassName={'page-link'}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </BlockUi>
    )
}

export { TransactionsListPage }

