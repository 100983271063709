/* eslint-disable jsx-a11y/anchor-is-valid */
import { AmplifyUser } from '@aws-amplify/ui';
import { Authenticator, PhoneNumberField } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { storeTokens, useAuth } from './Auth';
import { AuthModel, UserModel } from './LogInModel';

export function Login() {
  const {saveAuth, setCurrentUser} = useAuth()
  const[user,setUser] = useState<AmplifyUser>();
  const navigate = useNavigate();

  useEffect(() => {
    checkUser();
  },[user]);

  async function checkUser() {
    try{
   const user = await Auth.currentAuthenticatedUser();
   if(user != null){
    console.log(user)
    const auth : AuthModel={
      Access_Token : user.signInUserSession.accessToken.jwtToken
    };
    const userModel : UserModel ={
      Username :  user.username,
      Email : '',
      sub:'user.attributes.sub'
    };
    storeTokens(user.signInUserSession.idToken.jwtToken, user.signInUserSession.accessToken.jwtToken);
    saveAuth(auth);
    setCurrentUser(userModel);
    localStorage.setItem('username', user.attributes.preferred_username)
    localStorage.setItem('cognitoId', user.attributes.sub)
    navigate('/dashboard/overview');
   }else{
    saveAuth(undefined)
   }
  }catch(err){
    console.log(err)
  }
       
  }
  return (
    <div className='container'>
    <Authenticator  loginMechanisms={['username']} hideSignUp={true} signUpAttributes={['email' , 'phone_number']}    socialProviders={['google']}>
      {({ signOut, user }) => {
        setUser(user);
        return (
          <></>
        )
      }}
    </Authenticator>
    </div>
  )
}
