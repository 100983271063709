import axios from "axios";
import { config } from "webpack";
import PasswordChangeModel from "./PasswordChangeModel";

export default class PasswordChangeCommand {
    
     responseErrorMessage : string
     statusCode: number;
    constructor() {
        this.responseErrorMessage = '';
        this.statusCode = 0;
    }

    async PasswordChange(passwordchange: PasswordChangeModel) {
        
            const API_URL = process.env.REACT_APP_API_URL;
            const idToken: string | null = localStorage.getItem("IdToken");
            const accessToken: string | null = localStorage.getItem("AccessToken");
            const config = {
                headers: {
                    Authorization: `${idToken}`,
                    AccessToken: `${accessToken}`
                }
            };
            try {
            const response = await axios.put(
                `${API_URL}/v1/users/change-password`,
                {
                    currentPassword: passwordchange.CurrentPassword,
                    newpassword: passwordchange.NewPassword
                }, config);
                this.statusCode = response.status;
                return response;
            const data = response.data.response; // Assumes server returns a single item
        } catch (error :any) {
            if (error.response && error.response.data) {
              this.responseErrorMessage = error.response.data.message;
              this.statusCode = error.response.status
            } else {
              // Handle the generic error message
              throw new Error("Failed to create user");
            }
        }
    }

}
