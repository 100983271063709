import axios from "axios";
import JobListModel from "./JobListModel";

export default class JobListViewModel {

  jobs: JobListModel[];
  totalPages: number;
  totalCount: number;

  constructor() {
    this.jobs = [];
    this.totalPages = 0;
    this.totalCount = 0;
  }

  async loadJobs(pageNumber: number, pageSize: number, partnerName: string, fromDate: Date | null, toDate: Date | null, status: string, brandName : string) {
    try {
      const API_URL = process.env.REACT_APP_API_URL
      const idToken: string | null = localStorage.getItem("IdToken");
      if (fromDate) {
        fromDate.setHours(fromDate.getHours() + 5, fromDate.getMinutes() + 30);
      }
      if (toDate) {
        toDate.setHours(toDate.getHours() + 5, toDate.getMinutes() + 30);
      }
      const response = await axios.get(`${API_URL}/v1/jobs`, {
        params: {
          page: pageNumber,
          pageSize: pageSize,
          partnerName: partnerName,
          fromDate: fromDate,
          toDate: toDate,
          status: status,
          brandName : brandName
        },
        headers: {
          'Authorization': `${idToken}`
        }
      });

      const data = response.data.response.items;
      this.jobs = data;
      this.totalPages = response.data.response.totalPages
      this.totalCount = response.data.response.totalCount
    } catch (error: any) {
      console.error('Failed to load jobs data:', error);
    }
  }
}